import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import VueFeather from 'vue-feather';
import { createI18n } from 'vue-i18n'
import en from './locales/en.json';
import pt from './locales/fr.json';
import fr from './locales/pt.json';
import es from './locales/es.json';
import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
import '@/assets/css/custom.css';
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'maz-ui/styles' // or
import 'maz-ui/css/main.css'
// import { VueSignalR } from '@dreamonkey/vue-signalr';
// import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

const toastOptions = {
    position: POSITION.TOP_RIGHT
};
const baseURL = process.env.NODE_ENV === 'production' ? 'https://sales.shp.digital:7059' : 'https://salesdev.shp.digital:7057';

// const authInfo = localStorage?.getItem('auth_info');
// console.log(authInfo, 'authInfo authInfo authInfo')

// const {tokenType, accessToken} = JSON.parse(authInfo);
// console.log(tokenType, accessToken, 'tokenType, accessToken tokenType, accessToken')



// const connection = new HubConnectionBuilder()
//     .withUrl(`${baseURL}/notifications`, {
//         skipNegotiation: true,
//         transport: HttpTransportType.WebSockets,
//         // headers: {
//         //     "Authorization": `${tokenType} ${accessToken}`
//         // }
//     })
//     .build();



const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;

const messages = { en: en, es: es, pt: pt, fr: fr};
const i18n = createI18n({

    locale: locale,
    messages
});
//.use(VueSignalR, { connection })
createApp(App).use(i18n).use(Toast, toastOptions).use(router).use(store).component(VueFeather.name, VueFeather).component('Breadcrumbs', Breadcrumbs).mount('#app')
