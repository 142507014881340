<template>
  <div>
    <div class="logo-wrapper">
        <div class="d-flex">
            <Logo />
            <div class="back-btn" @click="toggle_sidebar">
                <i class="fa fa-angle-left"></i>
            </div>
        </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <vue-feather
          class="status_toggle middle sidebar-toggle"
          type="grid"
          id="sidebar-toggle"
        ></vue-feather>
      </div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <img
          class="img-fluid"
          src="../../assets/images/logo/logo_sm_dark.png"
          alt=""
      /></router-link>
    </div>
    <Nav />
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import Logo from './logo.vue';
import Nav from './nav.vue';

export default {
    name: 'Sidebar',
    components: {
        Logo,
        Nav,
    },
    computed: {
        ...mapState({
            activeoverlay: (state) => state.menu.activeoverlay,
        }),
        ...mapGetters({
            menuItems: "menu/filteredMenu"
        })
    },
    methods: {
        toggle_sidebar() {
            this.$store.dispatch('menu/opensidebar');
            this.$store.state.menu.activeoverlay = false;
        },
    },
};
</script>
