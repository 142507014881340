<script>
import Header from "@/components/header/index.vue";

export default {
    name: "contact",
    components: {Header},

}
</script>

<template>
    <div class="mb-5">
        <img src="../assets/images/polar/contact.jpg" alt="support" class="w-100">
        <div class="container mt-5">
            <div>
                <h2 class="text-primary">Contact us</h2>
                <p>Our experts are here to guide you towards the best solution for you.</p>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-sm-6 col-md-4">
                    <h6 class="text-primary">Address for Notices:</h6>
                    <a href="https://maps.app.goo.gl/qHAVdnehp7XQx2dY9" target="_blank">
                        <p>
                           Polar Speed Distribution Ltd
                            <br>
                           8 Chartmoor Road
                            <br>
                           Leighton Buzzard
                            <br>
                           LU7 4WG
                            <br>
                           United Kingdom
                        </p>
                    </a>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <h6 class="text-primary">Contacts</h6>
                    <a href="mailto:pharmacy@polarspeed.com">pharmacy@polarspeed.com</a>
<!--                    <br>-->
<!--                    <a href="tel:0800 7833178">0800 7833178</a>-->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.text-justify{
    text-align: justify;
    text-justify: inter-word;
}
</style>
