<script>
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {mapMutations, mapState} from "vuex";

export default {
    name: "EditMasterDataModal",
    data(){
        return {
            // cloneData: null
        }
    },
    components: {SettingsInput},
    computed:{
        ...mapState({
            editAddressModal: state => state.masterdata.editAddressModal
        }),
        cloneData(){
            console.log(this.editAddressModal.data, 'modalliiii')
            return this.editAddressModal.data
        },
        inputs(){
            return this.editAddressModal.inputs
        }
    },
    methods:{
        ...mapMutations({
            mutEditAddress: "masterdata/mutEditAddress"
        }),
        handleChange(data){
            this.mutEditAddress(data);
        },
        handleSave(){
            this.$emit('save', this.cloneData)
        },
        closeModal(){
            document.getElementById('closeEditData').click();
            // this.cloneData = null;
            this.$emit('modal-close')
        }
    }
}
</script>

<template>
    <div class="modal fade" :id="id || 'EditMasterDataModal'" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{editAddressModal.title}}
                    </h5>
                    <button id="closeEditData" class="hidden" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                    <button @click="closeModal" class="btn-close pr-3 max-w-max" type="button"></button>
                </div>
                <div class="modal-body">
                    <div v-if="cloneData" class="row">
                        <div v-for="input in inputs"
                             class="col-sm-6 col-lg-4 d-flex flex-column justify-content-end"
                             :key="input.key">
                            <settings-input
                                :settingsData="cloneData"
                                :model-value="cloneData[input.key]"
                                @update:model-value="(val) => handleChange({key: input.key, value: val})"
                                :input="input"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="closeModal" class="btn btn-light max-w-max" type="button">Cancel</button>
                    <button class="btn btn-primary max-w-max" @click="handleSave">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
