<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, numeric} from "@vuelidate/validators";
import api from "@/utils/api";
import PolarLoader from "@/components/PolarLoader.vue";
import MasterdataAddress from "@/components/masterdata/MasterdataAddress.vue";
import RegisterPatient from "@/components/patient/RegisterPatient.vue";
import {separateOnCapitalLetter} from "@/utils/helperFunctions";
import {format} from "date-fns";

export default {
    components: {RegisterPatient, MasterdataAddress, PolarLoader, SettingsInput, ConfirmModal},
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    props: {
        userToManage: null
    },
    data() {
        return {
           filetypes: ['csv','docx','gif','jpg','mp4','pdf','png','xls','xlsx','zip'],
            loading: false,
            loadingTitle: '',
            inputs: [
                {
                    title: "",
                    inputs: [
                        { key: "homecareProvider" },
                        {
                            key: "hospital",
                            type: "obj",
                            displayKey: 'name'
                        },
                        {
                            key: "therapy",
                            type: "obj",
                            displayKey: 'therapyService'
                        },
                    ]
                },
                {
                    title: "PATIENT, CARER and GP DETAILS",
                    inputs: [
                        {key: "webPortalPatientId"},
                        {key: "nhsNumber"},
                        {key: "hospital", type: "obj", displayKey: 'hospitalNumber', label: 'hospitalNumber'},
                        {key: "title" },
                        {key: "firstName",label:"Forename"},
                        {key: "lastName",label:"Surname"},
                        {key: "birthdate",label:"Date of birth", type: "date"},
                        {key: "address"},
                        {key: "postcode"},
                        {key: "gender"},
                        {key: "phoneNumber", type: 'phoneNumber'},
                        {key: "alternativePhone", type: 'phoneNumber'},
                        {key: "leaveMessage", type: "bool"},
                        {key: "email"},
                        {key: "diagnosis"},
                        {key: "clinicalLeadName"},
                        {key: "clinicalLeadPhone", type: 'phoneNumber'},
                        {key: "clinicalSpecialistName"},
                        {key: "clinicalSpecialistPhone", type: 'phoneNumber'},
                        {key: "clinicalPharmacistName"},
                        {key: "clinicalPharmacistPhone", type: 'phoneNumber'},
                        {key: "gpName"},
                        {key: "gpSurgery"},
                        {key: "parentName"},
                        {key: "parentRelation"},
                        {key: "parentPhone", type: 'phoneNumber'},
                    ]
                },
                {
                    title: "SERVICE REQUIREMENTS – Low Tech and Delivery Service Module",
                    inputs: [
                        {key: "registrationStatus", type: "radio"},
                    ]
                },{
                title: "Initial delivery / treatment details",
                    inputs: [
                        {key: "deliveryAddress"},
                        {key: "deliveryPostcode"},
                        {key: "firstDeliveryDatetimeUtc", type: "date"},
                        {key: "deliveryReceived", type: "bool"},
                        {key: "specifiedPersonName", type: "string", disabled: 1},
                        {key: "specifiedPersonPhone", type: "phoneNumber", disabled: 1},
                        {key: "specifiedPersonRelation", type: "string", disabled: 1},
                        {key: "deliveryFrequency", type: "radioOther"},
                        {
                            key: "picp",
                            type: "bool",
                            label: 'Patient individual Care Plan (PICP) attached'
                        },
                        {key: "picpRef", type: "string", disabled: 1, label: 'PICP'},
                    ]
                },
                {
                    title: "ADDITIONAL CLINICAL SERVICE REQUIREMENTS – Mid Tech Module ",
                    inputs: [
                        {
                            key: "administrationTrainingRequired",
                            type: "bool",
                            notRequired: true
                        },
                        {key: "administrationTrainingDatetimeUtc", type: "date", disabled: 1},
                        {
                            key: "administrationTrainingProvider",
                            type: "radioOther",
                        },
                    ]
                },
                {
                    title: "ADDITIONAL CLINICAL SERVICE REQUIREMENTS – Mid Tech Module ",
                    inputs: [
                        {key: "nurseAdministrationRequired", type: "bool"},
                        {key: "nurseAdministrationRequiredDatetimeUtc", type: "date", disabled: 1},
                        {key: "nurseAdministrationProvider", type: "radioOther"},
                    ]
                },
                {
                    title: "REFERRING PHYSICIAN/HEALTHCARE PROFESSIONAL ",
                    inputs: [
                        {key: "healthcareProfessionalName", type: "string"},

                    ]
                },
                {
                    title: "INVOICING DETAILS & ADMINISTRATIVE CONTACTS",
                    inputs: [
                        {key: "invoiceAddress"},
                        {key: "invoicePostcode"},
                        {key: "homecareLeadName"},
                        {key: "homecareLeadEmail"},
                        {key: "invoiceContactName"},
                        {key: "invoiceContactPhone", type: 'phoneNumber'},
                        {key: "invoiceContactEmail"},
                        {key: "invoiceCAccountName"},
                        {key: "homecareLeadPhone", type: 'phoneNumber'},
                    ]
                },
                {
                    title: "This Patient Registration Form must be forwarded with a valid prescription to the Hospital’s Pharmacy Department (Homecare Team) prior to transmission to the selected Homecare Provider.",
                    // text: "Appendix 4a Version 4.0 - Approved: 12th Jun 2018",
                    inputs: [
                        { key: "homecareProvider" },
                        { key: "homecareProviderSentDatetimeUtc", label:'Date sent to home care provider', type: "date", },
                    ]
                },
            ],
            patient: {}
        }
    },
    computed: {

    },
    methods: {
        format,
        separateOnCapitalLetter,
        closeNew() {
            this.patient = this.patientData
            this.$emit('closeNew')
        },
       getImg(val){
           let name = val.split(".").slice(-1)[0]
          console.log(name,'namenamenamenamename')
          if(this.filetypes.includes(name))
             return `${name}.png`
          return 'other.png'
       },
        showValue(i){
            if (i.type === 'phoneNumber'){
                let nr = this.patient[i.key]
                return nr.phonePrefix + nr.phoneNumber || 'No Data'
            }
            if (i.type === 'obj'){
                return this.patient[i.key][i.displayKey]
            }
            if (i.type === 'date'){
                console.log(i.key, this.patient,'this.patient[i.key]')
                if (this.patient[i.key])
                return format(this.patient[i.key],'dd-MMM-yyyy')
            }
            if (i.type === 'bool'){
                return this.patient[i.key] === true ? 'Yes' : 'No'
            }
            return this.patient[i.key] || 'No Data'
        }
    },
    created() {
        this.loadingTitle = 'Loading data'
        this.loading = true;
        if (this.userToManage) {
            this.patient = this.userToManage
        }
        this.loading = false;

    },
}
</script>

<template>
    <div class="container">
        <div class="card position-relative overflow-hidden">

            <polar-loader v-if="loading">
                {{ loadingTitle }}
            </polar-loader>
            <div class="card-body">
                <div class="d-flex align-items-center gap-2">
                    <h6 style="color:#818181">Private and Confidential</h6>
                    <button @click="closeNew" class="btn btn-danger btn-sm ml-auto">Go Back</button>
                </div>
                <div class="d-grid">
                <h5 class="text-center f-w-600 m-t-30">Homecare Medicine Service: Patient Registration Form</h5>
                <div :class="'m-auto btn btn-sm ' +
                      (this.userToManage.formStatus === 'Rejected' ? 'btn-danger':
                      (this.userToManage.formStatus === 'Approved' ? 'btn-success': 'btn-warning'))"
                     v-if="this.userToManage">
                    {{ this.userToManage.formStatus }}
                </div>
                </div>
            </div>
            <div class="card-body" :style="idx === 0 ? '' : 'border-top: 1px dashed #ced4da;'" v-for="(section, idx) in inputs"
                 :key="section.title">
                <div v-if="section.title">
                    <h5>{{ section.title }}</h5>
                </div>
                <div v-if="section.text">
                    <h6>{{ section.text }}</h6>
                </div>
                <div>
                    <div class=" show row">
                        <div v-for="i in section.inputs"
                             :class="'col-md-4 mb-3'">
                            <b> {{ separateOnCapitalLetter(i.label || i.key) }}</b><br>
                            <div class="inputLike border border-secondary-subtle rounded-1 p-2">
                                {{showValue(i)}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
           <div class="card-body" style="border-top: 1px dashed #ced4da;" v-if="userToManage.patientDocument.length">
              <h5 class="mb-4">Files</h5>
              <div class="flex-wrap d-flex gap-5">
              <div v-for="item in userToManage.patientDocument" style="max-width: 100px;">
                 <a :href="item.link" :download="item.name">
                    <img :src="`/fileTypes/${getImg(item.link)}`" alt="" style="max-width: 100px;"> <br>
                    <div class="text-center" style="color: black; font-size: 1rem">{{ item.name }}</div>
                 </a>
              </div>
              </div>
           </div>

        </div>

    </div>

</template>

<style scoped>

</style>