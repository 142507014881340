<script>
import {mapActions, mapMutations, mapState} from "vuex";
import AddressView from "@/components/masterdata/AddressView.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";
import {addressInputs} from "@/constants/masterData";

export default {
    name: "MasterdataAddress",
    components: {EditMasterDataModal, SettingsInput, AddressView},
    data(){
        return {
            transactionType: 0, // 0 = Invoice, 1 = Delivery
            transOptions: [
                {type: 0, title: 'Invoice'},
                {type: 1, title: 'Delivery'},
            ],
            communicationType: 0,// 0 = phone, 1 = email
            editModal: {
                inputs: null,
                data: null,
                title: null,
                type: null
            }
        }
    },
    computed: {
        ...mapState({
            customerMasterData: state => state.masterdata.customerMasterData,
            loggedUser: state => state.auth.loggedUser
        }),
        communicationOptions(){
            const transTitle = `${this.transactionType ? 'Delivery' : 'Invoice'} Contact`
            return [
                {type: 0, title: `${transTitle} Phone`},
                { type: 1, title: `${transTitle} Email`},
            ]
        },
        addressInputs(){
            return addressInputs;
        },
        allAddress(){
            return this.customerMasterData?.addresses || []
        },
        invoiceAddress(){
            return this.customerMasterData?.addresses.find(a => a.transactionType === 0) || null
        },
        currentInvoiceContact(){
            return this.invoiceAddress?.contacts.find(c => c.communicationType === this.communicationType)
        },
        currentAddress(){
            return this.customerMasterData?.addresses.find(a => a.transactionType === this.transactionType) || null
        },
        currentContact(){
            return this.currentAddress?.contacts.find(c => c.communicationType === this.communicationType)
        }
    },
    methods:{
        ...mapMutations({
            mutMasterAddress: 'masterdata/mutMasterAddress',
            mutMasterContact: 'masterdata/mutMasterContact',
            mutCloneEditAddress: "masterdata/mutCloneEditAddress",
        }),
        ...mapActions({
            actUpdateCustomerAddress: "masterdata/actUpdateCustomerAddress"
        }),
        handleAddressChange({key, value}){
            this.mutMasterAddress({key, value, id: this.currentAddress.id});
            this.monitorRelations(key)
        },
        handleContactChange({key, value}){
            this.mutMasterContact({key, value, addressId: this.currentAddress.id, id: this.currentContact.id});
        },
        monitorRelations(key){
            let nextKey = ''
            for(const data of this.addressInputs.addresses){
                if(nextKey === data.relation){
                    this.mutMasterAddress({key: data.key, value: null, id: this.currentAddress.id});
                    // this.settingsData[sKey] = null;
                    console.log('setting next data to default', key)
                } else if(data.relation === key){
                    console.log('setting data to default', key)
                    this.mutMasterAddress({key: data.key, value: null, id: this.currentAddress.id});
                   nextKey = key;
                }
            }
        },
        handleEditAddress(){
            this.mutCloneEditAddress({
                data: this.currentAddress,
                inputs: addressInputs.addresses,
                title: `${this.transactionType ? 'Delivery' : 'Invoice'} Address`,
                type: 'address'
            });
        },
        resetEditModal(){
            this.mutCloneEditAddress({
                data: null,
                inputs: null,
                title: null,
                type: null
            })
        },
        getAddressPayload(data){
            const {
                contacts,
                accountum,
                d365AddressLocationID,
                transactionType,
                ...rest
            } = data

            return {
                ...rest,
                "addressNote": "string",
                customerMasterDataId: accountum,
                userIds: [
                    "446e7701-0500-4e77-8725-f38a98dca180"
                ]
            }
        /*
        * {
  "branchName": "string",
  "registrationNumber": "string",
  "branchID": "string",
  "address": "string",
  "street": "string",
  "cityName": "string",
  "zipCode": "string",
  "customerAddressCheckDate": "2024-02-22T14:57:57.251Z",
  "addressValExpDate": "2024-02-22T14:57:57.251Z",
  "addressValidFrom": "2024-02-22T14:57:57.251Z",
  "addressValidTo": "2024-02-22T14:57:57.251Z",
  "addressNote": "string",
  "countyId": 0,
  "countryId": 0,
  "stateId": 0,
  "customerMasterDataId": "string",
  "userIds": [
    "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  ]
}*/
        },
        handleModalSave(data){
            if(this.editModal.type === 'address'){
                const payload = this.getAddressPayload(data);
                console.log(payload, 'handleModalSave payload')
                this.actUpdateCustomerAddress(payload)
            }
        }
    }
}
</script>

<template>
    <div>
        <EditMasterDataModal
            :inputs="editModal.inputs"
            :title="editModal.title"
            @modal-close="resetEditModal"
            @save="handleModalSave"
        />
        <div class="d-flex gap-3 mb-3 align-items-end">
            <ul class="nav nav-tabs w-100">
                <li v-for="nav in transOptions" class="nav-item">
                    <div @click="() => transactionType = nav.type" :class="['nav-link cursor-pointer', {'font-primary active': transactionType === nav.type}]">
                        {{nav.title}} Address
                    </div>
                </li>
            </ul>
            <button v-if="transactionType === 0" @click="handleEditAddress"
                    data-bs-toggle="modal"
                    data-bs-target="#EditMasterDataModal"
                    class="max-w-max h-max ml-auto btn btn-sm btn-outline-primary">
                <i class="fa fa-pencil"></i> Address
            </button>
        </div>
        <transition-group name="fadeIn" enter-active-class="animated fadeIn">
            <div v-if="invoiceAddress" v-show="transactionType === 0" :key="0">
                <div class="row">
                    <div v-for="input in addressInputs.addresses"
                         class="col-sm-6 col-lg-4 d-flex flex-column justify-content-end"
                         :key="input.key">
                        <settings-input
                            :disabled="true"
                            :settingsData="invoiceAddress"
                            :model-value="invoiceAddress[input.key]"
                            @update:model-value="(val) => handleAddressChange({key: input.key, value: val})"
                            :input="input"
                        />
                    </div>
                </div>
                <div class="d-flex gap-3 pb-3 mb-3 mt-4 pt-3 align-items-end">
                    <ul class="nav nav-tabs w-100">
                        <li v-for="nav in communicationOptions" class="nav-item">
                            <div @click="() => communicationType = nav.type" :class="['nav-link cursor-pointer', {'font-primary active': communicationType === nav.type}]">
                                {{nav.title}}
                            </div>
                        </li>
                    </ul>
                    <button class="max-w-max h-max ml-auto btn btn-sm btn-outline-primary" disabled>
                        <i class="fa fa-pencil"></i> Contact
                    </button>
                </div>
                <div class="row">
                    <div v-for="input in addressInputs.contacts"
                         class="col-sm-6 col-lg-4 d-flex flex-column justify-content-end"
                         :key="input.key">
                        <settings-input
                            :disabled="true"
                            :settingsData="currentInvoiceContact"
                            :model-value="currentInvoiceContact[input.key]"
                            @update:model-value="(val) => handleContactChange({key: input.key, value: val})"
                            :input="input"
                        />
                    </div>
                </div>
            </div>
            <address-view v-show="transactionType === 1" :key="1"></address-view>
        </transition-group>
    </div>
</template>

<style scoped>
</style>
