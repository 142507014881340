import {errorToast} from "@/utils/helperFunctions";
import api from "@/utils/api";
import JsonViewer from "json-viewer-js";
import {useToast} from "vue-toastification";
const toast = useToast();
const state = {
    customerMasterData: null,
    editAddressModal: {
        inputs: null,
        data: null,
        title: null,
        type: null
    }
}

const getters = {

}

const mutations = {
    mutEditAddress: (state, {key, value}) => {
        state.editAddressModal.data[key] = value;
    },
    mutCloneEditAddress: (state, clone) => {
        state.editAddressModal = JSON.parse(JSON.stringify(clone));
    },
    mutMasterData: (state, value) => {
        state.customerMasterData = value;
    },
    mutMasterDataByKey: (state, {key, value}) => {
        // const el = document.getElementById('data')
        // el.innerHTML = ''
        state.customerMasterData[key] = value;
        // new JsonViewer({
        //     container: el,
        //     data: JSON.stringify(state.customerMasterData),
        //     theme: 'light',
        //     expand: true
        // });
    },
    mutMasterAddress: (state, {key, value, id}) => {
        let addr = state.customerMasterData.addresses.find(a => a.id === id);
        addr[key] = value;
    },
    mutMasterContact: (state, {key, value, addressId, id}) => {
        let addr = state.customerMasterData.addresses.find(a => a.id === addressId);
        if(addr){
            let contact = addr.contacts.find(c => c.id === id);
            contact[key] = value;
        }
    }
}

const actions = {
    actCustomerMasterData: async ({commit}, customerId) => {
        try {
            const {data} = await api.get(`/CustomerMasterData/${customerId}`);
            commit('mutMasterData', data);
        } catch (e) {
            errorToast(e)
        }
    },
    actUpdateCustomerMasterData: async ({commit}, masterData) => {
        const {id, ...rest} = masterData;
        try {
            const {data} = await api.put(`/CustomerMasterData/${id}`, rest);
            toast.success('Customer Master Data updated successfully')
            console.log('dataaaaaaaa:::',data)
        } catch (e) {
            errorToast(e)
        }
    },
    actUpdateCustomerAddress: async ({commit}, address) => {
        const {id, ...rest} = address;
        try {
            const {data} = await api.put(`/address/${id}`, rest)
            console.log('dataaaaaaaa:::',data)
        } catch (e) {
            errorToast(e)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
