import api from "@/utils/api";
import {useToast} from "vue-toastification";
const toast = useToast();

const state = {
    dashNotifications: [],
    currentNotification: null,
    wsNotifications: []
}

const getters = {

}

const mutations = {
    mutNotificationState: (state, {key, value}) => {
        state[key] = value;
    },
    mutNotification: (state, {notification, id}) => {
        let foundN = state.dashNotifications.find(n => n.id === id);
        for(const key in notification){
            foundN[key] = notification[key]
        }
        // console.log(foundN, 'foundN foundN', notification)
        // foundN = {...foundN, ...notification};
    },
    mutFilterNotifications: (state, id) => {
        state.dashNotifications = state.dashNotifications.filter(n => n.id !== id)
    },
    mutWSNotification: (state, items) => {
        state.wsNotifications = items;
    }
}

const actions = {
    actGetDashNotifications: async ({commit}) => {
        try {
            const {data} = await api.get('/homePageInfo', {
                params: {
                    isDescending: false,
                    columnName: 'order'
                }
            })
            console.log(data.items, 'actGetDashNotifications')
            commit('mutNotificationState', {key: 'dashNotifications', value: data.items})

        } catch (e) {
            toast.error(e.response?.data?.errors[0]?.description || 'Something went wrong');
        }
    },
    actUpdateNotification: async ({commit}, {notification, id}) => {
        try {
            await api.put(`/homePageInfo/${id}`, notification);
            console.log(notification, 'notification notification')
            commit('mutNotification', {notification, id})
            toast.success('Notification updated successfully');
        } catch (e) {
            toast.error(e.response?.data?.errors[0]?.description || 'Something went wrong');
        }
    },
    actDeleteNotification: async ({commit}, id) => {
        try {
            await api.delete(`/homePageInfo/${id}`);
            commit('mutFilterNotifications', id)
            toast.success('Notification deleted successfully');
        } catch (e) {
            console.log(e, 'errorr')
            toast.error(e.response.data?.errors[0]?.description || 'Something went wrong');
        }
    },
    actGetWSNotifications: async ({commit}, userId) => {
        console.log(userId,'customerServiceId')
        try {
            const {data} = await api.get('/notification', {
                params: {
                    userId,
                    pageNumber: 1,
                    pageSize: 1000
                }
            })

            commit('mutWSNotification', data.value.items)
        } catch (e) {
            toast.error(e.response.data?.errors[0]?.description || 'Something went wrong');
        }
    },
    
    actMarkAllAsRead: async ({commit}, {userId, notificationId}) => {
        try {
            const {data} = await api.post(`/notification?userId=${userId}&notificationId=${notificationId}`);
            // commit('mutWSNotification', [])
        } catch (e) {
            toast.error(e.response.data?.errors[0]?.description || 'Something went wrong');
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
