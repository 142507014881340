<script>
import Pagination from 'v-pagination-3';
export default {
    components:{
        Pagination
    },
    props: {
        paginationInfo: {
            type: Object,
            default: () => {
                return {
                    "pageNumber": 1,
                    "totalPages": 2,
                    "totalCount": 26,
                    "hasPreviousPage": false,
                    "hasNextPage": true
                }
            }
        },
        pageSize: {
            type: Number,
            default: 10
        },
    },
    data(){
        return{
            paginationOptions: null
        }
    },
    computed:{
        pagItems(){
            // const length = this.paginationOptions.length;
            const total = this.paginationInfo.totalPages;
            const page = this.paginationInfo.pageNumber;
            if(total > 5 && page >= 5){
                // const remainder = (page) % 5;
                const stop = page + 2;
                const start = page - 2;
                console.log(stop, start)
                // if(remainder === 0){
                    return Array.from(
                        { length: (stop - start) + 1 },
                        (value, index) => start + index
                    )
                // }
            } else {
                return Array.from({length: total > 5 ? 5 : total}, (_, i) => i + 1)
            }
        }
    },
    methods: {
        defineNewOptions(newPage){
            // const length = this.paginationOptions.length;
            const total = this.paginationInfo.totalPages;
            if(total > 5){
                // const first = this.paginationOptions[0];
                const last = this.paginationOptions[length - 1];
                console.log(newPage, 'last nav item:::',last, total > last)
                if(newPage === last && total > last){
                    this.paginationOptions.push(last + 1);
                }
            }
        },
        handlePageChange(page){
            console.log('smthhhnin')
            console.log('handlePageChange', page)
            this.$emit('pageChanged', page)
            // this.defineNewOptions(page)
        },
        handlePreviousNext(value){
            this.handlePageChange(this.paginationInfo.pageNumber + value)
        }
    },
}
</script>

<template>
    <div>
        <Pagination :model-value="paginationInfo.pageNumber" @update:model-value="handlePageChange" :records="paginationInfo.totalCount" :per-page="8" :options="{chunk: 5}"/>
    </div>
<!--    <nav>-->
<!--        <ul class="pagination pagination-primary">-->
<!--            <li :class="['page-item', {'disabled': !paginationInfo.hasPreviousPage}]">-->
<!--                <span @click="handlePreviousNext(-1)" class="page-link">Previous</span>-->
<!--            </li>-->
<!--            <li v-for="opt in pagItems" :key="opt" :class="['page-item', {'active': paginationInfo.pageNumber === opt}]">-->
<!--                <span @click="handlePageChange(opt)" class="page-link">{{ opt }}</span>-->
<!--            </li>-->
<!--            <li :class="['page-item', {'disabled': !paginationInfo.hasNextPage}]">-->
<!--                <span @click="handlePreviousNext(1)" class="page-link">Next</span>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </nav>-->
</template>

<style scoped>
.page-link{
    cursor: pointer;
}
</style>
