<template>
        <div class="card">
            <div class="card-body">
                <div class="product-page-details">
                    <h3>{{ singleProduct?.name }}</h3>

                </div>
                <div class="product-price">
                    ${{ 'Price' }}
<!--                    <del>${{ 10 }} </del>-->
                </div>
                <hr />
                <p>
                    {{ singleProduct?.description}}
                </p>
                <hr />
                <div class="d-flex col-10 col-sm-6 col-md-8 col-xl-6 justify-content-between">
                    <div class="d-flex flex-column gap-2">
                        <div v-for="d in displayData" class="fw-bold">
                            {{d.title}} :
                        </div>
                    </div>
<!--                    <div  class="d-flex flex-column gap-2" style="margin-right: 30px">-->
<!--                        <b v-for="d in displayData">-->
<!--                            :-->
<!--                        </b>-->
<!--                    </div>-->
                    <div class="d-flex flex-column gap-2">
                        <div v-for="d in displayData" class="text-end">
                            <div v-if="d.title === 'Availability'"
                                 :class="singleProduct ? singleProduct[d.key] ? 'font-danger' : 'font-success' : ''">
                                {{singleProduct ? singleProduct[d.key] ? 'Out of stock' : 'In stock' : ''}}
                            </div>
                            <div v-else>
                                {{singleProduct ? singleProduct[d.key] : ''}}
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-12 d-flex align-items-center justify-content-between" v-for="d in displayData">-->
<!--                        <div>-->
<!--                            <b>{{ d.title }} :</b>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            {{singleProduct ? singleProduct[d.key] : ''}}-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <hr />
                <div class="my-auto btn-showcase">
                    <button class="btn btn-primary">
                        <i class="fa fa-shopping-basket me-1"></i>
                        Add To Cart
                    </button>
                </div>
            </div>
        </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name: "ProductDetails",
    computed: {
        ...mapState({
            singleProduct: state => state.product.singleProduct
        }),
        displayData(){
            return [
                {title: 'Barcode', key: 'barcode'},
                {title: 'Code', key: 'barcode'},
                {title: 'SKU', key:'d365FO_Item_Number_SKU'},
                {title: 'Unit', key: 'unitOfMeasureSymbol'},
                {title: 'Availability', key:'psdwP_OutOfStock'}
            ]
        }
    },
};
</script>
