<script>
import CreateEditSettings from "@/components/settings/CreateEditSettings.vue";
import {tableColumnTitles} from "@/constants/tableSettings";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import { useVuelidate } from '@vuelidate/core'
import { email, sameAs, helpers } from '@vuelidate/validators';
import {validatePhoneNumber} from "@/utils/helperFunctions";
import api from "@/utils/api";
import {useToast} from "vue-toastification";

const defaultAddress = (transactionType) => {
    return {
        "countryId": null,
        "stateId": null,
        "countyId": null,
        "cityId": null,
        "street": "",
        "address": "",
        "zipCode": "",
        "branchID": "",
        "branchName": "",
        "registrationNumber": "",
        transactionType,
        "contactPhone": "",
        "email": ""
    }
}
export default {
    name: "new_register",
    components: {PolarLoader, SettingsInput, CreateEditSettings},
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
          hospitals:[],
            finishedSuccess: false,
            loading: false,
            agreeCheck: false,
            agreeError: false,
            captchaError: '',
            emailExists: [],
            newAddressId: 0,
            addresses:[
                defaultAddress(0),
                defaultAddress(1),
            ],
            accountDetails: {
                organizationName: '',
                companyRegistrationNumber: '',
                companyVATNumber: '',
                dunsNumber: '',
                isVATRegistered: false,
            },
            loginDetails: {
                title: "",
              proTitle: "",
                firstName: "",
                lastName: "",
                email: "",
              hospitals:[
                {
                  hospitalId: 0
                }
              ]
            },
            apiS: [
                // 'api/v1/Hospital/all',
                // 'Country',
                // 'State',
                // 'County',
                // 'City'
            ],
            apiData: {
                TitleOptions: [
                    'Ms',
                    'Miss',
                    'Mr',
                    'Mrs',
                ],
                HealthcareOptions: [
                    'Doctor',
                    'Nurse',
                    'Clinical Pharmacist',
                    'Clinicians',
                ]
            }
        }
    },
    computed: {
        apiTempData(){
            const allApiData = [];
            for(const apI in this.apiData){
                allApiData.push({apI, items: [this.apiData[apI][0]]})
            }
            return allApiData
        },
        finalForm(){
            const fForm = {
                ...this.loginDetails,
                ...this.accountDetails,
                isVATRegistered: !this.accountDetails.isVATRegistered,
                addresses: []
            }

            for(const add of this.addresses){
                const {contactPhone, email, transactionType, ...rest} = add;
                const contacts = [
                    {
                        isMobilePhone: true,
                        locator: contactPhone,
                        transactionType,
                        communicationType: 0
                    },
                    {
                        isMobilePhone: false,
                        locator: email,
                        transactionType,
                        communicationType: 1
                    }
                ]

                const finalAdd = {
                    ...rest,
                    transactionType,
                    contacts
                };
                console.log(finalAdd);

                fForm.addresses.push(finalAdd);
            }
            return fForm
        },
        JSONForm(){
          const jsonForm = [
            {
              title: 'Account Details',
              data: this.loginDetails,
              form: this.getAddressForm({
                // title: {type: 'select', default: '', api: 'TitleOptions'},

                firstName: {type: 'string', default: '', label: 'First name'},
                lastName: {type: 'string', default: '', label: 'Last name'},
                proTitle: {type: 'select', default: '', api: 'HealthcareOptions',label: 'Healthcare Professional Type'},
                email: {
                  type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {
                    email,
                    emailExists: helpers.withMessage('Email exists', (value) => {
                      return !this.emailExists.includes(value)
                    })
                  }
                },
                hospitals: {type: 'select', default: '', api: 'hospitalsArray' ,label: 'Hospitals'},
                hospitalEmail: {type: 'string', default: '', label: 'Hospital Confirmation email', value:'test', readonly:true},


              })
            }
          ]
          // const jsonForm = [
          //       {
          //           title: 'Account Details',
          //           form: this.getAddressForm({
          //               "organizationName": {type: 'string', default: '', label: 'Organization Name'},
          //               "companyRegistrationNumber": {type: 'string', default: '', label: 'Company Registration Number'},
          //               "companyVATNumber": {type: 'string', default: '', label: 'Company VAT Number'},
          //               "dunsNumber": {type: 'string', default: '', label: 'DUNS Number'},
          //               "isVATRegistered": {type: 'boolean', default: false, label: 'I am not VAT registered'},
          //           }),
          //           data: this.accountDetails,
          //       }
          //   ]
          //   for(const address of this.addresses){
          //       jsonForm.push({
          //           title: address.transactionType ? 'Delivery Address Details' : 'Invoice Address Details',
          //           data: address,
          //           form: this.getAddressForm({
          //               "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number'},
          //               "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number'},
          //               "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number'},
          //               "cityId": {type: 'select', default: '', api: 'City', relation: 'countyId', valueType: 'number'},
          //               "street": {type: 'string', default: '', label: 'Street'},
          //               "address": {type: 'string', default: '', label: 'Address'},
          //               "zipCode": {type: 'string', default: '', label: 'Post Code'},
          //               "branchID": {type: 'string', default: '', label: 'Branch Identifier'},
          //               "branchName": {type: 'string', default: '', label: 'Branch Name'},
          //               "registrationNumber": {type: 'string', default: '', label: 'Registration number'},
          //               "contactPhone": {type: 'string', default: '', label: 'Phone number', validation: { validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}},
          //               "email": {
          //                   type: 'string',
          //                   default: '',
          //                   inputType: 'email',
          //                   label: 'Email address',
          //                   validation: {
          //                       email
          //                   }
          //               }
          //           }),
          //       })
          //   }
          //
          //   jsonForm.push({
          //       title: 'Login Details',
          //       data: this.loginDetails,
          //       form: this.getAddressForm({
          //           title: {type: 'select', default: '', api: 'TitleOptions'},
          //           firstName: {type: 'string', default: '', label: 'First name'},
          //           lastName: {type: 'string', default: '', label: 'Last name'},
          //           email: {type: 'string', default: '', inputType: 'email', label: 'Email address', validation: {email,
          //                   emailExists: helpers.withMessage('Email exists', (value) => {
          //                       return !this.emailExists.includes(value)
          //                   })}},
          //           confirmEmail: {type: 'string', default: '', inputType: 'email', label: 'Confirm email address', validation: {sameAs: helpers.withMessage('Confirm email is not the same as email', sameAs(this.loginDetails.email))}},
          //           phoneNumber: {type: 'string', default: '', label: 'Phone number', validation: { validatePhoneNumber: helpers.withMessage('Invalid phone number', validatePhoneNumber)}},
          //       })
          //   })
            return jsonForm;
        },
    },
    methods:{
      getHospitalnames(){
        let arr = []
        for(let x =0; x< this.hospitals.length; x++){
          arr.push(this.hospitals[x].name)
        }
        this.apiData.hospitalsArray = arr
        console.log(arr,'getHospitalnames',this.hospitalsArray)

      },
        removeNewAddress(id){
            const idx = this.addresses.findIndex(a => a.id === id);
            this.addresses.splice(idx, 1);
        },
        addNewDeliveryAddress(){
            this.newAddressId = this.newAddressId - 1;
            this.addresses.push({...defaultAddress(1), id: this.newAddressId})
        },
        async register(){

            const result = await this.v$.$validate();
            this.agreeError = !this.agreeCheck;

            if(result && this.agreeCheck){
                this.agreeError = false;
                const verified = await this.verifyCaptcha();
                if(verified){
                    this.loading = true;
                    document.body.style.overflow = 'hidden';
                    const response = await this.$store.dispatch('auth/actRegister', this.finalForm);
                    this.loading = false;
                    document.body.style.overflow = '';
                    document.body.style['max-height'] = '';
                    if(response === 'success') {
                        this.finishedSuccess = true;
                        return true;
                    } else {
                        if(response?.response?.data){
                            const {errors} = response.response.data;
                            if(errors){
                                if(errors[0]?.code === 'Users.EmailNotUnique'){
                                    this.emailExists.push(this.finalForm.email);
                                }
                            }
                        }
                    }
                } else {
                    this.captchaError = 'Please verify you are human';
                }
            }
            const errorEl = document.getElementsByClassName('invalid-feedback');
            errorEl[0]?.parentElement.scrollIntoView({ behavior: "smooth"});
        },
        getAddressForm(jsonForm){
            const form = [];
            for(const key in jsonForm){
                const formInput = {
                    label: tableColumnTitles[key] || key,
                    key,
                    ...jsonForm[key],
                }
                form.push(formInput);
            }
            return form;
        },
        handleDataChange({val, key, data, form}){
            data[key] = val;
            this.monitorRelations(key, data, form);
            console.log(key, data, form,'key, data, form')
        },
        monitorRelations(key, data, form){
            let nextKey = '';
            for(const input of form){
                if(nextKey === input.relation){
                    console.log('next relation', nextKey)
                    data[input.key] = null;
                } else if(input.relation === key){
                    data[input.key] = null;
                    nextKey = input.key;
                }
            }
        },
        async getSetApiData(){
            for(const url of this.apiS){
                api.get(`/${url}`, {
                    // params: {
                    //     pageNumber: 1,
                    //     pageSize: 1000
                    // }
                }).then(({data}) => {
                    console.log(`${url} dataaa:::`, data);
                    this.apiData[url] = data;
                })
            }
        },
        async verifyCaptcha(){
            try {
                const recaptchaResponse = grecaptcha.getResponse();
                console.log('recaptchaResponse::', recaptchaResponse);
                if(recaptchaResponse) {
                    const {data} = await api.post('/captcha', null, {
                        params: {
                            token: recaptchaResponse
                        }
                    })
                    if(data) return data
                } else {
                    this.captchaError = 'Please verify you are human'
                }
            } catch (e) {
                const {data} = e.response
                useToast().error(`${data.errors[0]?.description}`);
                if(grecaptcha){
                    grecaptcha.reset();
                }
            }
            return false;
        },
      hosptalNames(){
        api.get('api/v1/Hospital/all', {
          // params: {
          //     pageNumber: 1,
          //     pageSize: 1000
          // }
        }).then(({data}) => {
          this.hospitals = data;
          console.log(this.hospitals,`api/v1/Hospital/all dataaa:::`, data);
          this.getHospitalnames()
        })
      }
    },
    mounted() {
        // this.getSetApiData();
      // this.hosptalNames()
    }
}
</script>

<template>
<div>
    <div v-if="finishedSuccess" class="text-center">
        <h3 class="text-success">Your account has been created!</h3>
        <p>We have sent a confirmation link in the email below</p>
        <p><b class="text-primary">{{loginDetails.email}}</b></p>
    </div>
    <div v-else class="container w-100">
        <div v-for="({form, data, title}, idx) in JSONForm" :key="title + 'idx'">
            <div class="card theme-form" >
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0">{{ title }}</h4>
                    <div v-if="data.id < 0" class="ml-auto cursor-pointer" @click="removeNewAddress(data.id)">
                        <i class="fa fa-trash fs-5 font-danger"></i>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div v-for="(input, idx) in form"
                             class="col-md-6 mb-3"
                             :key="input.key + title">
                            <SettingsInput :settingsData="data"
                                           :model-value="data[input.key]"
                                           :parent-select="input.api ? (apiData[input.api] || [])  : null"
                                           @update:model-value="(val) => handleDataChange({val, key: input.key, data, form})"
                                           :input="input"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="idx + 2 === JSONForm.length" class="mb-4">
                <button class="btn btn-primary d-flex align-items-center gap-2 justify-content-center btn-lg w-100" @click="addNewDeliveryAddress">
                    <i class="fa fa-plus"/>
                    Add delivery address
                </button>
            </div>
        </div>
        <div class="form-group">
<!--            <label class="col-form-label">Please confirm*</label>-->
            <div class="g-recaptcha" data-sitekey="6LeQ04QpAAAAAJBnlfoa7mrn5IHzgmbgv6gaccbz"></div>
            <div class="invalid-feedback d-block" v-if="captchaError">{{captchaError}}</div>
        </div>
        <div class="form-group mb-0 mt-3 mb-3">
            <div class="checkbox p-0">
                <input v-model="agreeCheck" id="checkbox1" type="checkbox" />
                <label class="text-muted" for="checkbox1">
                    Agree with<router-link to="/terms-and-conditions" class="ms-2">Terms and Conditions</router-link>
                </label>
                <div class="invalid-feedback mt-0 d-block" v-if="agreeError && !agreeCheck">{{ 'You have to agree with our terms and conditions' }}</div>
            </div>
        </div>
        <button @click="register" class="btn btn-lg btn-primary btn-block w-100">
            Create Account
        </button>
        <PolarLoader v-if="loading" classes="top-none"/>
    </div>
</div>

</template>

<style>
.top-none{
    top: unset !important;
}
</style>
