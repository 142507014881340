<script>
import DisplayCard from "@/components/settings/DisplayCard.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import {settings} from "@/constants/tableSettings";
import {mapGetters} from "vuex";

export default {
    name: "settings",
    components: {PolarSearch, DisplayCard},
    data(){
        return {
            settings: settings,
            search: ''
        }
    },
    computed:{
        ...mapGetters({
            permission: "auth/loggedPermission"
        }),
        filterSettings(){
            return this.settings.filter(s => {
                const {title, api, getApi} = s;
              console.log(title, api,'title, api,')
                const search = this.search.toLowerCase();
                return (title.toLowerCase().includes(search) || api.toLowerCase().includes(search))
            })
        }
    },
    mounted() {
        if (this.permission < 3)
            this.$router.push('/')
    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="Settings" title="Manage Settings" />
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <polar-search v-model="search"/>
                </div>
                <div class="col-12 col-md-6" v-for="setting in filterSettings">
                    <DisplayCard :title="setting.title" :buttonText="setting.buttonText" :path="`/settings/${setting.api.toLowerCase()}`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
