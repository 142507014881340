<script>
import {mapMutations, mapState} from "vuex";
import {addressInputs} from "@/constants/masterData";
import {format} from "date-fns";
import api from "@/utils/api";
import ContactView from "@/components/masterdata/ContactView.vue";
import EditMasterDataModal from "@/components/masterdata/EditMasterDataModal.vue";

export default {
    name: "AddressView",
    components: {EditMasterDataModal, ContactView},
    props: ['information'],
    data(){
        return{
            toggleAddressContacts: [],
            editModal: {
                inputs: null,
                data: null,
                title: null,
                type: null
            }
        }
    },
    computed:{
        ...mapState({
            customerMasterData: state => state.masterdata.customerMasterData,
            settingsApiData: state => state.settings.settingsApiData
        }),
        deliveryAddresses(){
            const allAddr = []
            for(const addr of this.customerMasterData.addresses){
                if(addr.transactionType === 1){
                    const {contacts, ...rest} = addr;
                    allAddr.push(rest)
                    allAddr.push({contactRow: true, addressId: addr.id, contacts})
                }
            }
            return allAddr;
            return this.customerMasterData.addresses.filter(a => a.transactionType === 1);
        },
        addressInputs(){
            return addressInputs.addresses;
        },
        contacts(){
            if(this.currentAddress){
                return this.currentAddress.contacts.map(() => {
                    return {

                    }
                })
            }
        }
    },
    methods:{
        ...mapMutations({
            mutCloneEditAddress: "masterdata/mutCloneEditAddress"
        }),
        isAddressOpen(id){
            const idx = this.toggleAddressContacts.findIndex(i => i === id);
            return idx >=0;
        },
        handleToggleContact(id){
            const idx = this.toggleAddressContacts.findIndex(i => i === id);
            if(idx >= 0){
                this.toggleAddressContacts.splice(idx, 1);
            } else {
                this.toggleAddressContacts.push(id)
            }
        },
        handleEditAddress(data){
            console.log(data, 'dataaaa')
            this.mutCloneEditAddress({
                data: data,
                inputs: addressInputs.addresses,
                title: `Delivery Address`,
                type: 'address'
            })
            // this.editModal.data = data;
            // this.editModal.inputs = addressInputs.addresses;
            // this.editModal.title = `Delivery Address`;
            // this.editModal.type = 'address'
        },
        resetEditModal(){
            this.mutCloneEditAddress({
                data: null,
                inputs: null,
                title: null,
                type: null
            })
        },
        handleAddressDel(){

        },
        handleModalSave(){

        },
        formatDate(date){
            return format(date, 'dd MMMM yyyy')
        },
        getInputData(input, data){
            console.log(input.key, data[input.key], this.settingsApiData[input.api])
            if(input.api && this.settingsApiData[input.api]){
                const opt = this.settingsApiData[input.api].find(o => o.id === data[input.key]);
                if(opt){
                    return opt.name || opt[input.displayKey]
                }
            }
            return data[input.key]
        }
    }
}
</script>

<template>
    <div>
        <div class="table-responsive add-project h-100">
            <table class="table table-hover card-table table-vcenter text-nowrap">
                <thead>
                <tr>
                    <th>
                        <div class="p-1 max-w-min text-capitalize">
                            <i class="fa fa-circle-o"></i>
                        </div>
                    </th>
                    <th v-for="(input, idx) in addressInputs" :key="input.key" :class="['fw-bold', {'text-center': idx > 1}]">
                        <div :class="['p-1 max-w-min text-capitalize', {'mx-auto': idx > 1}]">
                            {{ input.label }}
                        </div>
                    </th>
                    <th class="fw-bold text-center">
                        <div :class="['p-1 max-w-min text-capitalize mx-auto']">
                            Actions
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="data in deliveryAddresses" :key="data.id">
                        <tr v-if="!data.contactRow">
                            <td>
                                <div class="p-1 max-w-min text-capitalize">
                                    <i :class="['fa fs-5 cursor-pointer', isAddressOpen(data.id) ? 'fa-minus-circle font-warning' : 'fa-plus-circle font-primary']" @click="handleToggleContact(data.id)"></i>
                                </div>
                            </td>
                            <td v-for="(input, idx) in addressInputs" :class="[{'text-center': idx > 1}]" :key="`${data.id}|${input.key}`">
                                <div v-if="input.type === 'boolean' || !data[input.key]" :class="['badge p-2 w-100', (data[input.key] ? 'badge-success' : 'badge-warning')]" style="max-width: max-content;">
                                    <div class="f-w-700">
                                        {{data[input.key] ? 'YES' : (input.type === 'boolean' ? 'NO' : `No ${input.label}`)}}
                                    </div>
                                </div>
                                <div v-else-if="input.type === 'date'">
                                    {{ formatDate(data[input.key]) }}
                                </div>
                                <div v-else-if="input.api && settingsApiData[input.api]?.length">
                                    {{ getInputData(input, data) }}
                                </div>
                                <div v-else>
                                    {{ data[input.key]}}
                                </div>
                            </td>
                            <td>
                                <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                    <button @click="handleEditAddress(data)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#EditMasterDataModal"
                                            class="btn btn-primary btn-sm">
                                        <i class="fa fa-pencil"></i>
                                        Edit
                                    </button>
                                    <button class="btn btn-danger btn-sm"
                                            @click="handleAddressDel(data)"
                                            type="button"
                                            disabled
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteSettingModal">
                                        <i class="fa fa-trash"></i>
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="toggleAddressContacts.includes(data.addressId)">
                            <td colspan="10">
                              <contact-view :contacts="data.contacts"/>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>

</style>
