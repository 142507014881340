import {createRouter, createWebHistory} from "vue-router"
import Body from '../components/body';
import Default from '../pages/dashboard/defaultPage.vue';
import Products from "@/pages/products/products.vue";
import login_one from "@/pages/authentication/login_one.vue";
import register from "@/pages/authentication/register.vue";
import reset_password from "@/pages/authentication/reset_password.vue";
import userSettings from "@/pages/user/userSettings.vue";
import settings from "@/pages/settings/settings.vue";
import unlock from "@/pages/authentication/unlock.vue";
import error404 from "@/pages/error/error404.vue"
import store from '@/store/index'
import edit from "@/pages/user/edit.vue";
import account from "@/pages/user/account.vue";
import setting_table from "@/pages/settings/setting_table.vue";
import single_product from "@/pages/products/single_product.vue";
import user_notifications from "@/pages/user/user_notifications.vue";
import faq from "@/pages/faq/faq.vue";
import landing from "@/pages/authentication/landing.vue";
import terms from "@/pages/terms.vue";
import contact from "@/pages/contact.vue";
import patientList from "../pages/patientList.vue";
import supervisorRequests  from "../pages/supervisorRequests.vue";
import master_data from "@/pages/masterdata/master_data.vue";
import edit_masterdata from "@/pages/masterdata/edit_masterdata.vue";
import validation_status from "@/pages/masterdata/validation_status.vue";
import lock_screen from "@/pages/authentication/lock_screen.vue";

const routes =[
    {
        path: '/',
        component: Body,
        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: Default,
                meta: {
                    title: 'Dashboard'
                }
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: faq,
                meta: {
                    title: 'FAQ'
                }
            },
            {
                path: 'patientList',
                name: 'Patient List',
                component: patientList,
                meta: {
                    title: 'Patient List'
                }
            },
            {
                path: 'supervisorRequests',
                name: 'Supervisor Requests',
                component: supervisorRequests,
                meta: {
                    title: 'Supervisor Requests'
                }
            },
            {
                path: 'master-data',
                name: 'Master Data',
                children: [
                    {
                        path: '',
                        name: 'MasterData',
                        component: master_data,
                        meta: {
                            title: 'Master Data'
                        }
                    },
                    {
                        path: 'validation-status',
                        name: 'ValidationStatus',
                        component: validation_status,
                        meta: {
                            title: 'Customer Validation Status'
                        }
                    },
                    {
                        path: ':customerId',
                        name: 'Edit MasterData',
                        component: edit_masterdata,
                        meta: {
                            title: 'Edit Master Data'
                        }
                    },
                ]
            },
            {
                path: 'products',
                name: 'products',
                children: [
                    {
                        path: '',
                        name: 'Products',
                        component: Products,
                        meta: {
                            title: 'Products',
                        }
                    },
                    {
                        path: ':productId',
                        component: single_product,
                        name: 'Single Product',
                        meta: {
                            title: 'Single Product',
                        }
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        name: 'Settings',
                        component: settings,
                        meta: {
                            title: 'Settings',
                        }
                    },
                    {
                        path: ':setting',
                        component: setting_table,
                        name: 'Settings Table',
                        meta: {
                            title: 'Settings Table',
                        }
                    }
                ]
            },
            {
                path: 'user',
                children: [
                    {
                        path: 'settings',
                        name: 'User Settings',
                        component: userSettings,
                        meta: {
                            title: 'User Settings',
                        }
                    },
                    {
                        path: 'edit',
                        name: 'User Edit',
                        component: edit,
                        meta: {
                            title: 'User Editor',
                        }
                    },
                ]
            },

            {
                path: 'account',
                children: [
                    {
                        path: '',
                        name: 'User Account',
                        component: account,
                        meta: {
                            title: 'User Account',
                        }
                    },
                    {
                        path: 'notification-dashboard',
                        name: 'Notification Settings',
                        component: user_notifications,
                        meta: {
                            title: 'User Notifications',
                        }
                    },
                ]
            },
            {
                path: 'support',
                name: 'Support',
                component: contact,
                meta: {
                    title: 'Support'
                }
            },
        ]
    },
    // {
    //     path: '/',
    //     component: Body,
    //     children: [
    //         {path: '/products', name: 'products', component: Products}
    //     ]
    // },
    {
        path: '/auth',
        children: [
            {
                path: 'landing',
                name: 'Landing',
                component: landing,
                meta: {
                    title: 'Landing | Polar Speed',
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: login_one,
                meta: {
                    title: 'Login | Polar Speed',
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: register,
                meta: {
                    title: 'Register | Polar Speed',
                }
            },
            {
                path: 'email/:email',
                name: 'Reset Password',
                component: reset_password,
                meta: {
                    title: 'Reset Password | Polar Speed',
                }
            },
            {
                path: ':userId',
                name: 'Set New Password',
                component: unlock,
                meta: {
                    title: 'Set New Password | Polar Speed',
                }
            },
            {
                path: 'lock-screen',
                name: 'Lock Screen',
                component: lock_screen,
                meta: {
                    title: 'Lock Screen | Polar Speed'
                }
            }
        ]
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms And Conditions',
        component: terms,
        meta: {
            title: 'Terms and Conditions'
        }
    },
    { path: '/:pathMatch(.*)', component: error404 }
]
const router= createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const user = !!store.state.auth.loggedUser;
    if(to.meta?.title)
        document.title = to.meta.title;
    console.log(store.state.auth.loggedUser, 'user user user user')
    if(to.path === '/terms-and-conditions' ||to.path === '/support')
        return next();

    const splitPath = to.path.split("/");
    const userPermission = store.getters["auth/loggedPermission"] || 0;

    const rolePaths = [
        {path: '/user/settings', reqPermission: 2},
        {path: '/settings', reqPermission: 3},
        {path: '/user/edit', reqPermission: 3},
        {path: '/settings/hospital', reqPermission: 3},
        {path: '/settings/titles', reqPermission: 3},
        {path: '/settings/portalconfiguration', reqPermission: 3},
        {path: '/settings/therapy', reqPermission: 3},
        {path: '/settings/faq', reqPermission: 3},
    ]

    if(splitPath[1] === 'auth'){
        if(splitPath[2] === 'email'){
            store.dispatch('auth/actLogOut', {noRedirect: true})
            return next();
        }
        if(user){
            return next('/')
        }
        return next();
    }

    if(user){
        if(splitPath[1] === 'settings'){
            if(userPermission < 2)
                return next('/');
        }

        for(const p of rolePaths){
            if(to.path === p.path){
                console.log(userPermission,p.reqPermission > userPermission,  'pathetttttpathettttt', p.reqPermission)

                if(p.reqPermission > userPermission)
                    return next('/');
            }
        }
        return next();
    }

    next('/auth/landing');
});
export default router
