<script>
import HomeCard from "@/components/homeCard.vue";
import DashboardCards from "@/data/dashboardCards.json";

export default {
  name: "Landing",
  data(){
    return{
      cards:[
          {
            title:"Healthcare Professionals Registration",
            description:"If you're a healthcare professional, kindly click the button below to initiate the registration process.",
            buttonLabel:"Healthcare Professionals Registration",
            path:"/auth/register",
            color: '#0ad69c'
          },
          {
            title:"Active Users Login",
            description:"If you've already verified your account, please click the button below to log in.",
            buttonLabel:"Login Now",
            path:"/auth/login",
            color: '#7980fc'
          },
          // {
          //   title:"Patient Registration",
          //   description:"If you're a patient kindly click the button below to initiate the registration process.",
          //   buttonLabel:"Patient Registration",
          //   path:"/",
          //   color: '#70777f'
          // },
      ],
    }
  },
}
</script>

<template>
  <div class="bcg" style="height: 100dvh">
  <div class="d-flex flex-wrap gap-3 justify-content-center center" style="width: 100dvw">
    <div class="col-12 col-sm-6 col-xl-4 p-4 card course-box card-body course-widget" :style="`border: 2px solid ${card.color}; max-width: 28rem; max-height:15rem`" v-for="(card, idx) in cards" :key="idx">
      <h5 class="mb-2" :style="`color: ${card.color}`">{{ card.title }}</h5>
      <span class="f-light cut-text">{{ card.description }}</span>
      <router-link class="btn f-light max-w-max" :style="`background: ${card.color}; color:white`" :to="card.path">
        {{ card.buttonLabel }}
      </router-link>
    </div>
  </div></div>

</template>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bcg{
  background: url('../../assets/images/login/login_bg.jpg');
  background-position: center;
}
</style>
