<script>
import {differenceInSeconds} from "date-fns";

export default {
   name: "LockScreenModal",
   data(){
      return {
         lastActivity: null,
         interval: null,
         showModal: false,
         // secondsPassed: 0
      }
   },
   computed:{
      isInDev(){
         return process.env.NODE_ENV === 'development'
      }
   },
   methods:{
      setLasActivity(){
         this.lastActivity = new Date();
      },
      checkIfUserInactive(){
         const time = this.isInDev ? 10000 : 120;
         // this.secondsPassed++;
         if(this.lastActivity){
            const result = differenceInSeconds(new Date(), this.lastActivity)
            if(result >= time && !this.showModal){
               this.openModal();
            } else if(result >= (time * 2)){
               //send to lock screen
               this.$refs?.closeModal.click();
               this.handleModalClose();
               this.$store.dispatch('auth/actLockScreen');
            }
         }
      },
      createInterval(){
         this.interval = setInterval(this.checkIfUserInactive, 1000)
      },
      openModal(){
         this.$refs.toggleModal?.click();
         this.showModal = true
      },
      handleModalClose(){
         this.showModal = false
      }
   },
   created() {
      this.setLasActivity();
      document.addEventListener('mousemove', this.setLasActivity);
      this.createInterval();
   },
   beforeUnmount() {
      document.removeEventListener('mousemove', this.setLasActivity);
      clearInterval(this.interval)
   }

}
</script>

<template>
    <div class="modal fade" id="lockScreenModal" aria-labelledby="lockScreenModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body d-flex flex-column justify-content-center align-items-center">
                    <img src="@/assets/images/gif/wave.gif" alt="wave">
                    <div class="fs-5 text-center">
                        You have been inactive for a while.
                        <br>
                        Are you still here?
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" ref="closeModal" class="btn btn-lg btn-primary" @click="handleModalClose" data-bs-dismiss="modal">Yes</button>
                </div>
            </div>
        </div>
    </div>
    <button class="d-none"
            ref="toggleModal"
            data-bs-toggle="modal"
            data-bs-target="#lockScreenModal"></button>
</template>

<style scoped>

</style>
