import api from "@/utils/api";
import {errorToast} from "@/utils/helperFunctions";

const state = {
    settingsApiData: {}
}

const getters = {

}

const mutations = {
    mutSettingsApiData: (state, {api, data}) => {
        state.settingsApiData[api] = data;
    },
    mutEmptyApiData: (state) => {
        state.settingsApiData = {};
    }
}

const actions = {
    actGetAllApiData: ({commit}, apiURLs) => {
        for(const url of apiURLs){
            commit('mutSettingsApiData', {
                api: url,
                data: []
            })
            api.get(`/${url}`, {
                params: {
                    pageNumber: 1,
                    pageSize: 10000
                }
            }).then(({data}) => {
                commit('mutSettingsApiData', {
                    api: url,
                    data: data.items
                })
            }).catch((e) => {
                errorToast(e)
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
