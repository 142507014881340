<script>
import {cUSettings, colTitle} from "@/constants/tableSettings";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import SettingsInput from "@/components/settings/SettingsInput.vue";

export default {
    name: "CreateEditSettings",
    components: {SettingsInput},
    props: ['settingType', 'editSetting', 'useAsComponent'],
    data(){
        return {
            settingsData: null,
            formType: [],
            oldData: null
        }
    },
    computed:{
        currCUS(){
            return cUSettings[this.settingType.api]
        }
    },
    methods: {
        async handleCreate(){
            try {
                console.log('this.settingsData', this.settingsData);
                if(this.editSetting?.id){
                    const {id, ...rest} = this.settingsData;
                    await api.put(`/${this.settingType.api}/${this.editSetting.id}`, rest);
                } else {
                  let a = this.settingType.createApi ? this.settingType.api + this.settingType.createApi : this.settingType.api
                    await api.post(`/${a}`, this.settingsData);
                }
                this.closeModal();
                this.$emit('settingCreated')
                useToast().success(`${this.settingType.title} ${this.editSetting ? 'changed' : 'created'}`);
            } catch (e) {
                const {status, errors} = e.response.data;
                if(status === 409){
                    useToast().error(errors[0].description);
                } else {
                    useToast().error(e.response.data?.title || 'Something went wrong');
                }
            }
        },
        defineSettingsAndData(setDefault){
            const cUS = this.currCUS;
            const form = [];
            const data = {}
            for(const key in cUS){
                const formInput = {
                  label: colTitle(key, this.settingType.title),
                    ...cUS[key],
                    key
                }
                form.push(formInput);

                if(!this.editSetting || setDefault){
                    data[key] = cUS[key].default;
                }
            }
            this.settingsData = this.editSetting && !setDefault ? {...this.editSetting} : data;
            this.formType = form;
        },
        closeModal(){
            this.defineSettingsAndData(true);
            document.getElementById('dismissCreateSettings').click();
        },
        handleDataChange(val, key){
            console.log({val, key});
            this.settingsData[key] = val;
            this.monitorRelations(key);
        },
        monitorRelations(key){
            let nextKey = ''
            for(const sKey in this.currCUS){
                if(nextKey === this.currCUS[sKey]?.relation){
                    this.settingsData[sKey] = null;
                    console.log('setting next data to default', sKey)
                } else if(this.currCUS[sKey]?.relation === key){
                    console.log('setting data to default', sKey)
                    this.settingsData[sKey] = null;
                    nextKey = sKey;
                }
            }
        }
    },
    beforeUnmount() {
        this.defineSettingsAndData(true);
    },
    created() {
        this.defineSettingsAndData();
    }
}
</script>

<template>
        <div>
            <div class="modal-body">
                <div class="theme-form row">
                  <SettingsInput v-for="(input, idx) in formType"
                                 :key="input.key"
                                 class="col-md-6"
                                 :settingsData="settingsData"
                                 :model-value="settingsData[input.key]"
                                 @update:model-value="(val) => handleDataChange(val, input.key)"
                                 :input="input"
                  />
                </div>
            </div>
            <div class="modal-footer">
                <button id="dismissCreateSettings" class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-primary" type="button" @click="handleCreate">{{ editSetting ? 'Edit' : 'Create' }}</button>
            </div>
        </div>
</template>

<style scoped>

</style>
