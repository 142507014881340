import { useToast } from 'vue-toastification';

const toast = useToast();
export const errorToast = (e) => {
    const {errors, status, title} = e.response?.data || {errors: [{description: 'Something wrong happened'}]}
    toast.error(errors[0].description);
}
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const validatePhoneNumber = (number) => {
    return number.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
}

export const validatePassword = (password) => {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return re.test(password)
}
export const downloadReport = ({data, type, fileName}) => {
    const ab = atob(data);
    const bytes = new Uint8Array(ab.length);
    for (let i = 0; i < ab.length; i++) {
        bytes[i] = ab.charCodeAt(i);
    }
    const byteArray = new Uint8Array(bytes.buffer);
    const blob = new Blob([byteArray], { type });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger the download
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(link.href);
}

export const getPermissionByte = (role) => {
    let pByte = 0
    switch (role) {
        case 'Admin':
        case 'Polar Speed Admin':
            pByte = 3
            break;
        case 'HealthCare Professional':
            pByte = 2
            break;
        default:
            pByte = 1;
    }
    return pByte
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const separateOnCapitalLetter = (string) => {
    let [first, ...rest] = string.split(/(?=[A-Z])/);
    first = capitalizeFirstLetter(first);
    return [first,...rest].join(" ")
}

export const checkIfDate = (dateToCheck) =>{
    if(!dateToCheck || !isNaN(dateToCheck)) return false;
    let date = dateToCheck.replace(/\s/g, '');
    try {
        if(date) date = new Date(date);
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    } catch (e) {
        return false;
    }
}
export const getFileFormData = async (file) => {
    const formData = new FormData();
    for(let x= 0; x<file.length; x++){
        formData.append( 'File', file[x]);
    }
    // formData.append('file', file);
    return formData
}

export const ordinal_suffix_of = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}
