/* eslint-disable */
import api from '@/utils/api'
import { useToast } from 'vue-toastification';
import {getPermissionByte} from "@/utils/helperFunctions";
import {errorToast} from "@/utils/helperFunctions";
import router from '../../router';

const toast = useToast();


const state = {
    user: null,
    token: '',
    permission: null,
    resetToken: '',
    userId: null,
    loggedUser: null,
    authResponse: {
        title: '',
        description: ''
    },
    roles: [
        {title: 'Admin', key: 'Admin', pByte: 3},
        {title: 'Read Only', key: 'Read Only', pByte: 1},
        {title: 'Customer Service', key: 'Customer Service', pByte: 2},
        {title: 'Regular User', key: 'Regular User', pByte: 1},
        {title: 'Call Centre', key: 'Call Centre', pByte: 1},
    ],
    emailConfirmed: false
};

// getters
const getters = {
    loggedPermission: (state) => {
        return getPermissionByte(state.loggedUser?.role);
    },
    canModifyUser: (state, getters) => (user) => {
        return (getters.loggedPermission > 2) || state.loggedUser?.isSupervisor
    },
    userRoles: (state) => {
        return state.roles.filter(r => r.pByte <= state.permission);
    }
};

// mutations
const mutations = {
    mutSetState: (state, {key, value}) => {
        state[key] = value;
    },
    mutSetPermissions: (state, role) => {
        state.permission = getPermissionByte(role)
    },
    mutSetUserId: (state, userID) => {
        state.userId = userID
    },
    // mutSetToken: (state, token) => {
    //     state.token = token
    // },
    mutLoggedUser: (state, user) => {
        // const logUser = JSON.parse(localStorage.getItem('logged_user'));
        console.log('trying editing logged user')
        if(state.loggedUser?.id === user.id){
            console.log('editing logged user')
            state.loggedUser = {...state.loggedUser, ...user};
            localStorage.setItem('logged_user', JSON.stringify(state.loggedUser));
        }
    }
};

// actions
const actions = {
    actLoginMfa: async ({commit, dispatch}, loginInfo) => {
        try{
            const response = await api.post(`/User/mfaverification`,null,{params:{email:loginInfo.email,code:loginInfo.code}})
            console.log(loginInfo,'loginInfo',response)
            if(response.data){
                console.log('success', response)
                localStorage.setItem('auth_info', JSON.stringify(response.data.value));
                localStorage.setItem('log_email', loginInfo.email);
                let token = response.data.value.accessToken
                console.log(token,'tokennn')
                let resp = await dispatch('decodeToken', token)
                console.log(resp,'respppppp')
                await dispatch('actGetLogUserData', resp)
            }
        } catch (e) {
            const {errors, status, title} = e.response.data
            console.log('errors::', e)
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title,
                    description: errors[0]?.description
                }
            })
            console.log('failed login', e)
        }
    },
    actLogin: async ({commit, dispatch}, loginInfo) => {
        try{
            // const response = await fetch(`https://pharmacyapidev.azurewebsites.net/api/v1/User/login?email=${loginInfo.email}&password=${loginInfo.password}`, {
            //     method: 'POST',
            //     credentials: 'include',
            //     headers: {
            //         'accept': '*/*',
            //         'Content-Type': 'application/json',
            //     },
            // });
            const response = await api.post(`/User/login?email=${loginInfo.email}&password=${loginInfo.password}`)
            console.log(loginInfo,'loginInfo',response)
            if(response.data){
                console.log('success', response)
                return response.data
            }
        } catch (e) {
            const {errors, status, title} = e.response.data
            console.log('errors::', e)
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title,
                    description: errors[0]?.description
                }
            })
            // }
            console.log('failed login', e)
        }
    },
    actLockScreen: ({dispatch, rootState}) => {
        const user = {...rootState.auth.loggedUser};
        dispatch('actLogOut', {lockScreen: true});
        localStorage.clear();
        localStorage.setItem('lockScreenUser', JSON.stringify({
            email: user.email,
            firstName: `${user?.firstName}`,
            lastName: `${user?.lastName}`,
        }))
        router.push('/auth/lock-screen');
    },
    decodeToken(a, token){
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let obj = JSON.parse(jsonPayload);
        return obj?.id

    },
    actGetLogUserData: async ({commit}, id) => {
        const {data} = await api.get(`/user/${id}`);



        // const data ={
        //     "id": "deef920b-c625-47a3-96a6-addb0805fb9e",
        //     "title": "Mr",
        //     "firstName": "Kr",
        //     "lastName": "Hysenaj",
        //     "phoneNumber": "0696696144",
        //     "email": "krisel_hysenaj@hotmail.com",
        //     "isActive": true,
        //     "permission": {
        //         "canViewClientAddress": true,
        //         "canEditClientAddress": true,
        //         "canCreateNewClientAddress": true
        //     },
        //     "role": "Admin",
        //     "customerMasterDataId": null
        // }

        console.log('dataaaaaaaaa',data)
        commit('mutSetState', {
            key: 'loggedUser',
            value: data?.value
        })
        commit('mutSetPermissions', data.value.role);
        localStorage.setItem('logged_user', JSON.stringify(data.value));
    },
    actRegister: async({commit}, registerInfo) => {
        try {
            const response = await api.post('/user/register', registerInfo,{customError: true})
            if(response.data){
                console.log('success', response)
                commit('mutSetUserId', response.data)
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title: 'Account has been registered',
                        description: `We have sent a confirmation link in your email: <b class="text-primary">${registerInfo.email}</b>`
                    }
                })
            } else {
                console.error('failed register', response)
            }
            return 'success';
        } catch (e) {
            const {errors, status, title} = e.response.data
            // if(status === 409){
            //user exists
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title,
                    description: errors[0]?.description
                }
            })
            // }
            errorToast(e);
            console.log('failed register', e)
            return e;
        }
    },
    actConfirmEmail: async({commit}, {id, token}) => {
        try {
            const response = await api.post(`/user/confirmEmail/${id}`, null,{
                params: {
                    token
                }
            })
            console.log(response, 'response actConfirmEmail')
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title: 'Email confirmed successfully',
                    description: 'You will receive another email as soon as your account is activated.'
                }
            })
            commit('mutSetState', {key: 'emailConfirmed', value: true})
        } catch (e) {
            const {errors, status, title} = e.response?.data || {}
            if(status === 404 || status === 500){
                //user dont exist or token invalid
                commit('mutSetState', {
                    key: 'authResponse',
                    value: {
                        title,
                        description: errors[0]?.description
                    }
                })
            }
        }
    },
    actResetPassword: async({commit, dispatch}, resetInfo) => {
        try {
            const response = await api.post('/user/resetPassword', resetInfo)
            if(response?.data){
                console.log('success', response)
                // commit('mutSetUserId', response.data)
                return await dispatch('actLogin', {email: resetInfo.email, password: resetInfo.password});
            } else {

            }

        } catch (e) {
            console.log('failed login', e);
            const {errors, status, title} = e.response.data
            commit('mutSetState', {
                key: 'authResponse',
                value: {
                    title,
                    description: errors[0]?.description
                }
            })
        }
    },
    actChangePassword: async ({commit}, {id,...rest}) => {
        try {
            const response = await api.post(`/user/changePassword/${id}`, rest);
            console.log(response, 'response response')
            toast.success('Password changed successfully')
        } catch (e) {
            console.log(e, 'response response');
            errorToast(e)
            return 'error'
        }
    },
    actLogOut: ({commit, dispatch}, options) => {
        // commit('mutSetUser', null);
        commit('mutGlobalLoader', true, {root: true})
        commit('mutSetState', {
            key: 'loggedUser',
            value: null
        });
        if(!options?.lockScreen){
            localStorage.clear();
            const noRedirect = options?.noRedirect;
            if(!noRedirect) {
                window.location.replace('/auth/login');
            } else {
                commit('mutGlobalLoader', false, {root: true})
            }
        }
    }
    // actGetResetToken: async ({commit}, email) => {
    //     const response = await api.post('/user/getResetPasswordToken', null,{
    //         params: {email}
    //     })
    //
    //     if(response.data) {
    //         console.log(response, 'actGetResetToken success')
    //         commit('mutSetState', {key: 'resetToken', value: response.data})
    //     } else {
    //         console.log(response, 'actGetResetToken error')
    //     }
    // }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
