<script>
import PolarLoader from "@/components/PolarLoader.vue";
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import {errorToast} from "@/utils/helperFunctions";
import {format} from "date-fns";

export default {
    name: "validation_status",
    components: {PolarSearch, Pagination, PolarLoader},
    data(){
        return{
            params: {
                searchKey: '',
                columnName: '',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8,
                isUserValidated: null,
                isCustomerValidated: null
            },
            displayColumns:[
                {key: 'accountum', title: 'Customer Id'},
                {key: 'organizationName', title: 'Company Name'},
                {key: 'userEmail', title: 'User Email'},
                {key: 'isAccountNumValidated', title: 'Customer Account Validated', type: 'boolean'},
                {key: 'isUserValidated', title: 'User Validated', type: 'boolean'},
                {key: 'accountNumValidatedOnUtc', title: 'Customer Account Validation Date', type: 'date'},
                {key: 'userValidatedOnUtc', title: 'User Validation Date', type: 'date'},
            ],
            validationStatus: [],
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 0,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            loading: true
        }
    },
    methods:{
        handleSearch(){
            this.params.pageNumber = 1;
            this.getValidations();
        },
        handlePaginationEvent(page){
            this.params.pageNumber = page;
            this.getValidations();
        },
        handleSort(column){
            if(column === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = column;
            this.params.pageNumber = 1;
            this.getValidations();
        },
        formatDate(date){
            console.log(date, 'dateeeee')
            return format(date, 'dd MMMM yyyy')
        },
        async getValidations(){
            try {
                this.loading = true;
                const {data} = await api.get('/CustomerValidationStatus', {
                    params: this.params
                })
                const {items, ...rest} = data;
                this.validationStatus = items;
                this.pagination = rest;
            } catch (e) {
                errorToast(e)
            } finally {
                this.loading = false
            }
        },
        handleFilterChange(filter, value){
            this.params[filter] = value;
            this.params.pageNumber = 1;
            this.getValidations();
        }
    },
    mounted() {
        this.getValidations()
    }
}
</script>

<template>
    <div>

        <Breadcrumbs diff-main="Validation Status" main="Master Data" main-path="/master-data" title="Validation Status" />
        <div class="container">
            <div class="row">
                <div class="card position-relative overflow-hidden">
                    <div class="card-header d-flex align-items-center gap-2">
                        <div class="d-flex gap-2 flex-wrap w-100 align-items-center">
                            <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="handleSearch"/>
                                <div v-for="filter in [{key: 'isUserValidated', title: 'User Validated'}, {key: 'isCustomerValidated', title: 'Customer Validated'}]" class="dropdown" :key="filter.key">
                                    <button class="btn filterSelect" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{filter.title}} - {{ params[filter.key] ? 'Yes' : 'No' }}
                                        <i class="fa fa-caret-down m-l-5"></i>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li @click="handleFilterChange(filter.key, true)"><span class="dropdown-item">Yes</span></li>
                                        <li @click="handleFilterChange(filter.key, false)"><span class="dropdown-item">No</span></li>
                                    </ul>
                                </div>
<!--                                <select @change="handleFilterChange" v-model="params.isUserValidated" class="filterSelect">-->
<!--                                    <option selected disabled>User Validated</option>-->
<!--                                    <option :value="true">Yes</option>-->
<!--                                    <option :value="false">No</option>-->
<!--                                </select>-->
<!--                                <select @change="handleFilterChange" v-model="params.isCustomerValidated" class="filterSelect">-->
<!--                                    <option selected disabled>Customer Validated</option>-->
<!--                                    <option :value="true">Yes</option>-->
<!--                                    <option :value="false">No</option>-->
<!--                                </select>-->
<!--                                <div class="checkbox p-0">-->
<!--                                    <input v-model="params.isUserValidated" id="isUserValidated" type="checkbox" @change="handleFilterChange"/>-->
<!--                                    <label class="text-muted" for="isUserValidated">-->
<!--                                        Validated Users-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                                <div class="checkbox p-0">-->
<!--                                    <input v-model="params.isCustomerValidated" id="isCustomerValidated" type="checkbox" @change="handleFilterChange"/>-->
<!--                                    <label class="text-muted" for="isCustomerValidated">-->
<!--                                        Validated Customers-->
<!--                                    </label>-->
<!--                                </div>-->
                        </div>
                    </div>
                    <div class="card-body" style="min-height: 565px;">
                        <div class="table-responsive add-project h-100">
                            <table class="table table-hover card-table table-vcenter text-nowrap">
                                <thead>
                                <tr>
                                    <th v-for="(col, idx) in displayColumns" :key="col.key" :class="['fw-bold', {'text-center': idx > 0}]">
                                        <div @click="handleSort(col.key)" :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 0}]">
                                            {{ col.title }}
                                            <i class="fa fa-sort"/>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="data in validationStatus" :key="data.id">
                                        <td v-for="(col, idx) in displayColumns" :class="[{'text-center': idx > 0}]" :key="`${data.id}|${col.key}`">
                                            <div v-if="col.type === 'boolean'" :class="['badge py-2 px-3 w-100 max-w-max', (data[col.key] ? 'badge-success' : 'badge-warning')]">
                                                {{ data[col.key] ? 'YES' : 'NO' }}
                                            </div>
                                            <div v-else-if="col.type === 'date'">
                                                {{ data[col.key] ? formatDate(data[col.key]) : 'No date'}}
                                            </div>
                                            <div v-else>
                                                {{data[col.key]}}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer pagination-padding">
                        <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Customer Validation Status
                    </polar-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.filterSelect{
    border-color: #efefef;
    background-color: #fff;
    font-size: 14px;
    padding: 12px 10px;
    box-shadow: none;
}

.dropdown span {
    cursor: pointer;
}

</style>
