import {validatePassword} from "@/utils/helperFunctions";

export default{
    data() {
        return {
            formSubmitted: false,
            errors: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            oldPasswordError: false,
            newPasswordError: false,
            confirmPasswordError: false,
        }
    },
    computed:{
        formIsValid(){
            return !this.oldPasswordError && !this.confirmPasswordError && !this.newPasswordError;
        }
    },
    methods: {
        validatePasswordForm({oldPassword, newPassword, confirmPassword}) {
            this.formSubmitted = true;
            if (!validatePassword(oldPassword)) {
                this.oldPasswordError = true;
                this.errors.oldPassword = 'Password invalid';
            } else {
                this.oldPasswordError = false;
                this.errors.oldPassword = '';
            }
            if (!validatePassword(newPassword)) {
                this.newPasswordError = true;
                this.errors.newPassword = 'Password invalid';
            } else {
                this.newPasswordError = false;
                this.errors.newPassword = '';
            }
            if (confirmPassword !== newPassword || this.newPasswordError) {
                this.confirmPasswordError = true;
                this.errors.confirmPassword = 'Passwords invalid';
            } else {
                this.confirmPasswordError = false;
                this.errors.confirmPassword = '';
            }
        },

    }
}
