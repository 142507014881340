import axios from "axios";
import {errorToast} from "@/utils/helperFunctions";

const baseURL = process.env.NODE_ENV === 'production' ? 'https://nhsportalbackend.azurewebsites.net/api/v1'
// : 'https://pharmacyapidev.azurewebsites.net/api/v1';
: 'https://pharmacytestapi.azurewebsites.net/api/v1'

const api = axios.create({
    baseURL,
    headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
    },
    withCredentials: true

});

api.interceptors.request.use(
    config => {
        const authInfo = localStorage?.getItem('auth_info');
        // let pairs = document.cookie.split(";");
        // console.log(pairs,'ssssssdd')
        // console.log(config.withCredentials,'authInfoauthInfo')
        // config.headers['Access-Control-Allow-Origin'] = 'Content-Type'
        if(authInfo){
            try {
                const {tokenType, expiresIn, accessToken} = JSON.parse(authInfo);
                // console.log({tokenType, expiresIn, accessToken})
                if(!tokenType || !expiresIn || !accessToken) throw new Error('Invalid auth storage');
                config.headers['Authorization'] = `${tokenType} ${accessToken}`;

            } catch (e) {
                console.log(e);
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        console.log(response,'resssss')

        return response;
    },
    (error) => {
        // console.log('interceptor error', error)
        if(error.response?.status === 401){
            localStorage.clear();
            window.location.replace('/auth/login');
        }
        if(!error.response?.config.customError){
            errorToast(error)
        }
        return Promise.reject(error);
    }
)

// app.provide('api', api)
export default api;
