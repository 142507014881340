<script>
import { vOnClickOutside } from '@vueuse/components';
export default {
    name: "DropdownSearch",
    data(){
        return {
            searchString: ''
        }
    },
    props: [
        'options',
        'filterKeys',
        'modelValue',
        'displayOption',
        'displayKey',
        'disabled',
        'id',
        'backData',
        'error'
    ],
    computed:{
        filterOptions(){
            const searchWord = this.searchString.toLowerCase();
            return this.options.filter(opt => {
                for(let x =0; x < this.filterKeys.length; x++){
                    const word = opt[this.filterKeys[x]].toLowerCase()
                    if(word.includes(searchWord)){
                        return true;
                    }
                }
                return false
            })
        },
        displayValue(){
            if(this.displayKey){
                if(typeof this.displayKey === 'function')
                    return this.displayKey(this.modelValue);
                return this.modelValue[this.displayKey]
            }

            return this.modelValue
        },
    },
    methods:{
        handleOptionSelect(opt){
            console.log(this.backData)
            this.searchString= opt[this.displayKey]
            this.$emit('select', opt[this.backData])
        },
        handleFocusOut(){
            // if(this.modelValue){
            //     this.searchString = this.displayValue;
            // } else {
            //     this.searchString = '';
            // }
        },
        handleFocusIn(){
            this.searchString = '';
        }
    },
    watch:{
        modelValue(){
            this.handleFocusOut();
        }
    },
    directives:{
        onClickOutside: vOnClickOutside
    },
    mounted(){
        const myDropdown = document.getElementById(this.id || 'dropka')
        const vm = this;
        myDropdown.addEventListener('show.bs.dropdown', function () {
            vm.handleFocusIn();
        })
        this.handleFocusOut();
    },
    beforeUnmount() {
        const myDropdown = document.getElementById(this.id || 'dropka')
        const vm = this;
        myDropdown.removeEventListener('show.bs.dropdown', function () {
            vm.handleFocusIn();
        })
    }
}
</script>

<template>
<div class="dropdown" :id="id || 'dropka'" v-on-click-outside="handleFocusOut">
    <div class="d-flex align-items-center gap-3 form-input position-relative"
         aria-expanded="false"
         :data-bs-toggle="disabled ? '' : 'dropdown'">
        <input type="text"
               :disabled="disabled"
               :class="['form-control', {'is-invalid': !!error}]"
               @focusin="handleFocusIn"
               v-model="searchString"
               placeholder="Search">
        <span class="position-absolute" style="right: 12px;">
            <i class="fa fa-caret-down"></i>
        </span>
    </div>
    <ul class="dropdown-menu w-100 min-w-max" style="max-height: 300px;height: min-content; overflow-y: auto; z-index: 2">
        <li v-for="opt in filterOptions" class="fs-6 cursor-pointer" @click.prevent="handleOptionSelect(opt)">
            <div class="dropdown-item">
                <slot name="option" :data="opt">
                    {{ opt[displayKey]}}
                </slot>
            </div>
        </li>
        <li v-if="!filterOptions.length">
            <div class="dropdown-item">
                There are no matches
            </div>
        </li>
    </ul>
</div>
</template>

<style scoped>
.dropdown-item:hover{
    background-color: #f6f6f6;
    font-weight: bold;
}
</style>
