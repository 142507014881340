<script>
export default {
    name: "ProductImage",
    props: ['image']
}
</script>

<template>
    <div class="card">
        <div class="card-body justify-content-center">
            <img :src="image || 'https://m.media-amazon.com/images/I/41VdYm0RE7L.jpg'" class="img-fluid bg-img" alt="product image" />
        </div>
    </div>
</template>

<style scoped>

</style>
