<script setup>

</script>

<template>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <slot name="header">Please confirm!</slot>
                </h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="text-start">
                    <slot name="message">Are you sure you want to do this?</slot>
                </p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">No</button>
                <button class="btn btn-danger" type="button" @click="() => $emit('confirmed')" data-bs-dismiss="modal">Yes</button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
