<script>
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import {format} from "date-fns";
import PolarLoader from "@/components/PolarLoader.vue";

const productList = []


export default {
    components: {PolarLoader, PolarSearch},
    data(){
        return{
            loading: false,
            productList,
            productDisplay: [
                {key: 'urlImage', title: 'Product'},
                {key: 'name', title: 'Name'},
                {key: 'barcode', title: 'Code'},
                {key: 'description', title: 'Description'},
                {key: 'unitOfMeasureSymbol', title: 'Measure Symbol'},
                // {key: 'createdOnUtc', title: 'Added Date'},
                {key: 'psdwP_OutOfStock', title: 'Stock'},
            ],
            params: {
                searchKey: '',
                columnName: '',
                isDescending: false,
                pageNumber: 1,
                pageSize: 8
            }
        }
    },
    computed:{
        productsDisplayData(){
            return this.productList.map(p => {
                return {
                    ...p,
                    // createdOnUtc: format(p.createdOnUtc, 'PPpp'),
                }
            })
        }
    },
    methods:{
        async handleGetProducts(){
            this.loading = true;
            try {
                const {data} = await api.get('/ProductMasterData', {
                    params: this.params
                })
                this.productList = data.items;
            } catch (e) {
                console.log(e, 'error');
            }
            this.loading = false;
        },
        viewProduct(id){
            this.$router.push(`/products/${id}`);
        },
        handleSort(key){
            if(key === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = key;
            this.params.pageNumber = 1;
            this.handleGetProducts();
        },
        handleSearch(){
            this.params.pageNumber = 1;
            this.handleGetProducts();
        }
    },
    mounted() {
        this.handleGetProducts();
    }
}
</script>

<template>
    <Breadcrumbs main="Manage Products" title="Product List" />

    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="card position-relative overflow-hidden">
                    <div class="card-header">
                        <PolarSearch v-model="params.searchKey" @search="handleSearch" style="max-width: 400px;"/>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover card-table table-vcenter text-nowrap" style="min-height: 200px">
                                <thead>
                                <tr>
                                    <th v-for="head in productDisplay" @click="handleSort(head.key)" :key="head.title" class="fw-bold cursor-pointer" scope="col">
                                        {{head.title}}
                                        <i class="fa fa-sort"/>
                                    </th>
                                    <th scope="col" class="text-center fw-bold">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="product in productsDisplayData" :key="product.id">
                                    <td v-for="head in productDisplay">
                                        <img v-if="head.key === 'urlImage'"
                                             style="max-width: 100px;"
                                             :src="product[head.key]"
                                             alt="product">
                                        <div v-else-if="head.key === 'psdwP_OutOfStock'"
                                             :class="!product[head.key] ? 'font-success' : 'font-danger'"
                                        >
                                            {{!product[head.key] ? 'In stock' : 'Out of stock'}}
                                        </div>
                                        <div v-else>
                                            {{product[head.key]}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2 justify-content-center">
                                            <button class="btn btn-outline-primary btn-sm ms-1"
                                                    @click="viewProduct(product.id)"
                                                    type="button"
                                                    data-original-title="btn btn-danger btn-sm" title="">
                                                <i class="fa fa-eye"/> View
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Products
                    </polar-loader>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>

</style>
