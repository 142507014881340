<script>
import {addressInputs} from "@/constants/masterData";

export default {
    name: "ContactView",
    props:['contacts'],
    data(){
        return{
            communicationOptions: [
                {type: 0, title: `Contact Phone`},
                { type: 1, title: `Contact Email`},
            ],
            communicationType: 0
        }
    },
    computed:{
        contactInputs(){
            return addressInputs.contacts;
        },
        currentContacts(){
            return this.contacts.filter(c => c.communicationType === this.communicationType)
        }
    },
    methods:{
        handleContactDel(){

        }
    }
}
</script>

<template>
    <div>
        <div class="d-flex gap-3 pb-1 pt-1 align-items-end">
            <ul class="nav nav-tabs w-100">
                <li v-for="nav in communicationOptions" class="nav-item">
                    <div @click="() => communicationType = nav.type" :class="['nav-link cursor-pointer', {'font-primary active': communicationType === nav.type}]">
                        {{nav.title}}
                    </div>
                </li>
            </ul>
        </div>
        <div class="table-responsive add-project h-100">
            <table class="table table-hover card-table table-vcenter text-nowrap">
                <thead>
                <tr>
                    <th v-for="(input, idx) in contactInputs" :key="input.key" :class="['fw-bold', {'text-center': idx > 1}]">
                        <div :class="['p-1 max-w-min text-capitalize', {'mx-auto': idx > 1}]">
                            {{ input.label }}
                        </div>
                    </th>
                    <th class="fw-bold text-center">
                        <div :class="['p-1 max-w-min text-capitalize mx-auto']">
                            Actions
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="data in currentContacts" :key="data.id">
                        <td v-for="(input, idx) in contactInputs" :class="[{'text-center': idx > 1}]" :key="`${data.id}|${input.key}`">
                            <div v-if="input.type === 'boolean' || !data[input.key]" :class="['badge p-2 w-100', (data[input.key] ? 'badge-success' : 'badge-warning')]" style="max-width: max-content;">
                                <div class="f-w-700">
                                    {{data[input.key] ? 'YES' : (input.type === 'boolean' ? 'NO' : `No ${input.label}`)}}
                                </div>
                            </div>
                            <div v-else>
                                {{ data[input.key]}}
                            </div>
                        </td>
                        <td>
                            <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                <button class="btn max-w-max btn-danger btn-sm"
                                        @click="handleContactDel(data)"
                                        type="button"
                                        disabled
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteSettingModal">
                                    <i class="fa fa-trash"></i>
                                    Delete
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>

</style>
