<script>
import {mapState} from "vuex";
import PasswordModal from "@/components/modals/PasswordModal.vue";
import SupervisorRequests from "@/components/modals/RequestSupervisorModal.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";

export default {
    name: "account",
    components: {SettingsInput, PasswordModal,SupervisorRequests},
    data(){
        return {
            accPermission: [
                {title: 'Can view client address', key: 'canViewClientAddress'},
                {title: 'Can edit client address', key: 'canEditClientAddress'},
                {title: 'Can create client address', key: 'canCreateNewClientAddress'},
            ],
        }
    },
    computed:{
        ...mapState({
            loggedUser: state => state.auth.loggedUser
        }),
        personalInfo(){
            return [
                {title: 'Full name', value: `${this.loggedUser?.firstName} ${this.loggedUser?.lastName}`},
                {title: 'Email', value: this.loggedUser?.email},
            ]
        },
        permissionInfo(){
            // const addressP = this.accPermission.map(p => {
            //     return {title: p.title, value: this.loggedUser?.permission[p.key]}
            // })
            // return [...addressP, {title: 'Role', value: this.loggedUser?.role}];
          console.log(this.loggedUser,'this.loggedUserthis.loggedUserthis.loggedUser')
           let val = this.loggedUser.role === 'Polar Speed Admin'
           let arr = [
              {title: 'Hospital Supervisor', value: !!this.loggedUser?.hospitals?.items[0]?.isSupervisor ? 'Yes' : 'No'},
           ]
           if(val)
              arr.push({title: 'Role', value: `${this.loggedUser?.role}.`})
          return arr
        },
        handleNotifications(){
            this.$router.push('/account/notification-dashboard')
        },
        handleSupport(){
            this.$router.push('/support')
        }
    },
    methods:{
      async requestSupervisor(h, idx){
        if (h.isSupervisor !== 0)
          return
        console.log(h,'hhhhhhhhhhh',idx)
        try {
          const {data} = await api.post('/user/request', null,{
            params:{
              userId: this.loggedUser.id,
              hospitalId: h.id
            }
          })
          console.log('erdhi', data)
            this.loggedUser.hospitals.items[idx].isSupervisor = 2
          useToast().success('Request Send')

        } catch (e) {
          console.log('errrrrrrrrr')
        }

      },
        handleLogOut(){
            this.$store.dispatch('auth/actLogOut')
        }
    }
}
</script>

<template>
    <div>
        <Breadcrumbs main="User Profile" title="Manage Account" />
        <div class="container">
            <div class="user-profile">
                <div class="card hovercard text-center">
                    <div class="cardheader bannerProfile"></div>
                    <div class="user-image">
                        <div class="avatar"><img id="profile-tour" alt="" src="@/assets/images/user/user.png" data-intro="This is Profile image"></div>
    <!--                    <div class="icon-wrapper"><i id="update-profile-tour" class="icofont icofont-pencil-alt-5" data-intro="Change Profile image here"></i></div>-->
                    </div>
                    <div class="info">
                        <div class="row" data-intro="This is the your details" id="info-bar-tour">
                            <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                                <div class="row">
                                    <div class="col-md-6 col-xl-4 p-1">
                                        <button @click="handleSupport" class="btn py-1 px-1 btn-success w-100">
                                            <i class="fa fa-life-buoy"/>
                                            Support
                                        </button>
                                    </div>
                                    <div class="col-xl-8 p-1">
                                        <button class="btn py-1 px-1 btn-warning w-100">
                                            <i class="fa fa-heartbeat"/>
                                            {{loggedUser?.healthcareType}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 order-first col-lg-4 order-lg-1">
                                <div class="user-designation">
                                    <div class="title f-w-600 font-primary">{{loggedUser?.firstName}} {{loggedUser?.lastName}}</div>
                                    <h5 class="mt-2">
                                        <span :class="['btn cursor-default', (loggedUser?.isActive ? 'btn-outline-success' : 'btn-outline-danger')]">
                                            {{ loggedUser?.isActive ? 'Active' : 'Inactive' }}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                                <div class="row">
                                    <div class="col-xl-7 p-1">
                                        <button
                                            class="btn btn-primary py-1 px-1 w-100"
                                            data-bs-toggle="modal"
                                            data-bs-target="#changePassword"
                                        >
                                            <i class="fa fa-lock"/>
                                            Change Password
                                        </button>
    <!--                                    <div class="ttl-info text-start ttl-xs-mt">-->
    <!--                                        <h6>Phone number</h6><span>{{ loggedUser?.phoneNumber }}</span>-->
    <!--                                    </div>-->
                                    </div>
                                    <div class="col-lg-5 p-1">
                                        <button class="btn btn-danger py-1 px-1 w-100" @click="handleLogOut">
                                            <i class="fa fa-arrow-circle-up"/>
                                            Log out
                                        </button>
    <!--                                    <div class="ttl-info text-start ttl-sm-mb-0">-->
    <!--                                        <h6>Role</h6><span>{{ loggedUser?.role }}</span>-->
    <!--                                    </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row text-start">
                            <div class="col-md-6 mb-4">
                                <h5 class="mb-3 f-w-600">Personal Information</h5>
                                <div class="d-flex flex-column gap-3 border p-3 rounded">
                                    <div v-for="(p, idx) in personalInfo" :key="p.key" :class="['d-flex justify-content-between align-items-center', {'pb-3 border-bottom': idx + 1 < personalInfo.length}]">
                                        <span class="f-w-600">
                                            {{p.title}}
                                        </span>
                                        <div>
                                            {{p.value}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h5 class="mb-3 f-w-600">Account Permissions</h5>
                                <div class="d-flex flex-column gap-3 border p-3 rounded">
                                    <div v-for="(p, idx) in permissionInfo" :key="p.key" :class="['d-flex justify-content-between align-items-center', {'pb-3 border-bottom': idx + 1 < permissionInfo.length}]">
                                        <span class="f-w-600">
                                            {{p.title}}
                                        </span>
                                        <div v-if="typeof(p.value) === 'number'" :class="['badge', (p.value === 1 ? 'badge-success' : 'badge-danger')]">
                                            <span class="f-w-700">{{p.value ===1 ? 'Yes' : 'No'}}</span>
                                        </div>
                                        <div v-else class="badge badge-success">
                                            <span class="f-w-700">{{p.value}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="row text-start" v-if="loggedUser?.hospitals?.items?.length">
                            <div class="col-md-12 mb-4 mt-4 d-flex">
                              <div class="col-md-8">
                                <h5 class="mb-3 f-w-600">Hospital List</h5>
                              </div>
                            </div>
                            <div class="col-md-12 mb-4">

                                <div class="d-flex flex-column gap-3 border p-3 rounded">
                                    <div v-for="(h, idx) in loggedUser.hospitals.items" :key="h.name" :class="['d-flex justify-content-between align-items-center']">

                                      <settings-input :input="{label: 'Hospital Name', type: 'null'}"
                                                      class="col-md-4"
                                                      :model-value="h.name"/>
                                      <settings-input :input="{label: 'Hospital Email', type: 'null'}"
                                                      class="col-md-4"
                                                      :model-value="h.email"/>
                                      <div class="col-md-2">
                                        <label class="col-form-label">Supervisor</label>
                                        <div class="yesNo text-center " :style="h.isSupervisor === 2 ? 'background: #FFAA05' : h.isSupervisor === 1 ? 'background: #54ba4a' : 'background: #fc4438; cursor:pointer'" @click="requestSupervisor(h,idx)">
                                            {{h.isSupervisor === 2 ? 'Requested' : h.isSupervisor === 1 ?'Yes' : 'Request'}}
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PasswordModal/>
<!--      <supervisor-requests/>-->
    </div>
</template>

<style scoped>
.yesNo{
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  appearance: none;
  border-radius: 0.25rem;
  color: white;
}
.bannerProfile{
    height: 110px !important;
    background-color: #ffffff !important;
    background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%) !important;
}
</style>
