<script>

import terms from "@/data/terms.json";

export default {
    name: "terms",
    data(){
        return {
            terms: terms.terms
        }
    }
}
</script>

<!--<template>-->
<!--    <div class="container">-->
<!--        <div class="m-t-50 text-center">-->
<!--            <h2>Terms and Conditions of Sale</h2>-->
<!--            <p>Abbott Terms and Conditions for Sale of the FreeStyle Libre System to Pharmacy Accounts</p>-->
<!--        </div>-->
<!--        <div class="row mt-5">-->
<!--            <div class="col-12">-->
<!--                <div class="mb-3">-->
<!--                    <b>Effective Date of the Terms:</b> 9th December 2022-->
<!--                </div>-->
<!--                <div class="mb-5">-->
<!--                    <b>Last Updated:</b> 9th December 2022-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12">-->
<!--                <div class="row mb-4" v-for="(term, idx) in terms" :key="idx + 'term'">-->
<!--                    <div class="d-flex gap-2 mb-3">-->
<!--                        <span>{{idx + 1}}. </span>-->
<!--                        <b>{{ term.title }}</b>-->
<!--                        <span>{{term.description}}</span>-->
<!--                    </div>-->
<!--                    <div v-for="(point, idxP) in term.items" :key="idxP + 'point'" class="d-flex gap-2 mb-3">-->
<!--                        <span v-if="term.items.length > 1">{{idx + 1}}.{{idxP + 1}} </span>-->
<!--                        <span v-html="point"></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->
<template>
   <div class="container">
      <h1>Terms and Conditions of Use</h1>
      <p>These terms and conditions of use (the “Terms and Conditions”) apply to the <a href="https://pharmacyportal.polarspeed.com/">https://pharmacyportal.polarspeed.com/</a> website. In these Terms and Conditions “we” “our” “us” and/or “Polar Speed” refers to “Polar Speed Distribution Limited”.</p>

      <h2>Content of this Website</h2>
      <p>Your access to this website is subject to the following Terms and Conditions which may be updated by us from time to time without notice to you. By accessing or using this website you agree that you have read understand and agree to be bound to these Terms and Conditions as they may be amended from time to time as well as to the terms of our
         Privacy Notice <a target="_blank" href="https://www.polarspeed.com/privacy/">https://www.polarspeed.com/privacy/</a> and Cookie Notice <a target="_blank" href="https://www.polarspeed.com/cookie/">https://www.polarspeed.com/cookie/</a> which are incorporated into these Terms and Conditions. We reserve the right to change any part of these Terms and Conditions without notice and your use of the website will be deemed as acceptance to those changes. You are responsible for periodically reviewing these Terms and Conditions for applicable changes. If you disagree with these Terms and Conditions (as they may be amended from time to time) or are dissatisfied with this website your sole and exclusive remedy is to discontinue using this website.</p>

      <h2>Disclaimers</h2>
      <p>You acknowledge and agree that:</p>
      <ol>
         <li>Although we strive to provide on this website the latest developments relating to our products and services and other information about our company we do not warrant the accuracy effectiveness and suitability of any information contained in this website. Each person assumes full responsibility and all risks arising from use of this website. The information is presented "AS IS" and may include technical inaccuracies or typographical errors. Polar Speed reserves the right to make additions deletions or modifications to the information at any time without any prior notification.</li>
         <li>Polar Speed makes no representations or warranties of any kind or nature with respect to the information or content posted on this website. Unless otherwise stated on this website Polar Speed hereby disclaims all representations and warranties whether express or implied created by law contract or otherwise including without limitation any warranties of merchantability fitness for a particular purpose title or non-infringement.</li>
         <li>Polar Speed is not responsible and provides no warranty whatsoever for the accuracy effectiveness timeliness and suitability of any information or content obtained from third parties including any hyperlinks to or from third-party sites.</li>
         <li>Polar Speed will endeavour to keep this website available for use however we cannot guarantee this and take no responsibility for any loss or damage that occurs due to an interruption of access to this website. Polar Speed may interrupt access to this website for maintenance reasons or for any other reason at Polar Speed’s sole discretion.</li>
      </ol>

      <h2>Your Use</h2>
      <p>You understand acknowledge and agree to the following:</p>
      <ol>
         <li>By using this website you agree not to disrupt or intercept our electronic information posted on this website or on any of our servers. You also agree not to attempt to circumvent any security features of our website and to abide by all applicable local and international laws rules and regulations.</li>
         <li>Except as expressly stated and agreed upon in advance by Polar Speed no confidential relationship shall be established in the event that any user of this website should make any oral written or electronic communication to Polar Speed (such as feedback questions comments suggestions ideas etc.). If any Polar Speed website requires or requests that such information be provided and that such information contains personal identifying information (e.g. name address phone number) Polar Speed shall obtain use and maintain it in a manner consistent with our Privacy Policy. Otherwise such communication and any information submitted therewith shall be considered non-confidential and Polar Speed shall be free to reproduce publish or otherwise use such information for any purposes whatsoever including without limitation the research development manufacture use or sale of products incorporating such information. The sender of any information to Polar Speed is fully responsible for its content including its truthfulness and accuracy and its non-infringement of any other person's proprietary or privacy rights.</li>
         <li>By registering on the website you represent and warrant the following: (i) you have the legal ability and authority to enter into these Terms and Conditions (ii) the information you provide is accurate and complete (iii) you will comply with any and all laws applicable to your use of the website and (iv) you will not interfere with a third party's use and enjoyment of the website.</li>
      </ol>

      <h2>Prohibited Uses</h2>
      <p>In using the website you agree not to:</p>
      <ol>
         <li>Send or otherwise transmit to or through the website any unlawful infringing harmful harassing defamatory threatening hateful or otherwise objectionable material of any kind any material that can cause harm or delay to the website of any kind and any unsolicited advertising solicitation or promotional materials;</li>
         <li>Misrepresent your identity or affiliation in any way;</li>
         <li>Restrict or inhibit any person from using the website;</li>
         <li>Reverse engineer disassemble or decompile any section or technology on the website or attempt to do any of the foregoing;</li>
         <li>Gain unauthorized access to the website to other users' accounts names personally identifiable information or other information or to other computers or websites connected or linked to the website;</li>
         <li>Launch or use any automated system including without limitation "robots" "spiders" or "offline readers" that access the website in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;</li>
         <li>Post transmit or otherwise make available any virus worm spyware or any other computer code file or program that may or is intended to damage or hijack the operation of any hardware software or telecommunications equipment;</li>
         <li>Allow any other person to use the website with your registration or login information; and</li>
         <li>Assist or permit any persons in engaging in any of the activities described above.</li>
      </ol>

      <h2>Intellectual Property</h2>
      <p>The information documents and related graphics published in this website (the "Information") are the sole property of Polar Speed except for information provided by third-party providers under contract to Polar Speed its subsidiaries or affiliates. Permission to use the Information is granted provided that (1) the above copyright notice appears on all copies; (2) use of the Information is for informational and non-commercial or personal use only; (3) the Information is not modified in any way; and (4) no graphics available from this Internet site are used separate from accompanying text. Polar Speed is not responsible for content provided by third-party providers and you are prohibited from distribution of such material without permission of the owner of the copyright therein. Except as permitted above no license or right express or implied is granted to any person under any patent trademark or other proprietary right of Polar Speed.</p>
      <p>No use of any Polar Speed trademark trade names and trade dress in this website may be made without the prior written authorisation of Polar Speed except to identify the product or services of the company.</p>
      <p>You may provide input comments or suggestions regarding the website ("Feedback"). You acknowledge and agree that Polar Speed may use any Feedback without any obligation to you and you hereby grant Polar Speed a worldwide perpetual irrevocable royalty-free transferable license to reproduce display perform distribute publish modify edit or otherwise use such Feedback as Polar Speed may deem appropriate without restriction for any and all commercial and/or non-commercial purposes in its sole discretion.</p>

      <h2>Termination</h2>
      <p>If you violate these Terms and Conditions your ability to use the website will be terminated. Polar Speed may in its sole discretion terminate your access to the website or any portion thereof for any reason whatsoever without prior notice. These actions are in addition to any other right or remedy Polar Speed may have available at law. Further Polar Speed shall not be liable to you or any third party for any such termination or discontinuance. You may terminate these Terms and Conditions by ceasing to access and use of the website. Upon any termination of these Terms and Conditions you must immediately cease use of the website. To the extent permitted by applicable law the disclaimers limitations on liability termination and your warranties and indemnities shall survive any termination of these Terms and Conditions.</p>

      <h2>Limitation of Liability</h2>
      <p>Polar Speed does not assume any liability for the services materials information and opinions provided on posted to or otherwise available through this website. Reliance on these services materials information and opinions is solely at your own risk. Polar Speed disclaims any liability for injury or damages resulting from the use of this website or the content contained thereon. This Polar Speed website the site content and the products and services provided on or available through this website are provided on an “as is” and “as available” basis with all faults. In no event shall Polar Speed or its subsidiaries affiliates vendors or their respective directors employees or agents (the “Polar Speed Parties”) be liable for any damages of any kind under any legal theory arising out of or in connection with your use of or inability to use this website the site content any services provided on or through this website or any linked site including any special indirect punitive incidental exemplary or consequential damages. Polar Speed and the Polar Speed Parties hereby disclaim to the maximum extent permitted by applicable law liability for any loss or damage which may be suffered by any person whether suffered directly indirectly immediately or consequentially and whether arising in contract tort (including negligence) or otherwise which arises out of or in connection with: (1) use of or inability to use the website; (2) use of information or content on the website; (3) any services provided on or through the website; and (4) interception or unauthorised access to personal information submitted to the website including without limitation the following categories of loss or damage whether or not Polar Speed has been advised of the possibility of such loss or damage: loss or corruption of data; loss of profits; loss of anticipated savings; loss of business opportunity; loss of goodwill; loss of reputation; and special damages</p>

      <h2>General</h2>
      <p>You agree that these Terms and Conditions describe the entire agreement between us with respect to its subject matter. These Terms and Conditions and any dispute or claim arising out of or in connection with them shall be governed and construed in accordance with the law of England with the courts of London having exclusive jurisdiction. If a court of competent jurisdiction finds that any provision of these Terms and Conditions is invalid or unenforceable you agree that the other provisions of these Terms and Conditions will remain in full force and effect.</p>

      <h2>Address for Notices:</h2>
      <p>Polar Speed Distribution Ltd<br>
         8 Chartmoor Road<br>
         Leighton Buzzard<br>
         LU7 4WG<br>
         United Kingdom<br>
         Email: <a href="mailto:legal@marken.com">legal@marken.com</a></p>
   </div>
</template>

<style scoped>
span{
    text-align: justify;
    text-justify: inter-word;
}
</style>
