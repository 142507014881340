<script>
import {useRoute} from "vue-router";
import {mapActions, mapMutations, mapState} from "vuex";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {capitalizeFirstLetter} from "@/utils/helperFunctions";
import PolarLoader from "@/components/PolarLoader.vue";
import MasterdataAddress from "@/components/masterdata/MasterdataAddress.vue";
import {hardCodeApi} from "@/constants/tableSettings";
import {addressInputs} from "@/constants/masterData";

const customerDataInputs = {
    general: [
        {label: 'Customer Account', type: 'null', key: 'id'},
        {label: 'Organization Name', type: 'string', key: 'organizationName'},
        {label: 'Duns Number', type: 'string', key: 'dunsNumber'},
        {label: 'Customer Status', type: 'select', api: 'StatusOptions', key: 'status', valueType: 'number'},
        {label: 'Customer Group', type: 'select', api: 'CustGroup', key: 'custGroupId', valueType: 'number'},
        {label: 'Currency', type: 'select', api: 'Currency', key: 'currencyId', valueType: 'number'},
        {label: 'Company Registration Number', type: 'string', key: 'companyRegistrationNumber'},
        {label: 'Active', type: 'boolean', key: 'active'},
        {label: 'One Time Customer', type: 'boolean', key: 'oneTimeCustomer'},
    ],
    addresses: {

    },
    saleOrderDefaults: [
        {label: 'Invent Site', type: 'select', api: 'InventSite', key: 'inventorySiteId', valueType: 'number'},
        {label: 'Invent Location', type: 'select', api: 'InventLocation', key: 'inventLocationId', valueType: 'number'},
        {label: 'Customer Item Group', type: 'select', api: 'ItemGroup', key: 'customerItemGroupId'},
        {label: 'Sales Order Pool', type: 'select', api: 'SalesPool', key: 'salesPoolId', valueType: 'number'},
        {label: 'Sales Group', type: 'string', key: 'salesGroup'},
        {label: 'Commission Group', type: 'string', key: 'commissionGroup'},
    ],
    paymentsDefaults:[
        {label: 'Payment Terms', type: 'select', api: 'PaymTerm', key: 'paymentTermId', valueType: 'number'},
    ],

    invoiceAndDelivery: [
        {label: 'Branch (Invoice Account)', type: 'string', key: 'invoiceAccount'},
        {label: 'D365 Number Sequence Group', type: 'string', key: 'd365NumberSequenceGroup'},
        {label: 'Company Vat Number', type: 'string', key: 'companyVatNumber'},
        {label: 'Delivery Mode', type: 'select', api: 'DlvMode', key: 'deliveryModeId', valueType: 'number', displayKey: 'code'},
        {label: 'Delivery Term', type: 'select', api: 'DlvTerm', key: 'deliveryTermId', valueType: 'number', displayKey: 'code'},
        {label: 'Is Vat Registered', type: 'boolean', key: 'vatRegistered'},
    ],
}
export default {
    name: "edit_masterdata",
    components: {MasterdataAddress, PolarLoader, SettingsInput},
    setup(){
        const route = useRoute();
        return {
            customerId: route.params?.customerId
        }
    },
    data(){
        return {
            loading: true,
            loadingMessage: 'Loading Customer Data'
        }
    },
    computed:{
        ...mapState({
            customerMasterData: state => state.masterdata.customerMasterData
        }),
        allAPIs(){
            let apis = [];
            const allInputs = {...customerDataInputs, ...addressInputs}
            for(const key in allInputs){
                for(const input of allInputs[key]){
                    if(input.api && !hardCodeApi[input.api]){
                        apis.push(input.api)
                    }
                }
            }
            return apis;
        },
        cDInputs(){
            return customerDataInputs
        },
        sections(){
            const sections = []
            for(const key in customerDataInputs){
                let [first, ...rest] = key.split(/(?=[A-Z])/);
                first = capitalizeFirstLetter(first);
                sections.push({
                    title: [first,...rest].join(" "),
                    key
                })
            }
            return sections
        }
    },
    methods: {
        ...mapActions({
            actCustomerMasterData: "masterdata/actCustomerMasterData",
            actUpdateCustomerMasterData: "masterdata/actUpdateCustomerMasterData",
            actGetAllApiData: 'settings/actGetAllApiData'
        }),
        ...mapMutations({
            mutMasterDataByKey: "masterdata/mutMasterDataByKey",
            mutEmptyApiData: 'settings/mutEmptyApiData'
        }),
        async getCustomerData(){
            this.loading = true;
            await this.actCustomerMasterData(this.customerId);
            this.loading = false;
        },
        async updateCustomer(){
            this.loadingMessage = 'Updating Customer Data';
            this.loading = true;
            await this.actUpdateCustomerMasterData(this.customerMasterData)
            this.loading = false;
        },
        async getAllApiData(){
            await this.actGetAllApiData(this.allAPIs);
        }
    },
    mounted() {
        this.getCustomerData();
    },
    created() {
        this.getAllApiData();
    },
    beforeUnmount() {
        this.mutEmptyApiData();
    }

}
</script>

<template>
    <div>
        <Breadcrumbs :diff-main="`Master Data ${customerId}`" main="Master Data" main-path="/master-data" title="Edit Master Data" />
        <div class="w-100">
            <polar-loader v-if="loading">
                {{loadingMessage}}
            </polar-loader>
        </div>
        <div class="container">
<!--            <div class="col-7">-->
<!--                <div id="data"></div>-->
<!--            </div>-->
            <div class="col-12">
                <div class="theme-form card default-according style-1" v-for="section in sections" :key="section.key">
                    <div
                        class="card-header cursor-pointer"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#masterData${section.key}`"
                        aria-expanded="true"
                        :aria-controls="`masterData${section.key}`"
                    >
                        <h4>{{section.title}}</h4>
                    </div>
                    <div v-if="customerMasterData" class="card-body">
                        <div v-if="section.key === 'addresses'" :id="`masterData${section.key}`"
                             class="collapse show row"
                             :aria-labelledby="`masterData${section.key}`">
                            <masterdata-address/>
                        </div>
                        <div v-else :id="`masterData${section.key}`"
                             class="collapse show row"
                             :aria-labelledby="`masterData${section.key}`">
                            <div v-for="input in cDInputs[section.key]"
                                 class="col-md-6 col-lg-4 d-flex flex-column justify-content-end"
                                 :key="input.key">
                                <settings-input
                                    :settingsData="customerMasterData"
                                    :model-value="customerMasterData[input.key]"
                                    @update:model-value="(val) => mutMasterDataByKey({key: input.key, value: val})"
                                    :input="input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="updateCustomer" class="mb-5 btn btn-primary btn-lg">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
