<style scoped>
.center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<template>
  <div>
    <div v-if="finishedSuccess" class="text-center center">
      <h3 class="text-success">Your account has been created!</h3>
      <p>We have sent a confirmation link in the email below</p>
      <p><b class="text-primary">{{loginDetails.email}}</b></p>
      <router-link to="/auth/login">
        Back to login
      </router-link>
    </div>
    <div v-else class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-xl-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png" alt="looginpage"/>
                </a>
              </div>
              <div class="container w-100">
                <div class="card theme-form">
                  <!--                            <div class="card-header align-items-center d-flex">-->
                  <!--                              <h4 class="card-title mb-0">{{ title }}</h4>-->
                  <!--                              <div v-if="data.id < 0" class="ml-auto cursor-pointer" @click="removeNewAddress(data.id)">-->
                  <!--                                <i class="fa fa-trash fs-5 font-danger"></i>-->
                  <!--                              </div>-->
                  <!--                            </div>-->
                  <div class="card-body">
                    <div class="row">
                      <div v-for="input in registerForm"
                           class="col-md-6 mb-3"
                           :key="input.key">
                        <settings-input :input="input"
                                        :model-value="loginDetails[input.key]"
                                        @update:model-value="(val) => handleDataChange({val, key: input.key})"

                        />
                      </div>
                    </div>
                    <div v-for="(hosp, idx) in loginDetails.hospitals" class="row position-relative">
                      <settings-input :input="hospitalInput"
                                      :parent-select="filterHospital"
                                      :model-value="hosp.hospitalId"
                                      @update:model-value="(val) => handleHospitalChange({val, idx})"
                                      class="col-md-6 mb-3"/>
                      <div class="col-md-6 mb-3 d-flex">
                        <settings-input :input="{label: 'Hospital Email', type: 'null'}"
                                        class="col-md-9"
                                        :model-value="hosp.email || 'Email'"/>
                        <button :disabled="loginDetails.hospitals.length<=1" @click="removeHospital(idx)" class="btn btn-danger max-w-max mt-auto ml-auto mb-lg-1 col-md-2"><i class="fa fa-trash"></i></button>
                      </div>

                    </div>

                    <button :disabled="hospitals.length === loginDetails.hospitals.length" class="btn btn-lg btn-primary btn-block w-100 m-b-20" @click="addHospital">Add Another Hospital</button>
                    <div class="form-group">
                      <!--            <label class="col-form-label">Please confirm*</label>-->
                      <div class="g-recaptcha" data-sitekey="6LeQ04QpAAAAAJBnlfoa7mrn5IHzgmbgv6gaccbz"></div>
                      <div class="invalid-feedback d-block" v-if="captchaError">{{captchaError}}</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                      <div class="form-group mb-0 mt-3 mb-3">
                      <div class="checkbox p-0">
                        <input v-model="agreeCheck" id="checkbox1" type="checkbox" />
                        <label class="text-muted" for="checkbox1">
                          Agree with<router-link target="_blank" to="/terms-and-conditions" class="ms-2">Terms and Conditions</router-link>
                        </label>
                        <div class="invalid-feedback mt-0 d-block" v-if="agreeError && !agreeCheck">{{ 'You have to agree with our terms and conditions' }}</div>
                      </div>
                    </div>
                      <router-link to="/auth/login">
                        Back to login
                      </router-link>
                    </div>
                      <button class="btn btn-lg btn-primary btn-block w-100 mt-4" @click="register">Register</button>
                  </div>
                </div>
              </div>


              <!--                        <new_register/>-->
            </div>
          </div>
        </div>
      </div>
      <PolarLoader v-if="loading"/>
    </div>
  </div>
</template>

<script>
import PolarLoader from "@/components/PolarLoader.vue";
import New_register from "@/pages/authentication/new_register.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {required, email} from "@vuelidate/validators";
import api from "@/utils/api";
import {useVuelidate} from "@vuelidate/core";
import {useToast} from "vue-toastification";

export default {
  components: {SettingsInput, New_register, PolarLoader},
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      emailExists: [],
      loading: false,
      finishedSuccess: false,
      captchaError: '',
      agreeCheck: false,
      agreeError: false,
      hospitals: [],
      hospitalInput: {
        label: 'Hospital',
        type: 'select',
        api: 'sss',
        key: 'hospitalId',
        displayKey: 'name',
        valueType: 'number'
      },
      registerForm: [
        {
          label: 'First Name',
          type: 'string',
          key: 'firstName',
        },
        {
          label: 'Last Name',
          type: 'string',
          key: 'lastName',
        },
        {
          label: 'Healthcare Professional Type',
          type: 'select',
          key: 'healthcareType',
          api: '/Healthcare',
          displayKey:'healthcareType',
          valueType: 'number',
          trackKey: 'healthcareType'
        },
        {
          label: 'Email',
          type: 'string',
          key: 'email',
          validation: {
            email
          }
        },
      ],
      loginDetails: {
        healthcareType: "",
        firstName: "",
        lastName: "",
        email: "",
        hospitals: [
          {
            hospitalId: null
          }
        ]
      },
    }
  },
  computed:{
    finalForm(){
      const hospitals = this.loginDetails.hospitals.map(e => {
        return {hospitalId:e.hospitalId}
      })
      const fForm = {
        ...this.loginDetails,
        hospitals
      }
      console.log(fForm,'fFormfFormfForm')
      return fForm
    },
    filterHospital(){
       return this.hospitals.map(x =>{
        for (const h of this.loginDetails.hospitals){
          if(h.hospitalId === x.id ){
            x.disabled = true
            return x
          }
        }
        x.disabled = false
        return x
      })
    }
  },
  methods: {
    async verifyCaptcha(){
      try {
        const recaptchaResponse = grecaptcha.getResponse();
        console.log('recaptchaResponse::', recaptchaResponse);
        if(recaptchaResponse) {
          const {data} = await api.post('/captcha', null, {
            params: {
              token: recaptchaResponse
            }
          })
          if(data) return data
        } else {
          this.captchaError = 'Please verify you are human'
        }
      } catch (e) {
        const {data} = e.response
        useToast().error(`${data.errors[0]?.description}`);
        if(grecaptcha){
          grecaptcha.reset();
        }
      }
      return false;
    },
    handleDataChange({key, val}) {
      this.loginDetails[key] = val
    },
    addHospital() {
      this.loginDetails.hospitals.push({hospitalId: null})
    },
    removeHospital(idx) {
      this.loginDetails.hospitals.splice(idx, 1)
    },
    handleHospitalChange({val, idx}) {
      this.loginDetails.hospitals[idx].hospitalId = val;
      this.loginDetails.hospitals[idx].email = this.hospitals.find(h => h.id === val).email;
    },
    async register() {
      const result = await this.v$.$validate()
      this.agreeError = !this.agreeCheck;

      if(result && this.agreeCheck){
        this.agreeError = false;
        const verified = await this.verifyCaptcha();
        if(verified){
          this.loading = true;
          document.body.style.overflow = 'hidden';
          this.finalForm
          const response = await this.$store.dispatch('auth/actRegister', this.finalForm);
          this.loading = false;
          document.body.style.overflow = '';
          document.body.style['max-height'] = '';
          if(response === 'success') {
            this.finishedSuccess = true;
            return true;
          } else {
            if(response?.response?.data){
              const {errors} = response.response.data;
              if(errors){
                if(errors[0]?.code === 'Users.EmailNotUnique'){
                  this.emailExists.push(this.finalForm.email);
                }
              }
            }
          }
        } else {
          this.captchaError = 'Please verify you are human';
        }
      }
      const errorEl = document.getElementsByClassName('invalid-feedback');
      errorEl[0]?.parentElement.scrollIntoView({ behavior: "smooth"});
    },
    getHospitals() {
        api.get('/Hospital/all').then(({data}) => {
            this.hospitals = data.items.filter((item) => item.status === true);
            console.log(data.items, 'datadatadatadata', this.hospitals)

        })

    }
  },
  mounted() {
    this.getHospitals();
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  unmounted() {
    // this.$store.commit('auth/mutSetState', {key: 'authResponse', value: {title: '', description: ''}})
  }
}
</script>
