<script>
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";

export default {
    name: "masterData",
    components: {PolarLoader, Pagination, PolarSearch},
    data(){
        return{
            loading: false,
            params: {
                searchKey: '',
                columnName: '',
                isDescending: true,
                pageNumber: 1,
                pageSize: 8
            },
            masterData: [],
            pagination: {
                "pageNumber": 1,
                "totalPages": 1,
                "totalCount": 1,
                "hasPreviousPage": false,
                "hasNextPage": false
            },
            displayColumns: [
                {key:'id', title: 'ID'},
                {key:'organizationName', title: 'Company Partnership/Sole Trader Name'},
                {key: 'companyRegistrationNumber', title: 'Registration Number'},
                {key: 'status', title: 'Status' }
            ],
            statusOptions: {
                1: 'In Check',
                2: 'Checked Successfully',
                3: 'Check Not Passed',
                4: 'Canceled',
                5: 'Closed'
            }
        }
    },
    methods:{
        async getMasterData(){
            try {
                this.loading = true;
                const {data} = await api.get('/CustomerMasterData', {
                    params: this.params
                })
                const {items, ...rest} = data;
                console.log(rest, 'rest')
                this.pagination = rest;
                this.masterData = data.items;
            } catch (e) {
                useToast.error(e.response?.data?.errors[0]?.description || 'Something went wrong');
            } finally {
                this.loading = false;
            }
        },
        handleSort(key){
            if(key === this.params.columnName){
                this.params.isDescending = !this.params.isDescending;
            }
            this.params.columnName = key;
            this.params.pageNumber = 1;
            this.getMasterData();
        },
        handleSearch(){
            this.params.pageNumber = 1;
            this.getMasterData();
        },
        handlePaginationEvent(page){
            this.params.pageNumber = page;
            this.getMasterData();
        }
    },
    mounted() {
        this.getMasterData();
    }
}
</script>

<template>
    <div>

    <Breadcrumbs main="Master Data" title="Master Data List" />
    <div class="container">
        <div class="row">
            <div class="card position-relative overflow-hidden">
                <div class="card-header d-flex align-items-center gap-2">
                    <div class="d-flex w-100 align-items-center">
                        <PolarSearch v-model="params.searchKey" style="max-width: 400px;" @search="handleSearch"/>
                    </div>
                </div>
                <div class="card-body" style="min-height: 565px;">
                    <div class="table-responsive add-project h-100">
                        <table class="table table-hover card-table table-vcenter text-nowrap">
                            <thead>
                            <tr>
                                <th v-for="(col, idx) in displayColumns" :key="col.key" :class="['fw-bold', {'text-center': idx > 0}]">
                                    <div @click="handleSort(col.key)" :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 0}]">
                                        {{ col.title }}
                                        <i class="fa fa-sort"/>
                                    </div>
                                </th>
                                <th class="fw-bold text-center">
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in masterData" :key="data.id">
                                <td v-for="(col, idx) in displayColumns" :class="[{'text-center': idx > 0}]" :key="`${data.id}|${col.key}`">
                                    <div v-if="col.key === 'status'">
                                        {{ statusOptions[data.status] }}
                                    </div>
                                    <div v-else>
                                        {{data[col.key]}}
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex gap-2 mx-auto w-100 justify-content-center">
                                        <router-link :to="`master-data/${data.id}`" class="btn btn-primary btn-sm">
                                            <i class="fa fa-pencil"></i>
                                            Edit
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer pagination-padding">
                    <pagination :pagination-info="pagination" @pageChanged="handlePaginationEvent"/>
                </div>
                <polar-loader v-if="loading">
                    Loading Table Settings
                </polar-loader>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
