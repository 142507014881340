<script>
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";

export default {
    components: {PolarSearch, ConfirmModal, Pagination, PolarLoader},
    data() {
        return {
            userUI: [
                {title: 'ID', key: 'userId'},
                {title: 'Name', key: 'firstName'},
                {title: 'Lastname', key: 'lastName'},
                {title: 'Email', key: 'userEmail'},
                {title: 'Hospital', key: 'name'},
            ],
            search: '',
            loadingUsers: false,
            userToManage: null,
            currentSort: '',
            isDescending: true
        }
    },
    computed: {
        ...mapState({
            users: state => state.user.users,
            paginationInfo: state => state.user.usersPagination,
        }),
        ...mapGetters({
            permission: "auth/loggedPermission"
        }),
        usersDisplay() {
            if (!this.users) return [];
            return this.users.map((u) => {
                return {
                    ...u,
                }
            })
        },

    },
    methods: {
        ...mapActions({
            getUsers: 'user/actGetAllUserRequests',
        }),
        async handleGetUsers(pageNumber) {
            this.loadingUsers = true;
            await this.getUsers({
                searchKey: this.search,
                pageNumber,
                pageSize: 8,
                columnName: this.currentSort,
                isDescending: this.isDescending
            });
            this.loadingUsers = false;
        },
        handlePaginationEvent(page) {
            this.handleGetUsers(page);
        },
        async handleUserSupervise(val) {
            console.log(this.userToManage, 'userToManageuserToManageuserToManage')
            try {
                const {data} = await api.post('/user/supervise', null, {
                    params: {
                        userId: this.userToManage.userId,
                        hospitalId: this.userToManage.hospitalId,
                        isActive: val
                    }
                })
                console.log('erdhi', data)
                if (data?.isSuccess) {
                    let index = this.users.findIndex(x => x.userId === this.userToManage.userId)
                    console.log(index, 'index', this.users)
                    if (index > -1)
                        this.users.splice(index, 1)
                }

            } catch (e) {

            }
        },
        handleSort(key) {
            console.log('key:::', key);
            const finalKey = key === 'fullName' ? 'firstName' : key
            if (finalKey === this.currentSort) {
                this.isDescending = !this.isDescending;
            } else {
                this.currentSort = finalKey;
            }
            this.handleGetUsers(1);
        }
    },
    mounted() {
        if (this.permission < 3)
            this.$router.push('/')
        else
            this.handleGetUsers(1);
    },
}

</script>

<template>
    <div>
        <Breadcrumbs main="Supervisor Requests" title="Requests"/>
        <div class="container">
            <div class="row">
                <div class="card position-relative overflow-hidden">
                    <div class="card-header d-flex align-items-center gap-2">
                        <PolarSearch v-model="search" @search="handleGetUsers(1)" style="max-width: 400px;"/>
                    </div>
                    <div class="card-body" style="min-height: 565px;">
                        <div class="table-responsive add-project stickyLastCol">
                            <table class="table table-hover card-table table-vcenter text-nowrap">
                                <thead>
                                <tr>
                                    <th v-for="(ui, idx) in userUI" :key="ui.key"
                                        :class="['fw-bold', {'text-center': idx > 2}]">
                                        <div @click="handleSort(ui.key)"
                                             :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 2}]">
                                            {{ ui.title }}
                                            <i class="fa fa-sort"/>
                                        </div>
                                    </th>
                                    <th class="text-center fw-bold">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="user in usersDisplay" :key="user.id">
                                    <td v-for="ui in userUI" :key="`${user.id}|${ui.key}`">
                                        <div>
                                            {{ user[ui.key] }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2 h-100 justify-content-center">
                                            <button class="btn btn-sm btn-success"
                                                    @click="() => userToManage = user"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#acceptUserModal">
                                                Accept
                                            </button>
                                            <button class="btn btn-danger btn-sm"
                                                    @click="() => userToManage = user"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#rejectUserModal">
                                                Reject
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer pagination-padding">
                        <pagination :pagination-info="paginationInfo" @pageChanged="handlePaginationEvent"/>
                    </div>
                    <polar-loader v-if="loadingUsers">
                        Loading Users
                    </polar-loader>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rejectUserModal" role="dialog"
             aria-labelledby="rejectUserModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleUserSupervise(false)">
                <template #message>
                    Are you sure you want to <b>Reject</b> this user?
                </template>
            </ConfirmModal>
        </div>
        <div class="modal fade" id="acceptUserModal" role="dialog"
             aria-labelledby="acceptUserModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleUserSupervise(true)">
                <template #message>
                    Are you sure you want to <b>Accept</b> this user?
                </template>
            </ConfirmModal>
        </div>
    </div>
</template>
