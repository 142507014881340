<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import RegisterPatient from "@/components/patient/RegisterPatient.vue";

export default {
  components: {RegisterPatient, ConfirmModal },
  props:{
    userToManage:null
  },
  data(){
    return {

    }
  },
  computed:{

  },
  methods:{
    closeNew(){
      this.$emit('closeNew')
    }
  },
  mounted(){
  },
}

</script>

<template>
  <div class="container">
    <div class=" card position-relative overflow-hidden">
        <div class="card-body">
            <div class="d-flex align-items-center gap-2">
                <h6 style="color:#818181">Private and Confidential (When Complete)</h6>
                <button @click="closeNew" class="btn btn-danger btn-sm ml-auto">Go Back</button>
            </div>
            <h5 class="text-center f-w-600 m-t-30">Homecare Medicine Service: Patient Registration Form</h5>
        </div>
        <register-patient @closeNew="closeNew" :userToManage="userToManage" />

    </div>
    <div class="modal fade" id="closeNewPatient" role="dialog"
         aria-labelledby="closeNewPatient" aria-hidden="true">
      <ConfirmModal @confirmed="closeNew">
        <template #message>
          Changes will <b>Not</b> be saved.
          <br>
          Are you sure you want to go back?
        </template>
      </ConfirmModal>
    </div>
  </div>
</template>

<style scoped>

</style>