<script>
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import api from "@/utils/api";
import AddPatient from "@/components/patient/AddPatient.vue";
import ViewPatient from "@/components/patient/ViewPatient.vue";
import {compareAsc, format} from "date-fns";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {helpers} from "@vuelidate/validators";
import ProggressButton from "@/components/uiKits/ProggressButton.vue";
import {downloadReport} from "@/utils/helperFunctions";

export default {
    components: {
        ProggressButton,
        SettingsInput, ViewPatient, AddPatient, PolarSearch, ConfirmModal, Pagination, PolarLoader},
    data() {
        return {
            loadDownload: false,
            filters:[
                {key:'status',type: "select", api:'status',displayKey: 'label',trackKey:'value', valueType: 'number'},
                {key:'flag', type: "select", api:'flag',displayKey: 'label',trackKey:'value', label:'Patients'},
            ],
            backFilters:{
                status: null,
                flag: null,
                startDate: null,
                endDate: null,
            },
            userUI: [
                {title: 'Web Portal Patient ID', key: 'webPortalPatientId'},
                {title: 'Title', key: 'title'},
                {title: 'Forename', key: 'firstName'},
                {title: 'Last Name', key: 'lastName'},
                {title: 'Gender', key: 'gender'},
                {title: 'Preferred phone', key: 'phoneNumber', type: 'phoneNumber'},
                {title: 'Email', key: 'email'},
                {title: 'Registration Status', key: 'registrationStatus'},
                {title: 'Healthcare Professional', key: 'healthcareProfessionalName'},
                {title: 'Hospital Number', key: 'hospital', type: 'obj',displayKey:'hospitalNumber'},
                {title: 'Therapy Area / Service', key: 'therapy', type: 'obj', displayKey: 'therapyService'},
                {title: 'Initial delivery frequency', key: 'deliveryFrequency'},
                {title: 'PICP', key: 'picp', type: 'bool'},
                {title: 'Registration Date', key: 'homecareProviderSentDatetimeUtc', type: 'date'},
            ],
            search: '',
            loadingUsers: false,
            newPatient: false,
            patientView: false,
            currentSort: '',
            userToManage: null,
            isDescending: true
        }
    },
    computed: {
        helpers() {
            return helpers
        },
        ...mapState({
            loggedUser: state => state.auth.loggedUser,
            users: state => state.user.userPatients,
            paginationInfo: state => state.user.usersPagination,
        }),
        ...mapGetters({
            permission: "auth/loggedPermission"
        }),
        apiData(){
            return {
                status:[
                    {value: 'null', label: 'All'},
                    {value: 0, label: 'Rejected'},
                    {value: 1, label: 'Approved'},
                    {value: 2, label: 'Submitted'},
                ],
                flag:[
                    {value: 'null', label: 'All Patients'},
                    {value: 'User', label: 'My Patients List'},
                    {value: 'Supervisor', label: 'Hospital Patients'},
                ]
            }
        },
        validateDates(){
            const {startDate, endDate} = this.backFilters
            if(startDate && endDate){
                return compareAsc(endDate, startDate);
            }
            return 1
        },
        usersDisplay() {
            if (!this.users) return [];
            return this.users.map((u) => {
                return {
                    ...u,
                }
            })
        },

    },
    methods: {
        ...mapActions({
            getUsers: 'user/actGetAllPatients',
        }),
       async handleDownload(){
            console.log(this.backFilters,'backFilters')
            try {
                this.loadDownload = true
                const {data} = await api.post('/Patient/downloadPatients', null, {
                    params: { ...this.backFilters, 'UserId': this.loggedUser.id}
                });
                console.log(data,'datadatadata')
                downloadReport({
                    data: data.fileContents,
                    type: data.contentType,
                    fileName: data.fileDownloadName
                })
            } catch (e) {
                console.log(e, 'eeeeee')
            } finally {
                this.loadDownload = false
            }
        },
        endDateValidation(endDate){
            if(endDate){
                return this.validateDates >= 0;
            }
            return true;
        },
        handleDataChange({ key, val, idx}) {
            console.log({key, val, idx}, 'handleDataChange')
            this.backFilters[key] = (val === 'null' ? null : val)
            // this.handleGetUsers(1)
        },
        showVal(user, i){
            if (i.type === 'phoneNumber'){
                let nr = user[i.key]
                console.log(user,"nrnrnrnrnr")
                return nr.phonePrefix + nr.phoneNumber || 'No Data'
            }
            if (i.type === 'obj'){
                return user[i.key][i.displayKey]
            }
            if (i.type === 'date'){
                console.log(user[i.key], i.key,'dataaaaas')
                if (user[i.key])
                return format(user[i.key],'dd-MMM-yyyy')
            }
            if (i.type === 'bool'){
                return user[i.key] === true ? 'Yes' : 'No'
            }
            return user[i.key] || 'No Data'
        },
        async handlePatientAccept(val) {
            try {
                const {data} = await api.put('/Patient/activate', null, {
                    params: {
                        id: this.userToManage.id,
                        isActive: val
                    }
                })
                if (data?.isSuccess) {
                    let index = this.users.findIndex(x => x.id === this.userToManage.id)
                    // console.log(index, 'indexindexindexindex', this.users,this.userToManage)
                    let stat = val? 'Approved' : 'Rejected'
                    if (index > -1)
                        this.users[index].formStatus = stat
                }

            } catch (e) {

            }
        },
        viewPatient(user) {
            this.userToManage = user
            this.patientView = true
        },
        async handleGetUsers(pageNumber) {
            console.log(this.backFilters.endDate,'this.validateDates >= 0this.validateDates >= 0')
            if (this.validateDates < 0)
                return
            this.loadingUsers = true;
            await this.getUsers({
                userId: this.loggedUser.id,
                searchKey: this.search,
                pageNumber,
                pageSize: 8,
                columnName: this.currentSort,
                isDescending: this.isDescending,
                ...this.backFilters
            });
            this.loadingUsers = false;
        },
        handlePaginationEvent(page) {
            this.handleGetUsers(page);
        },
        handleSort(key) {
            console.log('key:::', key);
            const finalKey = key === 'fullName' ? 'firstName' : key
            if (finalKey === this.currentSort) {
                this.isDescending = !this.isDescending;
            } else {
                this.currentSort = finalKey;
            }
            this.handleGetUsers(1);
        },
        closeNew() {
            this.handleGetUsers(1);
            this.userToManage = null
            this.newPatient = false
            this.patientView = false

        }
    },
    mounted() {
        this.handleGetUsers(1);
    },
    watch: {
        backFilters:{
            handler(val){
                this.handleGetUsers(1);
            },
            deep: true
        }
    }
}

</script>

<template>
    <div>
        <Breadcrumbs main="Patient List" title="Patients"/>
        <add-patient v-if="newPatient"
                     :userToManage="userToManage"
                     @closeNew="closeNew"/>
        <view-patient v-if="patientView"
                     :userToManage="userToManage"
                     @closeNew="closeNew"/>
        <div class="container" v-if="!newPatient && !patientView">
            <div class="row card position-relative overflow-hidden">
                <div class="card-header d-flex align-items-center gap-2">
                    <PolarSearch v-model="search" @search="handleGetUsers(1)" style="max-width: 400px;"/>
                    <div class="ml-auto">
                    <proggress-button
                        :loading="loadDownload"
                        @click="handleDownload()" :btnClass="`btn btn-primary`">
                        <div class="d-flex gap-2 align-items-center">
                            <i class="fa fa-download"/>
                            Download
                        </div>
                    </proggress-button>
                    </div>
                    <button class="btn btn-sm btn-primary " @click="() => this.newPatient = true">
                        <i class="fa fa-plus"/>
                        New Patient
                    </button>
                </div>
                <div class="card-header d-flex align-items-start gap-2">
                    <div v-for="(i, idx) in filters">
                    <settings-input :input="i"
                                    :parent-select="apiData[i.api]"
                                    :model-value="i.key"
                                    :disabled="i.disabled"
                                    @update:model-value="(val) => handleDataChange({ val, key: i.key, idx})"/>

                    </div>
                    <div class="d-flex flex-wrap gap-3">
                        <settings-input :input="{
                                            type: 'date',
                                            label: 'Start Date',
                                            placeholder: 'Start Date',
                                            notRequired: true
                                        }"
                                        v-model="backFilters.startDate"
                        />
                        <settings-input :input="{
                                            type: 'date',
                                            label: 'End Date',
                                            validation: {endDateBigger: helpers.withMessage('End Date must be greater than start date', endDateValidation)},
                                            placeholder: 'End Date',
                                            notRequired: true
                                        }"
                                        v-model="backFilters.endDate"
                        />
                    </div>
                </div>
                <div class="card-body" style="min-height: 565px;">
                    <div class="table-responsive add-project stickyLastCol">
                        <table class="table table-hover card-table table-vcenter text-nowrap">
                            <thead>
                            <tr>
                                <th v-for="(ui, idx) in userUI" :key="ui.key" :class="['fw-bold']">
                                    <div @click="handleSort(ui.key)" :class="['p-1 cursor-pointer max-w-min']">
                                        {{ ui.title }}
                                        <i class="fa fa-sort"/>
                                    </div>
                                </th>
                                <th class="text-center fw-bold">
                                    <div class="d-flex">

                                    <div class="mx-auto">Status</div>
                                    <div style="margin-right: 1rem">Actions</div>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="user in usersDisplay" :key="user.id">
                                <td v-for="ui in userUI" :key="`${user.id}|${ui.key}`">
                                    <div>
                                        {{ showVal(user, ui) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex gap-2 align-items-center">
                                    <div v-if="user.formStatus === 'Submited'">
                                        <div v-if="permission>2" class="d-flex gap-2 h-100 justify-content-center">
                                        <button class="btn btn-sm btn-success"
                                                @click="() => userToManage = user"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#acceptUserModal">
                                            Accept
                                        </button>
                                        <button class="btn btn-danger btn-sm"
                                                @click="() => userToManage = user"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#rejectUserModal">
                                            Reject
                                        </button>
                                        </div>
                                        <div v-else
                                             class="btn btn-sm mx-auto h-100 btn-warning">
                                            {{ user.formStatus }}
                                        </div>
                                    </div>

                                    <div v-else
                                            :class="'btn btn-sm mx-auto h-100 ' + (user.formStatus === 'Rejected' ? 'btn-danger': 'btn-success')">
                                            {{ user.formStatus }}
                                    </div>

                                    <div class="d-flex gap-2 h-100 justify-content-center">
                                        <button class="btn btn-sm btn-primary" @click="viewPatient(user)">View</button>
                                    </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer pagination-padding">
                    <pagination :pagination-info="paginationInfo" @pageChanged="handlePaginationEvent"/>
                </div>
                <polar-loader v-if="loadingUsers">
                    Loading Users
                </polar-loader>
            </div>
        </div>
        <div class="modal fade" id="rejectUserModal" role="dialog"
             aria-labelledby="rejectUserModal" aria-hidden="true">
            <ConfirmModal @confirmed="handlePatientAccept(false)">
                <template #message>
                    Are you sure you want to <b>Reject</b> this user?
                </template>
            </ConfirmModal>
        </div>
        <div class="modal fade" id="acceptUserModal" role="dialog"
             aria-labelledby="acceptUserModal" aria-hidden="true">
            <ConfirmModal @confirmed="handlePatientAccept(true)">
                <template #message>
                    Are you sure you want to <b>Accept</b> this user?
                </template>
            </ConfirmModal>
        </div>
    </div>
</template>
