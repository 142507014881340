<template>
   <div class="card position-relative overflow-hidden">
      <polar-loader v-if="loading">
         {{ loadingTitle }}
      </polar-loader>
      <div class="card-header d-flex">
         <h3 class="card-title mb-0">{{ creatingUser ? 'Create User' : 'Edit User' }}</h3>
         <div v-if="!creatingUser" class="ml-auto fw-bold font-primary fs-6">
            {{ userTitle }}
         </div>
      </div>
      <div class="card-body">
         <form class="theme-form" novalidate="">
             <div class="d-flex justify-content-between mb-3">
                 <h4>Account Information</h4>
                 <button type="button" v-if="!userInformation?.isEmailConfirm && !creatingUser" @click="handleEmailResend" class="btn btn-primary">Resend Confirmation Email</button>
             </div>
            <div class="row">
               <div class="col-12 col-sm-6 form-group mb-3">
                  <label for="firstName" class="col-form-label">First Name*</label>
                  <input id="firstName"
                         :class="[
                                   'form-control',
                                   `${formSubmitted ? firstError ? 'is-invalid' : 'is-valid' : ''}`
                               ]"
                         type="text"
                         v-model="userInformation.firstName">
                  <div class="invalid-feedback">{{ errors.firstName }}</div>
               </div>
               <div class="col-12 col-sm-6 mb-3">
                  <label for="lastName" class="col-form-label">Last Name*</label>
                  <input id="lastName"
                         :class="[
                                   'form-control',
                                   `${formSubmitted ? lastError ? 'is-invalid' : 'is-valid' : ''}`
                               ]"
                         type="text"
                         v-model="userInformation.lastName">
                  <div class="invalid-feedback">{{ errors.lastName }}</div>
               </div>
               <div class="col-12 col-sm-6 mb-3">
                  <label for="email" class="col-form-label">Email Address*</label>
                  <input id="email"
                         :class="[
                                   'form-control',
                                   `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`
                               ]"
                         type="email" v-model="userInformation.email">
                  <div class="invalid-feedback">{{ errors.email }}</div>
               </div>
               <div class="col-12 col-sm-6 form-group mb-3">
                  <!--                    <UserTitle v-model="userInformation.healthCareType" :show-validation="formSubmitted" :error="errors.title"/>-->
                  <settings-input :input="roleInput"
                                  :parent-select="titleOptions"
                                  :model-value="userInformation.healthCareType"
                                  @update:model-value="(val) => handleRoleChange({val})"
                                  class="col-md-6 mb-3"/>
               </div>
            </div>
            <hr class="mt-4 mb-4">
            <h4 class="pb-3 mb-0">Permissions</h4>
            <div class="row">
               <div class="col-12 col-md-3 col-lg-6 mb-3">
                  <label for="isActive">
                     User Status
                  </label>
                  <label class="d-block cursor-pointer" for="isActive">
                     <input v-model="userInformation.isActive" class="checkbox_animated" id="isActive"
                            type="checkbox">
                     Is Active
                  </label>
               </div>
               <div class="col-12 col-md-3 col-lg-6 mb-3 d-flex" v-if="!creatingUser">
                  <button type="button" v-if="userInformation.role !== 'Polar Speed Admin'"
                          @click="makeUserAdmin(true)" class="btn btn-success ml-auto mt-4">Make Admin
                  </button>
                  <button type="button" v-else @click="makeUserAdmin(false)" class="btn btn-danger ml-auto mt-4">
                     Remove Admin
                  </button>
               </div>
            </div>
         </form>
      </div>
      <div class="card-footer d-flex gap-2" v-if="!creatingUser">
         <button type="button" class="btn btn-primary ml-auto" @click="updateCreateUser">
            {{ creatingUser ? 'Create User' : 'Update User' }}
         </button>
         <button type="button" class="btn btn-light" @click="handleCancelEdit">Cancel</button>
      </div>
      <div class="card-body">
         <hr class="mt-4 mb-4">
         <h4 class="pb-3 mb-0"> User Hospitals</h4>
         <div v-for="(hosp, idx) in userInformation.hospitals" class="row position-relative">
            <settings-input :input="hospitalInput"
                            :parent-select="filterHospital"
                            :model-value="hosp.id"
                            @update:model-value="(val) => handleHospitalChange({val, idx, hosp})"
                            class="col-md-4 mb-3"/>
            <settings-input :input="{label: 'Hospital Email', type: 'null'}"
                            class="col-md-5"
                            :model-value="hosp.email || 'Email'"/>
            <div class="d-flex flex-wrap col-md-3 ml-auto">
               <button v-if="!creatingUser" @click="makeSupervisor(idx, hosp)"
                       :class="'btn max-w-max ml-auto mt-auto mb-3 ' + (hosp.isSupervisor === 2 ? 'btn-warning': (hosp.isSupervisor ? 'btn-success': 'btn-danger'))"
                       style="height: 38px">
                  {{
                     hosp.isSupervisor === 2 ? 'Requested' : (hosp.isSupervisor ? 'Supervisor' : 'Not Supervisor')
                  }}
               </button>
               <button type="button" :disabled="!userInformation.hospitals[1]?.status && idx === 0"
                       @click="removeHospital(hosp.id, idx)"
                       :class="'btn btn-danger max-w-max  mt-auto mb-3 ' + (creatingUser ? 'ml-auto' :'m-l-10')"
                       style="height: 38px;"><i class="fa fa-trash"></i></button>

            </div>

         </div>
         <button type="button" :disabled="hospitals.length === userInformation.hospitals?.length"
                 class="btn btn-lg btn-primary btn-block w-100 m-b-20" @click="addHospital">Add Another Hospital
         </button>
      </div>
      <div class="card-footer d-flex gap-2" v-if="creatingUser">
         <button class="btn btn-primary ml-auto" :disabled="!userInformation.hospitals"
                 @click="updateCreateUser">
            {{ creatingUser ? 'Create User' : 'Update User' }}
         </button>
         <button type="button" class="btn btn-light" @click="handleCancelEdit">Cancel</button>
      </div>
   </div>
</template>
<script>
import PolarLoader from "@/components/PolarLoader.vue";
import api from "@/utils/api";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import createUserValidation from "@/mixins/validations/createUserValidation";
import UserTitle from "@/components/user/UserTitle.vue";
import user from "../../store/modules/user";
import SettingsInput from "@/components/settings/SettingsInput.vue";

export default {
   components: {SettingsInput, UserTitle, PolarLoader},
   mixins: [createUserValidation],
   data() {
      return {
         titleOptions: [],
         hospitalInput: {
            label: 'Hospital',
            type: 'select',
            api: 'sss',
            key: 'id',
            displayKey: 'name',
            valueType: 'number'
         },
         roleInput: {
            label: 'Role',
            type: 'select',
            api: 'Healthcare',
            trackKey: 'healthcareType',
            key: 'id',
            displayKey: 'healthcareType',
            valueType: 'number'
         },
         hospitals: [],
         // permissions: [
         //     {title: 'Can view client address' , key: 'canViewClientAddress', importance: 1},
         //     {title: 'Can edit client address' , key: 'canEditClientAddress', importance: 2},
         //     {title: 'Can create new client address' , key: 'canCreateNewClientAddress', importance: 3},
         // ],
         userInformation: {
            id: null,
            firstName: "Loading",
            lastName: "Loading...",
            email: "Loading...",
            hospitals: [
                  {
                     hospitalId: null,
                  }],
            "role": "Customer Service"
         },
         loading: false,
         loadingTitle: ''
      }
   },
   computed: {
      filterHospital() {
         console.log(this.userInformation.hospitals,'sssssssssssssslll')
         return this.hospitals.map(x => {
            for (const h of this.userInformation.hospitals) {
               if (h.id === x.id || h.hospitalId === x.id) {
                  x.disabled = true
                  return x
               }
            }
            x.disabled = false
            return x
         })
      },
      user() {
         return user
      },
      ...mapState({
         selectedUserEmail: state => state.user.selectedUserEmail,
         selectedUserID: state => state.user.selectedUserID,
      }),
      ...mapGetters({
         roles: "auth/userRoles"
      }),
      userTitle() {
         if (this.userInformation?.id) {
            console.log(this.userInformation, 'userInfuserInformationormation')
            const {role, firstName, lastName} = this.userInformation
            return `${role}. ${firstName} ${lastName}`;
         }
         return 'Loading...'
      },
      creatingUser() {
         console.log(typeof this.selectedUserID, 'doc')
         return this.selectedUserID == null;
      }
   },
   methods: {
      ...mapActions({
         actUpdateUser: 'user/actUpdateUser',
         actResendEmailUser: 'user/actResendEmailUser',
         actCreateUser: 'user/actCreateUser',
      }),
      ...mapMutations({
         mutSetState: 'user/mutSetState'
      }),
       handleEmailResend(){
           this.actResendEmailUser(this.userInformation.id)
       },
      addHospital() {
         this.userInformation.hospitals.push({hospitalId: null})
      },
      async makeSupervisor(idx, hosp) {
         console.log(this.userInformation.id, hosp.id, hosp.isSupervisor)
         this.loadingTitle = 'Saving'
         this.loading = true
         try {
            await api.post('/user/supervise', null, {
               params: {
                  userId: this.userInformation.id,
                  hospitalId: hosp.id,
                  isActive: hosp.isSupervisor !== 1
               }
            })
            this.userInformation.hospitals[idx].isSupervisor = hosp.isSupervisor !== 1 ? 1 : 0


         } catch (e) {

         }
         this.loading = false;
      },
      async makeUserAdmin(val) {
         console.log(this.userInformation.id, 'idididididid')
         this.loadingTitle = 'Saving'
         this.loading = true
         try {
            await api.put(`/user/makeAdmin/${this.userInformation.id}?admin=${val}`)
            this.userInformation.role = val ? 'Polar Speed Admin' : 'Healthcare Professional'
         } catch (e) {
         }
         this.loading = false;
      },
      async removeHospital(id, idx) {
         this.loadingTitle = 'Saving'
         this.loading = true
         if (idx >= 0)
            this.userInformation.hospitals.splice(idx, 1)
         console.log(this.creatingUser, 'this.creatingUserthis.creatingUser')
         if (id && this.userInformation.id && !this.creatingUser) {
            await api.delete(`/User/hospitals`, {
               params: {
                  'hospitalId': id,
                  'userId': this.userInformation.id
               }
            });
         }
         this.loading = false;
      },
      async handleHospitalChange({val, idx, hosp}) {
         console.log(val, idx, hosp, 'val, idx, hosp')
         this.loadingTitle = 'Saving'
         this.loading = true
         if (hosp.id !== undefined) {
            await this.removeHospital(hosp.id, null)
         }
         if (val && this.userInformation.id && !this.creatingUser) {
            await api.post(`/User/hospitals`, null, {
               params: {
                  hospitalId: val,
                  userId: this.userInformation.id
               }
            });

         }
         let found = this.hospitals.find((element) => element.id === val)
         this.userInformation.hospitals[idx] = found
         this.userInformation.hospitals[idx].hospitalId = found.id
         this.loading = false;
      },

      handleRoleChange({val}) {
         console.log(val, 'validxhealthCareType')
         this.userInformation.healthcareType = val
      },
      getHospitals() {
         api.get('/Hospital/all').then(({data}) => {
            this.hospitals = data.items.filter((item) => item.status === true);
         })
      },
      handlePermissionChange(key, importance) {
         this.userInformation.permission[key] = !this.userInformation.permission[key];
         for (const perm of this.permissions) {
            if (key !== perm.key) {
               this.userInformation.permission[perm.key] = importance > perm.importance
            }
         }

      },
      addHealthcare() {
         let elem = this.titleOptions.find((x) => x.healthCareTypeValue === this.userInformation.healthcareType)
         if (elem)
            this.userInformation.healthcareProfessionalType = elem.healthCareTypeId
      },
      async getUserByEmail() {
         try {
            this.loadingTitle = 'Loading user'
            this.loading = true;
            const {data} = await api.get(`/user/${this.selectedUserID}`)
            const { hospitals, ...rest } =  data.value
            this.userInformation = rest;
            this.userInformation.hospitals = hospitals.items;
            console.log(data.value,'ssssdataaaaa')
            this.addHealthcare()
         } catch (e) {
         } finally {
            this.loading = false;
         }
      },
      async updateCreateUser() {
         this.loading = true;
         if (this.creatingUser) {
            // this.userInformation.hospitals = this.userInformation.hospitals?.items
         }
         this.validateForm(this.userInformation)
         if (this.formIsValid) {
            console.log(this.creatingUser, 'this.creatingUser')
            if (this.creatingUser) {
               this.loadingTitle = 'Creating user'
               const {id, permission, ...rest} = this.userInformation;
               const createUser = {...permission, ...rest};
               const result = await this.actCreateUser(createUser);
               if (result !== 'error') {
                  this.$emit('userCreated', result)
               }
            } else {
               this.loadingTitle = 'Updating user'
               await this.actUpdateUser(this.userInformation)
               this.$emit('editCanceled')
            }
         }
         this.loading = false;
      },
      handleCancelEdit() {
         this.$emit('editCanceled')
      }
   },
   async mounted() {

      let hash = location.hash.substr(1);
      if (hash !== 'null') {
         await this.mutSetState({
            key: 'selectedUserID',
            value: parseInt(hash)
         })
      }
      await api.get('/Healthcare').then(({data}) => {
         this.titleOptions = data.items
      })
      if (this.creatingUser) {
         this.userInformation = {
            "id": this.id,
            "title": "",
            "firstName": "",
            "lastName": "",
            "phoneNumber": "",
            "email": "",
            hospitals:  [
                  {
                     hospitalId: null
                  }],
            "role": "Regular User"
         }
         console.log(this.userInformation, 'docu')
      } else {
         await this.getUserByEmail();
      }

      this.getHospitals()

   },
   watch: {
      userInformation: {
         handler() {
            if (this.formSubmitted) {
               this.validateForm(this.userInformation)
            }
         },
         deep: true
      }
   }
}
</script>

<style scoped>

</style>
