<script>
import {mapActions, mapState} from "vuex";
import ProductExtraDetails from "@/components/product/ProductTabInfo.vue";
import ProductImage from "@/components/product/ProductImage.vue";
import ProductDetails from "@/components/product/ProductDetails.vue";
import ExtraDetails from "@/components/product/ExtraDetails.vue";

export default {
    name: "single_product",
    components: {ExtraDetails, ProductDetails, ProductImage, ProductExtraDetails},
    data(){
        return {
            loading: false
        }
    },
    computed:{
        ...mapState({
            singleProduct: state => state.product.singleProduct
        })
    },
    methods: {
        ...mapActions({
            actSetSingleProduct: 'product/actSetSingleProduct'
        }),
        async getProduct(){
            this.loading = true;
            const {productId} = this.$route.params;
            await this.actSetSingleProduct(productId);
            this.loading = false;
        }
    },
    mounted() {
        this.getProduct();
    }
}
</script>

<template>
        <Breadcrumbs :diff-main="singleProduct?.name || 'Single Product'" main-path="/products" main="Products" title="Product Page" />
        <div class="container-fluid" style="max-width:1500px;">
            <div>
                <div class="row product-page-main firstInfoP p-0">
                    <div class="col-xxl-4 col-lg-3 col-md-6 col-sm-6 colPb">
                        <ProductImage :image="singleProduct?.urlImage"/>
                    </div>
                    <div class="col-xxl-5 col-lg-5 order-0 order-sm-last order-lg-0 colPb">
                        <product-details />
                    </div>
                    <div class="col-xxl-3 col-lg-4 col-sm-6 colPb">
                        <extra-details />
                    </div>
                </div>
                <div class="card">
                    <div class="row product-page-main">
                        <ProductExtraDetails />
                    </div>
                </div>
            </div>
        </div>
</template>

<style>
.firstInfoP .card{
    height: 100%;
}

.firstInfoP .card-body{
    display: flex;
    flex-direction: column;
}

.firstInfoP .colPb{
    padding-bottom: 30px;
}
</style>
