import api from "@/utils/api";
import { useToast } from 'vue-toastification';

const toast = useToast();

const errorToast = (e) => {
    const {errors, status, title} = e.response?.data || {title: 'Something wrong happened'}
    toast.error(title)
}

const state = {
    users: null,
    userPatients: null,
    usersPagination: {
        "pageNumber": 1,
        "totalPages": 2,
        "totalCount": 26,
        "hasPreviousPage": false,
        "hasNextPage": true
    },
    selectedUserEmail: null,
    selectedUserID: null,
}

const getters = {

}

const mutations = {
    mutSetState: (state, {key, value}) => {
        state[key] = value;
        console.log(state[key], ':::key:::', key)
    },
    mutSetUserStatus: (state, {id, isActive}) => {
        let foundUser = state.users.find(u => u.id === id);
        if(foundUser) foundUser.isActive = isActive;
    },
    mutDeleteUser: (state, id) => {
        state.users = state.users.filter(u => u.id !== id);
    },
    mutUpdateUser: (state, user) => {
        const userIdx = state.users.findIndex(u => u.id === user.id);
        console.log('mut update user', user, 'useriiii', userIdx)
        if(userIdx >= 0){
            state.users.splice(userIdx, 1, {...user, fullName: `${user.firstName} ${user.lastName}`});
        }
    }
}

const actions = {
    actGetAllUsers: async ({commit}, reqBody) => {
        try {
            const {data} = await api.get('/user', {
                params: reqBody
            })
            console.log(data,'usersssss')

            const {items, ...rest} = data.value;
            console.log(data,items,rest,'usersssss22222')

            commit('mutSetState', {key: 'users', value: items});
            commit('mutSetState', {key: 'usersPagination', value: rest});
        } catch (e) {

        }
    },
    actGetSuperviseUsers: async ({commit}, reqBody) => {
        try {
            const {data} = await api.get('/user/toApprove', {
                params: reqBody
            })
            console.log(data,'usersssss')

            const {items, ...rest} = data.value;
            console.log(data,items,rest,'usersssss22222')

            commit('mutSetState', {key: 'users', value: items});
            commit('mutSetState', {key: 'usersPagination', value: rest});
        } catch (e) {

        }
    },
    actGetAllUserRequests: async ({commit}, reqBody) => {
        try {
            const {data} = await api.get('/user/request', {
                params: reqBody
            })
            console.log(data.value,'usersssss')
            console.log(data,'usersssss')

            const {items, ...rest} = data.value;

            commit('mutSetState', {key: 'users', value: items});
            commit('mutSetState', {key: 'usersPagination', value: rest});
        } catch (e) {

        }
    },
    actGetAllPatients: async ({commit}, reqBody) => {
        try {
            const {data} = await api.get('/patient', {
                params: reqBody
            })
            console.log(data,'usersssss')

            const {items, ...rest} = data.value;
            console.log(data,items,rest,'usersssss22222')

            commit('mutSetState', {key: 'userPatients', value: items});
            commit('mutSetState', {key: 'usersPagination', value: rest});
        } catch (e) {

        }
    },
    actSetUserStatus: async ({commit}, {id, isActive}) => {
        try {
            const response = await api.put(`/user/changeActiveStatus/${id}`, null, {
                params: {
                    isActive
                }
            })
            console.log(response, 'response')
            commit('mutSetUserStatus', {id, isActive});
        } catch (e) {
            errorToast(e)
        }
    },
    actApproveHospitalUser: async ({commit}, params) => {
        try {
            const response = await api.post(`/user/approveHospitalUser`, null, {params})
            console.log(response, 'response')
            // commit('mutSetUserStatus', {id, isActive});
        } catch (e) {
            errorToast(e)
        }
    },
    actCreateUser: async ({commit}, userInfo) => {
        try {
            console.log(userInfo,'userInfouserInfouserInfo')
            const {data} = await api.post('/user/register', userInfo);
            console.log('data:::', data)
            toast.success("User was created successfully",{icon: 'fa fa-check'});
            return data;
        } catch (e) {
            const {errors, status, title} = e.response.data;
            toast.error(title)
            return 'error';
        }
    },
    actResendEmailUser: async ({commit}, userId) => {
        try {
            await api.post(`/user/resendConfirmEmail/${userId}`);
            toast.success("Email sent successfully",{icon: 'fa fa-check'});
        } catch (e) {

        }
    },
    actUpdateUser: async ({commit}, userInfo) => {
        try {
            const {id, ...rest} = userInfo;
            const response = await api.put(`/user/update/${userInfo.id}`, rest);
            console.log(response,'responsresponsee');
            commit('auth/mutLoggedUser', userInfo, { root: true })
            // commit('mutUpdateUser', userInfo);
        } catch (e) {
            const {errors, status, title} = e.response.data
            toast.error(title)
        }
    },
    actDeleteUser: async ({commit}, id) => {
        try {
            await api.delete(`/user/${id}`);
            toast.success("User was deleted successfully",{icon: 'fa fa-check'});
            commit('mutDeleteUser', id)
        } catch (e) {
            const {errors, status, title} = e.response.data
            errorToast(e)
        }
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
