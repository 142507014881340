<script>
import {tableColumnTitles, colTitle} from "@/constants/tableSettings";

export default {
    name: "SearchBy",
    props: ['options', 'modelValue'],
    methods: {
        colTitles(key){
            return colTitle(key);
        },
        handleOptionSelect(opt){
            this.$emit('update:model-value', opt);
        }
    }
}
</script>

<template>
    <div class="dropdown-basic h-100 d-flex align-items-center">
        <div class="dropdown" style="height: 47px !important;">
            <div class="btn-group mb-0" style="margin-right: 0 !important;">
                <button class="dropbtn btn-primary d-flex align-items-center gap-3 min-w-max" type="button">
                    {{ colTitles(modelValue) || 'Search by' }}
                    <span>
                        <i class="fa fa-caret-down"></i>
                    </span>
                </button>
                <div class="dropdown-content">
                    <a v-for="opt in options" class="fs-6 cursor-pointer" @click="handleOptionSelect(opt)">
                        {{colTitles(opt)}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dropbtn{
    color:#212529 !important;
    padding: 12px 12px !important;
}

.btn-primary{
    background-color: white !important;
}
.dropdown{
    border: 1px solid #efefef;
}
</style>
