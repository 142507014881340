<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SettingsInput from "@/components/settings/SettingsInput.vue";
import {useVuelidate} from "@vuelidate/core";
import {email, numeric, maxLength, helpers} from "@vuelidate/validators";
import api from "@/utils/api";
import PolarLoader from "@/components/PolarLoader.vue";
import MasterdataAddress from "@/components/masterdata/MasterdataAddress.vue";
import {useToast} from "vue-toastification";
import {getFileFormData} from "@/utils/helperFunctions";

const phonePrefix = {
   type: 'phoneNumber', api: 'CountryPrefix', trackKey: 'prefix',
   displayKey: (countryInfo) => {
      console.log(countryInfo, 'countryInfocountryInfo')
      if (countryInfo) {
         return ` ${countryInfo.prefix}`
      }
      return ''
   },
   displayOption: (countryInfo) => {
      return `${countryInfo.name} ${countryInfo.prefix}`
   },
   customClass: 'col-3 col-md-2 p-r-0',
   filterKeys: ['name', 'prefix']
}
export default {
   components: {MasterdataAddress, PolarLoader, SettingsInput, ConfirmModal},
   setup() {
      return {
         v$: useVuelidate()
      }
   },
   props: {
      userToManage: null
   },
   data() {
      return {
         fileToUpload: {},
         apiData: {
            professionals: [],
            titles: [],
            therapies: [],
            hospitals: [],
            homecarePro: [
               {id: 'Polar Speed', name: 'Polar Speed'}
            ],
            gender: [
               {id: 'Male', label: 'Male'},
               {id: 'Female', label: 'Female'}
            ],
            leaveMessage: [
               {id: 1, label: 'Yes'},
               {id: 0, label: 'No'}
            ],
            picp: [
               {id: 0, label: 'Yes'},
               {id: 1, label: 'No'}
            ],
            registrationStatus: [
               {id: 0, label: 'New Patient'},
               {id: 1, label: 'Switch Provider'},
               {id: 2, label: 'Switch Therapy'}
            ],
            specifiedDelivery: [
               {id: 1, label: 'Anyone at delivery address'},
               {id: 0, label: 'Specified person(s)'}
            ],
            administrationTrainingRequired: [
               {id: 0, label: 'Yes'},
               {id: 1, label: 'No'}
            ],
            deliveryFrequency: [
               {id: '4 Weekly', label: '4 Weekly'},
               {id: '8 Weekly', label: '8 Weekly'},
               {id: '12 Weekly', label: '12 Weekly'},
               {id: "other", label: 'Other'}
            ],
            administrationTrainingProvider: [
               {id: 'Hospital Nurse', label: 'Hospital Nurse'},
               {id: 'Community Nurse', label: 'Community Nurse'},
               {id: 'Homecare Provider', label: 'Homecare Provider'},
               {id: "other", label: 'Other'}
            ],
         },
         loading: false,
         loadingTitle: '',
         inputs: [
            {
               title: "",
               inputs: [
                  {
                     key: "homecareProvider",
                     type: "select",
                     api: "homecarePro",
                     displayKey: 'name',
                     label: 'Homecare Provider',
                     disabled: true
                  },
                  {
                     key: "hospital",
                     type: "searchSelect",
                     api: 'hospitals',
                     displayKey: 'name',
                     filterKeys: ['name'],
                     backData: 'id'
                  },
                  {
                     key: "therapy",
                     type: "searchSelect",
                     api: 'therapies',
                     displayKey: 'therapyService',
                     filterKeys: ['therapyService'],
                     backData: 'id'
                  },
               ]
            },
            {
               title: "PATIENT, CARER and GP DETAILS",
               inputs: [
                  {
                     key: "nhsNumber", type: "string", validation: {
                        numeric: helpers.withMessage('Value must be numeric', (val) => {
                           const val2 = val.replaceAll(" ", "")
                           const regex = /^\d*(\.\d+)?$/
                           return regex.test(val2)
                        }), maxLength: helpers.withMessage('The required length is 10', (val) => {
                           const val2 = val.replaceAll(" ", "")
                           return val2.length === 10
                        })
                     }
                  },
                  {key: "hospitalNumber", type: "string", validation: {numeric}, disabled: true},
                  {
                     key: "title",
                     type: "searchSelect",
                     api: 'titles',
                     displayKey: 'title',
                     filterKeys: ['title'],
                     backData: 'title'
                  },
                  {key: "firstName", label: "Forename", type: "string"},
                  {key: "lastName", label: "Surname", type: "string"},
                  {key: "birthdate", label: "Date of birth", type: "date"},
                  {key: "address", type: "string"},
                  {key: "postcode", type: "string"},
                  {key: "gender", type: "radio", api: 'gender'},
                  {
                     key: "phoneNumber",
                     label: "Preferred Phone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {
                     key: "alternativePhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {key: "leaveMessage", label: "OK to leave message?", type: "radio", api: 'leaveMessage'},
                  {key: "email", label: "Email address", type: "string", validation: {email}, notRequired:true},
                  {key: "diagnosis", type: "string"},
                  {key: "clinicalLeadName", type: "string"},
                  {
                     key: "clinicalLeadPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {key: "clinicalSpecialistName", type: "string"},
                  {
                     key: "clinicalSpecialistPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {key: "clinicalPharmacistName", type: "string"},
                  {
                     key: "clinicalPharmacistPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {key: "gpName", type: "string", notRequired: true},
                  {key: "gpSurgery", type: "string", notRequired: true},

               ]
            },
            {
               text: "If the patient is under 16, please provide the Parent's Name and their Relation.",
               inputs: [
                  {key: "parentName", type: "string", disabled: true, notRequired: true},
                  {key: "parentRelation", type: "string", disabled: true, notRequired: true},
                  {
                     key: "parentPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix'],
                     disabled: true,
                     notRequired: true
                  },
               ]
            },
            {
               title: "SERVICE REQUIREMENTS – Low Tech and Delivery Service Module",
               inputs: [
                  {key: "registrationStatus", type: "radio", api: 'registrationStatus'},
               ]
            },
            {
               title: "Initial delivery / treatment details",
               inputs: [
                  {key: "deliveryAddress", type: "string"},
                  {key: "deliveryPostcode", type: "string"},
                  {key: "firstDeliveryDatetimeUtc", type: "date"},
                  {key: "specifiedDelivery", type: "radio", api: 'specifiedDelivery', valueType: 'number'},
                  {key: "specifiedPersonName", type: "string", disabled: 1, notRequired: 'true'},
                  {
                     key: "specifiedPersonPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix'],
                     disabled: 1,
                     notRequired: 'true'
                  },

                  {key: "specifiedPersonRelation", type: "string", disabled: 1, notRequired: 'true'},
                  {key: "deliveryFrequency", type: "radioOther", api: 'deliveryFrequency'},
                  {
                     key: "picp",
                     type: "radio",
                     api: 'picp',
                     label: 'Patient Individual Care Plan attached',
                     valueType: 'number'
                  },
                  {key: "picpRef", type: "string", disabled: 1, label: 'Patient Individual Care Plan', notRequired: 'true'},
               ]
            },
            {
               title: "ADDITIONAL CLINICAL SERVICE REQUIREMENTS",
               inputs: [
                  {
                     key: "administrationTrainingRequired",
                     type: "radio",
                     api: 'administrationTrainingRequired',
                     notRequired: true,
                     valueType: 'number'
                  },
                  {key: "administrationTrainingDatetimeUtc", type: "date", disabled: 1, notRequired: true},
                  {
                     key: "administrationTrainingProvider",
                     type: "radioOther",
                     api: 'administrationTrainingProvider',
                     notRequired: true,
                     disabled: true
                  },
               ]
            },
            {
               title: "",
               inputs: [
                  {
                     key: "nurseAdministrationRequired",
                     type: "radio",
                     api: 'administrationTrainingRequired',
                     valueType: 'number'
                  },
                  {key: "nurseAdministrationRequiredDatetimeUtc", type: "date", disabled: 1, notRequired: 'true'},
                  {
                     key: "nurseAdministrationProvider",
                     type: "radioOther",
                     notRequired: 'true',
                     api: 'administrationTrainingProvider',
                     disabled: true
                  },
               ]
            },
            {
               title: "REFERRING PHYSICIAN/HEALTHCARE PROFESSIONAL ",
               inputs: [
                  {
                     key: "termsAndCondition",
                     type: "boolean",
                     label: 'I have communicated extensively and supplied ample information about the Homecare service to the patient named above. The patient has consented to the referral into the homecare service. I affirm that a thorough home suitability assessment has been conducted, indicating the patient\'s suitability for the homecare service. Additionally, I confirm that I have informed the patient about the possibility of funding for this homecare service by a pharmaceutical company.'
                  },
                  {
                     key: "professionalId",
                     label: "Healthcare Professional",
                     type: "searchSelect",
                     api: 'professionals',
                     displayKey: 'firstName',
                     filterKeys: ['firstName'],
                     backData: 'id'
                  },
                  {key: "date", type: "date", disabled: 1,},

               ]
            },
            {
               title: "INVOICING DETAILS & ADMINISTRATIVE CONTACTS",
               inputs: [
                  {key: "invoiceAddress", type: "string"},
                  {key: "invoicePostcode", type: "string"},
                  {key: "homecareLeadName", type: "string"},
                  {key: "homecareLeadEmail", type: "string", validation: {email}, notRequired:true },
                  {key: "invoiceContactName", type: "string"},
                  {
                     key: "invoiceContactPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },
                  {key: "invoiceContactEmail", type: "string", validation: {email}, notRequired:true},
                  {key: "invoiceCAccountName", type: "string"},
                  {
                     key: "homecareLeadPhone",
                     type: "phoneNumber",
                     api: 'CountryPrefix',
                     trackKey: 'prefix',
                     filterKeys: ['name', 'prefix']
                  },

               ]
            },
            {
               title: "This Patient Registration Form must be forwarded with a valid prescription to the Hospital’s Pharmacy Department prior to transmission to Polar Speed Pharmacy.",
               // text: "Appendix 4a Version 4.0 - Approved: 12th Jun 2018",
               inputs: [
                  {
                     key: "homecareProvider",
                     type: "select",
                     api: "homecarePro",
                     displayKey: 'name',
                     label: 'Homecare Provider',
                     disabled: true
                  },
                  {key: "date", type: "date", disabled: 1,},

               ]
            },
         ],
         patientData: {
            "date": new Date(),
            "nhsNumber": "",
            "hospitalId": null,
            "therapyId": null,
            "title": "",
            "firstName": "",
            "lastName": "",
            "birthdate": "",
            "address": "",
            "postcode": "",
            "gender": "",
            "phoneNumber": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "alternativePhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "leaveMessage": false,
            "email": "",
            "diagnosis": "",
            "clinicalLeadName": "",
            "clinicalLeadPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "clinicalSpecialistName": "",
            "clinicalSpecialistPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "clinicalPharmacistName": "",
            "clinicalPharmacistPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "gpName": "",
            "gpSurgery": "",
            "parentName": null,
            "parentRelation": null,
            "parentPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "registrationStatus": null,
            "deliveryAddress": "",
            "deliveryPostcode": "",
            "firstDeliveryDatetimeUtc": null,
            "specifiedPersonName": "",
            "specifiedPersonPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "specifiedPersonRelation": "",
            "deliveryFrequency": null,
            "picpRef": "",
            "administrationTrainingDatetimeUtc": null,
            "administrationTrainingProvider": null,
            "nurseAdministrationRequiredDatetimeUtc": null,
            "nurseAdministrationProvider": null,
            "termsAndCondition": false,
            "professionalId": null,
            "invoiceAddress": "",
            "invoicePostcode": "",
            "homecareLeadName": "",
            "homecareLeadEmail": "",
            "homecareLeadPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "invoiceContactName": "",
            "invoiceContactPhone": {
               "phoneNumber": "",
               "phonePrefix": ""
            },
            "invoiceContactEmail": "",
            "invoiceCAccountName": "",
            "homecareProvider": "Polar Speed"
         },
         patientDataAutofill: {
            "date": "2024-04-12T17:40:47.693Z",
            "nhsNumber": "1111111111",
            "hospitalId": 2,
            "therapyId": 1,
            "title": 1,
            "firstName": "autofill",
            "lastName": "autofill",
            "birthdate": "2024-04-01",
            "address": "autofill",
            "postcode": "autofill",
            "gender": "Male",
            "phoneNumber": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "alternativePhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "leaveMessage": false,
            "email": "autofill@autofill.com",
            "diagnosis": "autofill",
            "clinicalLeadName": "autofill",
            "clinicalLeadPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "clinicalSpecialistName": "autofill",
            "clinicalSpecialistPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "clinicalPharmacistName": "autofill",
            "clinicalPharmacistPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "gpName": "autofill",
            "gpSurgery": "autofill",
            "parentName": "autofill",
            "parentRelation": "autofill",
            "parentPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "registrationStatus": 0,
            "deliveryAddress": "autofill",
            "deliveryPostcode": "autofill",
            "firstDeliveryDatetimeUtc": "2024-04-01",
            "specifiedPersonName": "autofill",
            "specifiedPersonPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "specifiedPersonRelation": "autofill",
            "deliveryFrequency": "autofill",
            "picpRef": "autofill",
            "administrationTrainingDatetimeUtc": "2024-04-01",
            "administrationTrainingProvider": "autofill",
            "nurseAdministrationRequiredDatetimeUtc": "2024-04-01",
            "nurseAdministrationProvider": "autofill",
            "termsAndCondition": true,
            "professionalId": 4,
            "invoiceAddress": "autofill",
            "invoicePostcode": "autofill",
            "homecareLeadName": "autofill",
            "homecareLeadEmail": "autofill@autofill.com",
            "homecareLeadPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "invoiceContactName": "autofill",
            "invoiceContactPhone": {"phoneNumber": "1111111111", "phonePrefix": "+44"},
            "invoiceContactEmail": "autofill@autofill.com",
            "invoiceCAccountName": "autofill",
            "homecareProvider": "Polar Speed",
            "hospitalNumber": 123456,
            "hospital": 2,
            "therapy": 1,
            "specifiedDelivery": "0",
            "picp": "0",
            "nurseAdministrationRequired": "0",
            "administrationTrainingRequired": "0"
         },
         patient: {}
      }
   },
   computed: {},
   methods: {
      uploadFile(e) {
         this.fileToUpload = e.target.files
         console.log(e.target.files, 'eeeeeeeeee')
      },
      async sendFile(val){
         if (!this.fileToUpload.length)
            return
         const file = this.fileToUpload;
         const form = await getFileFormData(file)
         console.log(!file.length,"formformformformform")
         try {
            const {data} = await api.post(`/patient/attachDocument/${val}`, form, {
               headers: {
                  'Content-Type': 'multipart/form-data'
               }
            })
            const {errors, ...rest} = data
            if(errors?.length){
               useToast().error(`There's an error`)
            } else {
               useToast().success('Document was imported successfully')
            }
         } catch (e) {
         } finally {
         }
      },
      async getTherapy() {
         try {
            const {data} = await api.get('/Therapy')
            this.apiData.therapies = data.value.items.map((e) => {
               return {
                  ...e,
                  name: e.therapyService
               }
            });

         } catch (e) {
            console.log(e);
         } finally {
         }
      },
      async getHospital() {
         try {
            const {data} = await api.get('/Hospital/all')
            this.apiData.hospitals = data.items.filter((item) => item.status === true);
         } catch (e) {
            console.log(e);
         } finally {
         }
      },
      async getPacientTitles() {
         try {
            const {data} = await api.get('/Titles')
            this.apiData.titles = data.value.items;
            console.log(this.apiData.titles, 'titlestitles')
         } catch (e) {
            console.log(e);
         } finally {
         }
      },
      async getProfessionals(val) {
         console.log(val,'valvalvalvalvalval')
         try {
            const {data} = await api.get('user/professionals', {
               params: {
                  id: val
               }
            })
            console.log(data, 'professionals')
            this.apiData.professionals = data.value.items;
         } catch (e) {
            console.log(e);
         } finally {
         }
      },
      closeNew() {
         this.patient = this.patientData
         this.$emit('closeNew')
      },
      async savePatient() {
         const result = await this.v$.$validate()
         console.log(result, 'result', this.v$)
         if (result) {
            try {
               this.loadingTitle = 'Saving Changes'
               this.loading = true;
               const {data} = await api.post('/patient', this.patient)
               console.log(data.value,'data.valuedata.value')
               if (!!data.value){
                  await this.sendFile(data.value)
                  this.closeNew()
               }

               console.log(data, 'dataaaaapatient')
            } catch (e) {
               console.log(e);
            } finally {
               this.loading = false;
            }

         } else
            useToast().error('Fill in all required fields')


      },
      getAge(dateString) {
         let today = new Date();
         let birthDate = new Date(dateString);
         let age = today.getFullYear() - birthDate.getFullYear();
         let m = today.getMonth() - birthDate.getMonth();
         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
         }
         console.log(age, 'agge')
         return age;
      },
      handleDataChange({key, val, idx}) {
         console.log({key, val, idx}, 'handleDataChange')
         if (key === 'registrationStatus') {
            this.patient[key] = parseInt(val)
            return
         }
         if (key === 'leaveMessage') {
            this.patient[key] = !!val
            return
         }
         if (key === 'birthdate') {
            let ageEnough = val ? (this.getAge(val) > 18) : true
            let arr = ['parentName', 'parentRelation', 'parentPhone']
            for (let x = 0; x < arr.length; x++) {
               let found = this.inputs[idx+1].inputs.find((element) => element.key === arr[x])
              if (found)
               found.disabled = ageEnough
            }
            if (!ageEnough) {
               this.patient.parentName = null;
               this.patient.parentRelation = null;
               this.patient.parentPhone = {
                  "phoneNumber": "",
                  "phonePrefix": ""
               };
            }
         }
         if (key === 'nurseAdministrationRequired') {
            let arr = ['nurseAdministrationRequiredDatetimeUtc', 'nurseAdministrationProvider']
            for (let x = 0; x < arr.length; x++) {
               const k = arr[x]
               const found = this.inputs[idx].inputs.find((element) => element.key === k)
               found.disabled = val
               if (val)
                  this.patient[k] = null;

            }
         }
         if (key === 'administrationTrainingRequired') {
            const found = this.inputs[idx].inputs.find((element) => element.key === 'administrationTrainingDatetimeUtc');
            const found1 = this.inputs[idx].inputs.find((element) => element.key === 'administrationTrainingProvider');
            if (val) {
               this.patient.administrationTrainingProvider = null;
               this.patient.administrationTrainingDatetimeUtc = null;
            }
            found.disabled = val
            found1.disabled = val
         }
         if (key === 'picp') {
            const found = this.inputs[idx].inputs.find((element) => element.key === 'picpRef')
            if (val) {
               this.patient.picpRef = null;
            }
            found.disabled = val
         }
         if (key === 'specifiedDelivery') {
            let y = ['specifiedPersonName', 'specifiedPersonPhone', 'specifiedPersonRelation']
            for (let i = 0; i < y.length; i++) {
               const found = this.inputs[idx].inputs.find((element) => element.key === y[i])
               if (val) {
                  this.patient[y[i]] = null;
               }
               found.disabled = val
            }
         }
         if (key === 'hospital') {
            const found = this.apiData.hospitals.find((element) => element.id === val)
            this.patient['hospitalNumber'] = found.hospitalNumber
            this.patient['hospitalId'] = found.id
            this.getProfessionals(found.id)

         }
         if (key === 'therapy') {
            this.patient['therapyId'] = val
         }
         this.patient[key] = val
      },
   },
   created() {
      this.loadingTitle = 'Loading data'
      this.loading = true;
      this.getTherapy()
      this.getHospital()
      this.getPacientTitles()
      // this.getProfessionals()
      this.patient = this.patientData
      // this.patient = this.patientDataAutofill
      this.loading = false;

   },
}
</script>

<template>
   <div>
      <polar-loader v-if="loading">
         {{ loadingTitle }}
      </polar-loader>
      <div class="card-body" :style="idx === 0 ? '' : 'border-top: 1px dashed #ced4da;'"
           v-for="(section, idx) in inputs" :key="section.title">
         <div v-if="section.title">
            <h5>{{ section.title }}</h5>
         </div>
         <div v-if="section.text">
            <div  >{{ section.text }}</div>
         </div>
         <div class="">
            <div :id="`masterData${section.title}`"
                 class="collapse show row"
                 :aria-labelledby="`masterData${section.title}`">
               <div v-for="i in section.inputs"
                    :class="((i.type === 'radioOther' || i.key === 'termsAndCondition') ? 'col-md-12' : 'col-md-6' ) + ' mb-3'">
                  <settings-input :input="i"
                                  :parent-select="apiData[i.api]"
                                  :model-value="patient[i.key]"
                                  :disabled="i.disabled"
                                  @update:model-value="(val) => handleDataChange({ val, key: i.key, idx})"/>
               </div>
            </div>
         </div>
      </div>
      <div class="form-group" style="padding: 30px;">
         <label class="col-form-label pt-0">Upload File:</label>
         <input class="form-control" type="file" multiple @change="uploadFile">
      </div>
      <div class="row mb-5">
         <button v-if="!this.userToManage" class="btn btn-lg btn-primary btn-block w-50 m-t-30 m-auto"
                 @click="savePatient">Save
         </button>
      </div>
   </div>
</template>

<style scoped>

</style>
