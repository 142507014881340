export const addressInputs = {
    addresses: [
        {label: 'Address', type: 'string', key: 'address'},
        {label: 'Street', type: 'string', key: 'street'},
        {label: 'Country', type: 'select', api: 'Country', key: 'countryId', valueType: 'number'},
        {label: 'State', type: 'select', api: 'State', relation: 'countryId', key: 'stateId', valueType: 'number'},
        {label: 'County', type: 'select', api: 'County', relation: 'stateId', key: 'countyId', valueType: 'number'},
        {label: 'City', type: 'select', api: 'City', relation: 'countyId', key: 'cityId', valueType: 'number'},
        {label: 'Postal Code', type: 'string', key: 'zipCode'},
        {label: 'Valid From', type: 'date', key: 'addressValidFrom'},
        {label: 'Valid To', type: 'date', key: 'addressValidTo'},
        {label: 'Customer Address Check Date', type: 'date', key: 'customerAddressCheckDate'},
        {label: 'Validation Expiry Date', type: 'date', key: 'addressValExpDate'},
        {label: 'D365 Address Location ID', type: 'null', key: 'd365AddressLocationID'},
    ],
    contacts: [
        {label: 'Description', type: 'string', key: 'description'},
        {label: 'Contact', type: 'string', key: 'locator'},
        {label: 'D365 Address Location ID', type: 'null', key: 'd365AddressLocationID'},
        {label: 'Is Primary', type: 'boolean', key: 'isPrimary'},
        {label: 'Is Private', type: 'boolean', key: 'isPrivate'},
    ]
}
