<template>
    <div class="container-fluid">
        <Breadcrumbs main="Dashboard"/>
<!--        <DashboardNotifications/>-->
        <div class="row starter-main">
            <template v-for="(card, idx) in DashboardCards.data">
                <div class="col-12 col-sm-6 col-xl-4 p-1" :key="idx" v-if="card.display <= getRole(loggedUser.role)">
                    <home-card :content="card" />
                </div>
            </template>

        </div>
    </div>
</template>
<script setup>
import HomeCard from "@/components/homeCard.vue";
import DashboardCards from "@/data/dashboardCards.json";
import {onMounted} from "vue";
import {useStore} from "vuex";
import DashboardNotifications from "@/components/dashboard/DashboardNotifications.vue";

const store = useStore();
const loggedUser = store.state.auth.loggedUser
function getRole (role){
    let pByte = 0
    switch (role) {
        case 'Admin':
        case 'Polar Speed Admin':
            pByte = 3
            break;
        case 'HealthCare Professional':
            pByte = 2
            break;
        default:
            pByte = 1;
    }
    return pByte
}
onMounted(() => {
    // store.dispatch('notifications/actGetDashNotifications');
})

</script>
