<template>
<div>
    <div class="page-wrapper">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img class="img-fluid for-light" src="../../assets/images/logo/polar-logo.png" alt="polar logo" />
                                </a>
                            </div>
                            <div :class="['login-main position-relative overflow-hidden', {'b-t-danger': authResponse.title || authResponse.description}]">
                                <PolarLoader v-if="loading">
                                    Loading
                                </PolarLoader>
                                <form v-if="!showMfa" class="theme-form" novalidate="" @submit.prevent="resetPassword">
                                    <h4>Create Your Password</h4>
<!--                                    <div class="form-group">-->
<!--                                        <label class="col-form-label" for="email">Email Address</label>-->
<!--                                        <input v-model="email"-->
<!--                                               :class="[-->
<!--                                                'form-control',-->
<!--                                                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
<!--                                           ]"-->
<!--                                               id="email" type="email" required="" placeholder="Your email" />-->
<!--                                        <div class="invalid-feedback">{{ errors.email }}</div>-->
<!--                                    </div>-->
                                    <div class="form-group">
                                        <label class="col-form-label">New Password</label>
                                        <div class="form-input position-relative">

                                            <input autocomplete="off"
                                                   v-model="password"
                                                   :type="active?'password':'text'"
                                                   :class="[
                                                        'form-control',
                                                        `${formSubmitted ? passwordError ? 'is-invalid' : 'is-valid' : ''}`
                                                    ]"
                                                   name="login[password]"
                                                   required=""
                                                   placeholder="*********" />
                                            <div class="show-hide"
                                                 style="padding-right: 10px;">
                                                <span :class="active?'show':'hide'" @click.prevent="show"> </span>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback d-block">{{ errors.password }}</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Retype Password</label>
                                        <input autocomplete="off"
                                               v-model="confirmPassword"
                                               :class="[
                                                    'form-control',
                                                    `${formSubmitted ? confirmPasswordError ? 'is-invalid' : 'is-valid' : ''}`
                                               ]"
                                               :type="active?'password':'text'"
                                               name="login[password]"
                                               required=""
                                               placeholder="*********">
                                        <div class="invalid-feedback">{{ errors.confirmPassword }}</div>
                                    </div>
                                    <div class="form-group mb-0">
<!--                                        <div class="checkbox p-0">-->
<!--                                            <input id="checkbox1" type="checkbox">-->
<!--                                            <label class="text-muted" for="checkbox1">Remember password</label>-->
<!--                                        </div>-->
                                        <button class="btn btn-primary btn-block w-100" type="submit">Done </button>
                                    </div>
                                    <p class="mt-4 mb-0 text-center">Don't have account?
                                        <router-link tag="a" to="/auth/register">
                                            Create Account
                                        </router-link>
                                    </p>
                                </form>
                                <form v-if="showMfa" class="theme-form" novalidate="" @submit.prevent="handleSignInMfa">
                                    <h5>We have sent an one time password on your email!</h5>
                                    <p>Check your email!</p>
                                    <div class="form-group">
                                        <label class="col-form-label">Enter your OTP to login</label>
                                        <input v-model="otp"
                                               :class="[
                                            'form-control',
                                            `${formSubmitted ? false ? 'is-invalid' : 'is-valid' : ''}`
                                       ]"
                                               type="text" required placeholder="OTP" />
                                        <div class="invalid-feedback">{{ errors.email }}</div>
                                    </div>
                                    <div @click="resendOtp()" style="color: #007cc2; margin-bottom: 3rem; cursor: pointer; width: 6rem;">
                                        Resend Otp
                                    </div>
                                    <div class="form-group mb-0">
                                        <button class="btn btn-primary btn-block w-100"
                                                type="submit">
                                            Sign in
                                        </button>
                                    </div>
                                </form>
                                <div v-else-if="authResponse.title || authResponse.description" class="absoluteCenter w-100 h-100 bg-white d-flex flex-column" style="padding: 40px;">
                                    <h4 :class="['text-danger']">{{authResponse.title}}</h4>
                                    <p v-html="authResponse.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import passwordValidation from "@/mixins/validations/passwordValidation";
import PolarLoader from "@/components/PolarLoader.vue";
import {validateEmail} from "@/utils/helperFunctions";
import api from "@/utils/api";
export default {
    components: {PolarLoader},
    mixins: [passwordValidation],
    data() {
        return {
            active: true,
            showMfa: false,
            token: '',
            loading: false,
            otp: "",
            email: "",
            password: "",
            confirmPassword: "",
        }
    },
    computed:{
        authResponse(){
            return this.$store.state.auth.authResponse
        }
    },
    methods: {
        async resendOtp(){
            console.log('tstssssss')
            this.loading = true;
            try {
                await api.post(`/user/resend?email=${this.email}`)
            }catch (e) {
                // useToast().error(e.response?.data?.errors[0]?.description || 'Something went wrong')
            }
            this.loading = false;
        },
        show() {
            this.active = !this.active
        },
        async handleSignInMfa() {
            this.loading = true;
            console.log(this.otp, this.email)
            await this.$store.dispatch('auth/actLoginMfa', {email: this.email, code: this.otp});
            if (this.$store.state.auth.loggedUser) {
                console.log('hiniru reset')
            this.$router.push('/');
            } else {
                this.successfulRegister = -1;
            }
            this.loading = false;

        },
        async resetPassword(){
            this.loading = true;
            this.validatePasswordForm({
                password: this.password,
                confirmPassword: this.confirmPassword,
                email: this.email
            })

            if(this.formIsValid){
                // await this.getResetToken();

                // const token = this.$store.state.auth.resetToken;
                if(this.token){
                    const r = await this.$store.dispatch("auth/actResetPassword", {
                        "email": this.email,
                        "password": this.password,
                        "confirmPassword": this.confirmPassword,
                        "token": this.token
                    })
                    if(r?.isSuccess){
                        this.showMfa = true;
                    }
                    // if(this.$store.state.auth.loggedUser) {
                    //     window.location.replace('/');
                    // }
                }
            }

            this.loading = false
        },
        // async getResetToken(){
        //     await this.$store.dispatch('auth/actGetResetToken', this.email)
        // }
    },
    mounted() {
        this.email = this.$route.params.email;
        const {fullPath} = this.$route;
        this.token = fullPath.split("token=")[1];
        console.log(this.token, 'tokeniii')
        if(!validateEmail(this.email) || !this.token){
            this.$store.commit('auth/mutSetState', {key: 'authResponse', value: {title: 'Invalid URL', description: 'Contact support or check if the URL is correct'}})
        }
    },
    unmounted() {
        this.$store.commit('auth/mutSetState', {key: 'authResponse', value: {title: '', description: ''}})
    }
}
</script>
