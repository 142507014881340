<script>
export default {
    name: "HomeCard",
    props:{
        content: {
            type: Object,
            default: () => {
                return {
                    icon: 'radio',
                    title: 'title',
                    description: 'description',
                    path: '/',
                    pathText: 'path text',
                    color: 'primary'
                }
            }
        }
    }
}
</script>

<template>
    <div class="card course-box">
        <div class="card-body">
            <div class="course-widget">
                <div :class="['course-icon', (content.color || 'warning')]" style="min-width: 60px !important;">
                    <vue-feather :type="content.icon || 'radio'" stroke="white" class="my-icon"/>
<!--                    <svg class="fill-icon">-->
<!--                        <use href="@/assets/svg/icon-sprite.svg#course-2"></use>-->
<!--                    </svg>-->
                </div>
                <div>
                    <h4 class="mb-2">{{ content.title }}</h4>
                    <span class="f-light cut-text">{{ content.description }}</span>
                    <router-link v-if="content.path" class="btn btn-light f-light max-w-max"
                                 :to="content.path">
                        {{ content.pathText }}
                        <span class="ms-2">
                            <svg class="fill-icon f-light">
                                <use href="@/assets/svg/icon-sprite.svg#arrowright"></use>
                            </svg>
                        </span>
                    </router-link>
                    <div v-else style="margin-top: 62px">

                    </div>
                </div>
            </div>
        </div>
        <ul class="square-group">
            <li class="square-1 warning"></li>
            <li class="square-1 primary"></li>
            <li class="square-2 warning1"></li>
            <li class="square-3 danger"></li>
            <li class="square-4 light"></li>
            <li class="square-5 warning"></li>
            <li class="square-6 success"></li>
            <li class="square-7 success"></li>
        </ul>
    </div>
</template>

<style>
.my-icon {
    svg {
        fill: none !important;
    }
}
</style>
