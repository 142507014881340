<script>
import api from "@/utils/api";

export default {
    name: "UserTitle",
    props: ['error', 'showValidation', 'modelValue'],
    data(){
        return {
            titleOptions: [],
            updModelValue:null
        }
    },
  mounted() {
    this.getHospitals();
  },
    methods:{
        handleSelectChange(e){
          console.log(e.target,'e.target')
          let n = parseInt(e.target.value)
            this.$emit('update:model-value', n);
        },
      async getHospitals() {
       await api.get('/Healthcare').then(({data}) => {
          console.log(data,'datadatadatadata')
          for (let x=0; x<data.items?.length; x++){
            this.titleOptions.push(data.items[x])
          }
        })

      }
    }
}
</script>

<template>
    <label class="col-form-label" for="title">Role*</label>
  {{updModelValue}}
    <select id="title"
            :value="updModelValue"
            @change="handleSelectChange"
            :class="['form-select', (showValidation ? error ? 'is-invalid' : 'is-valid' : '')]"
            required="">
        <option selected="" disabled="" value="">Role...</option>
        <option v-for="opt in titleOptions" :key="opt.healthcareTypeId" :value="opt.healthcareTypeId">{{ opt.healthcareTypeValue }}</option>
    </select>
    <div class="invalid-feedback">{{ error }}</div>
</template>

<style scoped>

</style>
