import { email, numeric, helpers } from '@vuelidate/validators';
import {separateOnCapitalLetter} from "@/utils/helperFunctions";
import {phonePrefix} from "@/constants/phonePrefix";
export const tablesDisplayInfo = {
    default:[
        'code',
        'description',
        'isDefault',
    ],
    Hospital:[
        'hospitalNumber',
        'name',
        'email',
        'address',
        'postcode',
        'status',
    ],
    Healthcare:[
        'healthcareTypeId',
        'healthcareType',
    ],
    Titles:[
        'id',
        'title'
    ],
    Therapy:[
        'id',
        'therapyService',
        'description',
    ],
    PortalConfiguration:[
        'email',
        "createdOnUtc",
        // "createdBy",
        "lastModifiedOnUtc",
        // "lastModifiedBy",
    ],
    PaymTerm:[
        'name',
        'numOfDays',
        'isDefault',
    ],
    PortalParameter:[
        'portalUrl',
        'stdCurrency',
        'stdUnitOfMeasure',
        'multipleCurrencyAllowed'
    ],
    UnitOfMeasure: [
        "symbol",
        "description",
    ],
    Country: [
        'name',
        'description',
        'isoCode',
    ],
    ZipCode:[
        "zipCode",
        "countryName",
        "stateName",
        "countyName",
        "cityName",
    ],
    InventLocation: [
        'name',
        'description',
        'siteName',
        'isDefault',
    ],
    InventSite: [
        'name',
        'description'
    ],
    City:[
        'name',
        'countryName',
        'stateName',
        'countyName',
    ],
    County: [
        'name',
        'countryName',
        'stateName',
    ],
    CustPaymMode: [
        'name',
        'description',
        'isDefault',
    ],
    CustGroup: [
        "name",
        "isDefault",
        "paymentTermId",
        "paymentTerm",
        "bankCustPaymIdTable",
        "clearingPeriod",
        "defaultDimension",
        "priceIncludesSalesTax",
        "taxGroupId",
        "taxPeriodPaymentCodePl",
        "custWriteOffRefRecId",
        "custAccountNumSeq",
        "recId",
        "recVersion",
        "isPublicSectorIt",
    ],
    State: [
        'name',
        'description',
        'countryName',
    ],
    SalesPool: [
        "name",
        "description",
        "isDefault",
    ],
    ItemGroup: [
        "name",
        "description",
    ],
    Faq: [
        "title",
        "description",
        "createdOnUtc",
        // "createdBy",
        "lastModifiedOnUtc",
        // "lastModifiedBy",
    ],
    Currency: [
        "code",
        "description",
    ],
    TaxItemGroup: [
        "vatGroup",
        "description",
        "percentage",
        "note",
    ],
    TradeAgreement: [
        "id",
        "relation",
        "accountCode",
        "accountRelation",
        "itemCode",
        "itemRelation",
    ]

}

export const getDisplayInfo = (key) => {
    if(!tablesDisplayInfo[key]) key = 'default';
    return tablesDisplayInfo[key];
}
export const dateTypes = {
    createdOnUtc: {type: 'date'},
    lastModifiedOnUtc: {type: 'date'},
    processedOnUtc: {type: 'date'},
}

export const tableColumnTitles = {
    'name': 'Name',
    'vatGroup': 'Vat Group',
    'id': 'Id',
    'title': 'Title',
    'createdOnUtc': 'Created On',
    'lastModifiedOnUtc': 'Last Modified',
    'lastModifiedBy': 'Modified By',
    'code': 'Code',
    'description': 'Description',
    'isDefault': 'Default',
    'isoCode': 'Iso Code',
    'dataAreaId': 'Data Area Id',
    'countyCodeIt': 'County Code It',
    'inventLocations': 'Invent Locations',
    'countryName': 'Country',
    'stateName': 'State',
    'countyName': 'County',
    'numOfDays': 'Number of Days',
    'numofDays': 'Number of Days',
    'portalUrl': 'Portal Url',
    'stdCurrency': 'Currency',
    'stdUnitOfMeasure': 'Unit',
    'countryId': 'Country',
    'countyId': 'County',
    'createdBy': 'Created By',
    'cityId': 'City',
    'siteName': 'Site',
    'cityName': 'City',
    'stateId': 'State',
    'siteId': 'Invent Site',
    'zipCode': 'Zip Code',
    'paymentTermId': 'Payment Term',
    'multipleCurrencyAllowed': 'Multiple Currency',
    'accountCode': 'Account Code',
    'AccountRelation': 'Account Relation',
    'accountRelation': 'Account Relation',
    'itemCode': 'Item Code',
    'ItemRelation': 'Item Relation',
    'itemRelation': 'Item Relation',
    'unitOfMeasureId': 'Unit of Measure',
    'quantityAmountFrom': 'Amount From',
    'quantityAmountTo': 'Amount To',
    'percent1': 'Percent 1',
    'percent2': 'Percent 2',
    'relation': 'Relation',
    'currencyId': 'Currency',
    'amount': 'Amount',
}

export const colTitle = (column, table) => {
    if(column === 'code' || column === 'name'){
        return table;
    }
    return tableColumnTitles[column] || separateOnCapitalLetter(column);
}

export const settings = [
    {title: 'Hospital List', buttonText:'Hospitals', api: 'Hospital', CUD: [1,1,1], getApi:'/all', createApi:'/add'},
    {title: 'Healthcare', buttonText:'Healthcare', api: 'Healthcare', CUD: [1,1,1]},
    {title: 'Patient Titles', buttonText:'Titles', api: 'Titles', CUD: [1,1,1]},
    {title: 'Therapeutic Domain', buttonText:'Domains', api: 'Therapy', CUD: [1,1,1]},
    {title: 'Portal Global Configuration', buttonText:'Configurations', api: 'PortalConfiguration', CUD: [1,1,1]},
    // {title: 'Delivery mode', api: 'DlvMode', CUD: [1,1,1]},
    // {title: 'Delivery term', api: 'DlvTerm', CUD: [1,1,1]},
    // {title: 'Customer payment mode', api: 'CustPaymMode', CUD: [1,1,1]},
    // {title: 'Payment terms', api: 'PaymTerm', CUD: [1,1,1]},
    // {title: 'Portal Parameter', api: 'PortalParameter', CUD: [0,1,0]},
    // {title: 'Invent Location', api: 'InventLocation', CUD: [1,1,1]},
    // {title: 'Site', api: 'InventSite', CUD: [0,1,1]},
    // {title: 'Country', api: 'Country', CUD: [1,1,1]},
    // {title: 'County', api: 'County', CUD: [1,1,1]},
    // {title: 'City', api: 'City', CUD: [1,1,1]},
    // {title: 'State', api: 'State', CUD: [1,1,1]},
    // {title: 'Sales Pool', api: 'SalesPool', CUD: [1,1,1]},
    // {title: 'Item Group', api: 'ItemGroup', CUD: [1,1,1]},
    // {title: 'Zip Code', api: 'ZipCode', CUD: [1,1,1]},
    // {title: 'Customer Group', api: 'CustGroup', CUD: [1,1,1]},
    {title: 'Frequently Asked Questions', buttonText:'FAQs', api: 'Faq', CUD: [1,1,1]},
    // {title: 'Currency', api: 'Currency', CUD: [0,0,0]},
    // {title: 'Tax Item Group', api: 'TaxItemGroup', CUD: [0,0,0]},
    // {title: 'Trade Agreement', api: 'TradeAgreement', CUD: [1,0,1]},
    // {title: 'Unit of Measure', api: 'UnitOfMeasure', CUD: [0,0,0]},
]



export const cUSettings = {
    Hospital: {
        hospitalNumber: {type: 'string', default: '', validation: {numeric}},
        name: {type: 'string', default: ''},
        email: {type: 'string', default: '', validation: {email}},
        address: {type: 'string', default: ''},
        postcode: {type: 'string', default: ''},
        status: {type: 'boolean', default: false, label:'Is Active'}

    },
    Titles: {
        title: {type: 'string', default: ''},
    },
    Healthcare: {
        healthcareType: {type: 'string', default: ''},
    },
    Therapy: {
        therapyService: {type: 'string', default: ''},
        description: {
            type: 'textarea',
            default: '',
            textareaOpts: {
                rows: 5,
                cols: 70
            }
        },
    },
    PortalConfiguration: {
        email: {type: 'string', default: '', validation: {email}},
    },
    DlvMode: {
        code: {type: 'string', default: ''},
        description: {type: 'string', default: ''},
        isDefault: {type: 'boolean', default: false}
    },
    DlvTerm: {
        code: {type: 'string', default: ''},
        description: {type: 'string', default: ''},
        isDefault: {type: 'boolean', default: false}
    },
    CustPaymMode:{
        'name': {type: 'string', default: ''},
        'description': {type: 'string', default: ''},
        'isDefault': {type: 'boolean', default: false},
    },
    PaymTerm: {
        "name": {type: 'string', default: ''},
        "numOfDays": {type: 'string', default: ''},
        "isDefault": {type: 'boolean', default: false}
    },
    Country: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "isoCode": {type: 'string', default: ''}
    },
    State: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number'}
    },
    County: {
        "name": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number'},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number'}
    },
    InventLocation: {
        'name': {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        'siteId': {type: 'select', default: '', api: 'InventSite', valueType: 'number'},
        'isDefault': {type: 'boolean', default: false},
    },
    InventSite: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        // "isoCode": {type: 'string', default: ''}
    },
    City: {
        "name": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number'},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number'},
        "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number'}
    },
    PortalParameter: {
        "portalUrl": {type: 'string', default: ''},
        "stdCurrency": {type: 'string', default: ''},
        "stdUnitOfMeasure": {type: 'string', default: ''},
        "multipleCurrencyAllowed": {type: 'boolean', default: false},
    },
    SalesPool: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
        "isDefault": {type: 'boolean', default: false},
    },
    ItemGroup: {
        "name": {type: 'string', default: ''},
        "description": {type: 'string', default: ''},
    },
    ZipCode: {
        "zipCode": {type: 'string', default: ''},
        "countryId": {type: 'select', default: '', api: 'Country', valueType: 'number'},
        "stateId": {type: 'select', default: '', api: 'State', relation: 'countryId', valueType: 'number'},
        "countyId": {type: 'select', default: '', api: 'County', relation: 'stateId', valueType: 'number'},
        "cityId": {type: 'select', default: '', api: 'City', relation: 'countyId', valueType: 'number'}
    },
    CustGroup: {
        "name": {type: 'string', default: ''},
        "isDefault": {type: 'boolean', default: false},
        "paymentTermId": {type: 'select', default: '', api: 'PaymTerm', valueType: 'number'},
    },
    Faq: {
        "title": {type: 'string', default: ''},
        "description": {
            type: 'textarea',
            default: '',
            textareaOpts: {
                rows: 5,
                cols: 70
            }
        },
    },
    TradeAgreement: {
        "relation": {type: 'select', default: '', api: 'TradeRelation', valueType: 'number'},
        "accountCode": {type: 'select', default: '', api: 'AccountCode', valueType: 'number'},
        "accountRelation": {type: 'select', default: '', api: 'AccountRelation'},
        "itemCode": {type: 'select', default: '', api: 'ItemCode', valueType: 'number'},
        "itemRelation": {type: 'select', default: '', api: 'ItemRelation'},
        "description": {type: 'string', default: ''},
        "amount": {type: 'number', default: '', valueType: 'number'},
        "percent1": {type: 'number', default: '', valueType: 'number'},
        "percent2": {type: 'number', default: '', valueType: 'number'},
        "priceUnit": {type: 'number', default: '', valueType: 'number'},
        "fromDate": {type: 'date', default: ''},
        "toDate": {type: 'date', default: ''},
        "quantityAmountFrom": {type: 'number', default: '', valueType: 'number'},
        "quantityAmountTo": {type: 'number', default: '', valueType: 'number'},
        "currencyId": {type: 'select', default: '', api: 'Currency', valueType: 'number', displayKey: 'code'},
        "unitOfMeasureId": {type: 'select', default: '', api: 'UnitOfMeasure', displayKey: 'symbol', valueType: 'number'},
    },
}

//if an input will have different data based on other input selections
//dynamic relations come to rescue
export const dynamicRelations = {
    AccountRelation: {
        key: 'accountCode',
        options: [
            {id: 1, type: 'null', default: null},
            {id: 2, type: 'select', default: '', api: 'CustGroup'},
            {id: 3, type: 'select', default: '', api: 'CustomerMasterData'},
        ]
    },
    ItemRelation: {
        key: 'itemCode',
        options: [
            {id: 1, type: 'null', default: null},
            {id: 2, type: 'select', default: '', api: 'ItemGroup'},
            {id: 3, type: 'select', default: '', api: 'ProductMasterData'},
        ]
    }
}
export const hardCodeApi = {
    CountryPrefix: phonePrefix,
    TradeRelation: [
        {id: 1, key: 'SimplePrice', name: 'Simple Price'}
    ],
    AccountCode: [
        {id: 1, key: 'All', name: 'All'},
        {id: 2, key: 'Group', name: 'Group'},
        {id: 3, key: 'Table', name: 'Table'},
    ],
    ItemCode: [
        {id: 1, key: 'All', name: 'All'},
        {id: 2, key: 'Group', name: 'Group'},
        {id: 3, key: 'Table', name: 'Table'},
    ],
    StatusOptions: [
        {id: 1, name: 'In Check'},
        {id: 2, name: 'Checked Successfully'},
        {id: 3, name: 'Check Not Passed'},
        {id: 4, name: 'Canceled'},
        {id: 5, name:'Closed'}
    ]
}
