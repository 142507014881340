<template>
    <suspense>
        <router-view/>
    </suspense>
</template>
<script setup>
import { useStore } from 'vuex';
// import { useSignalR } from '@dreamonkey/vue-signalr';
import {useToast} from "vue-toastification";

const store = useStore();

if(localStorage){
    const auth = localStorage.getItem('auth_info');
    const email = localStorage.getItem('log_email');
    const user = JSON.parse(localStorage.getItem('logged_user'));
  console.log(user,'useruseruseruseuserr')
    if(email && auth) {
        store.dispatch('auth/actGetLogUserData', user?.id)
        console.log(email, 'email email email');
        // store.commit('auth/mutSetPermissions', user.role);
        if(user) {
            store.commit('auth/mutSetState', {
                key: 'loggedUser',
                value: user
            });
        }
    }

}

// store.commit('menu/mutMenuByPermissions', store.getters["auth/loggedPermission"])

store.dispatch('layout/set');
</script>

