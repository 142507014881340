<script>
import api from "@/utils/api";
import {colTitle, dynamicRelations, hardCodeApi} from "@/constants/tableSettings";
import Datepicker from "@vuepic/vue-datepicker";
import {format} from "date-fns";
import {useVuelidate} from '@vuelidate/core'
import {required, numeric} from '@vuelidate/validators';
import {mapState} from "vuex";
import {separateOnCapitalLetter} from "@/utils/helperFunctions";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import DropdownSearch from "@/components/uiKits/DropdownSearch.vue";
import Phone from "@/components/uiKits/phone.vue";


export default {
    name: "SettingsInput",
    components:{ Phone, DropdownSearch, MazPhoneNumberInput,Datepicker},
    props: ['input', 'modelValue', 'settingsData', 'error', 'parentSelect', 'disabled'],
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            selectData: [],
            selectError: ''
        }
    },
    validations(){
        if(this.input.validation){
            if(this.input.notRequired)
                return {modelValue: {...this.input.validation, $autoDirty: true}}
            return {
                modelValue: {required,...this.input.validation, $autoDirty: true}
            }
        }
        if(!this.input.notRequired) {
            return {
                modelValue: {required, $autoDirty: true}
            }
        }
        return {
            modelValue:{}
        }
    },
    computed:{
        ...mapState({
            settingsApiData: state => state.settings.settingsApiData
        }),

        checkSelOptionsModelValue(){
            for (let x=0; x<this.selectOptions.length; x++){
                if(this.modelValue === this.selectOptions[x].id)
                    return null
            }
            return this.modelValue
        },
        checkSelOptions(){
            if(!this.modelValue)
                return true
            for (let x=0; x<this.selectOptions.length; x++){
                if(this.modelValue === this.selectOptions[x].id && this.modelValue !== 'other')
                    return true
            }
            return false
        },
        inputLabel(){
          if (!this.inputData.label){
            return separateOnCapitalLetter(this.inputData.key)
          }
          return this.inputData.label
        },
        inputData(){
            if(this.inputHasDynamicRelationApi){
                return this.inputHasDynamicRelationApi
            } else {
                return this.input
            }
        },
        currSelectData(){
            return this.settingsApiData[this.inputData.api] ? this.settingsApiData[this.inputData.api] : (this.parentSelect || this.selectData);
        },
        selectOptions(){
            if(this.inputData.api && this.inputData.relation){
                const selRel = this.settingsData[this.inputData.relation];
                if(selRel){
                    const filterData = this.currSelectData.filter(d => d[this.inputData.relation] === selRel)
                    console.log('filterData.length::', filterData.length)
                    this.selectError = filterData.length > 0 ? '' : `There are no options for the selected ${colTitle( this.inputData.relation)}`;
                    return filterData;
                }
                this.selectError = `Please select a ${colTitle( this.inputData.relation)} before`;
                return [];
            } else {
                return this.currSelectData;
            }
        },
        selectLabel(){
            return this.selectError ||  `Select a ${this.inputData.label}`;
        },
        inputHasHardCodeApi(){
            if(this.input.api){
                return hardCodeApi[this.input.api];
            }
            return null;
        },
        inputHasDynamicRelationApi(){
            if(this.input.api){
                const dRelation = dynamicRelations[this.input.api];
                if(dRelation) {
                    const {key, options} = dRelation
                    const option = options.find(o => o.id === this.settingsData[key]) || options[0];
                    return {
                        ...option,
                        label: this.input.label,
                        key: this.input.api
                    };
                }
            }
            return null;
        },
        errorMessage(){
            return this.v$.modelValue.$errors[0]?.$message;
        }
    },
    methods:{

        handleChange(e){
            let newValue = e?.target ? e.target.value : e;
            console.log(newValue,'eeeeeeeeeeeeeeeeeee',this.inputData)

            if(this.inputData.type === 'date'){
                newValue = newValue ? format(newValue, 'yyyy-MM-dd') : '';
            } else if(this.inputData.type === 'boolean'){
                newValue = !JSON.parse(newValue);
            } else if(this.inputData.type === 'number' || this.inputData.valueType === 'number'){
                try {
                    newValue = JSON.parse(e.target.value);
                } catch (error){
                }
            }else if(this.inputData.key === 'nhsNumber'){
                newValue = newValue.replaceAll(" ", "").replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
            }
            console.log(newValue,'newValuenewValuenewValue')
            this.$emit('update:model-value', newValue);
        },
        async getSelectOptions(){
            if(this.inputData.api){
                if(this.inputHasHardCodeApi){
                    this.selectData = this.inputHasHardCodeApi;
                } else {
                    if(!this.settingsApiData[this.inputData.api]){
                        const {data} = await api.get(`${this.inputData.api}`, {
                            // params: {
                            //     pageNumber: 1,
                            //     pageSize: 1000
                            // }
                        })
                        this.selectData = data.items;
                    }

                    if(this.parentSelect){
                        this.$emit('update:model-value', null);
                    }
                }
            }
        },
      getTrackId(opt){
          if(this.input.trackKey !== undefined){
              console.log(opt[this.input.trackKey], 'opt[this.input.trackKey]')
            return opt[this.input.trackKey]
          }return opt.id || opt.healthcareTypeId
      }
    },
    mounted() {
        if(!this.parentSelect){
            this.getSelectOptions();
        }
    },
    watch:{
        inputData(){
            if(!this.parentSelect){
                this.getSelectOptions();
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="form-group" v-if="inputData.type === 'searchSelect'">
            <label class="col-form-label" :for="inputData.key">{{inputLabel}}</label>
            <dropdown-search :options="selectOptions"
                             :id="inputData.key"
                             :error="errorMessage"
                             :disabled="disabled"
                             :backData="inputData.backData"
                             :displayOption="inputData.displayOption"
                             :display-key="inputData.displayKey"
                             :filter-keys="inputData.filterKeys"
                             @select="handleChange"
                             :model-value="searchSelectValue"
            />
        </div>
        <div class="form-group" v-if="inputData.type === 'phoneNumber'">
            <label class="col-form-label" :for="inputData.key">{{inputLabel}}</label>
            <phone :options="selectOptions"
                   class="col-3 col-md-2 p-r-0"
                   :id="inputData.key"
                   :disabled="disabled"
                   :error="errorMessage"
                   :displayOption="'prefix'"
                   :display-key="inputData.displayKey"
                   :filter-keys="inputData.filterKeys"
                   @handleChange="handleChange"
                   :model-value="searchSelectValue"/>

        </div>
        <div v-if="inputData.type === 'select'" class="form-group">
            <label class="col-form-label" :for="inputData.key">{{inputLabel}}</label>
            <select :id="inputData.key"
                    @change="handleChange"
                    :disabled="disabled"
                    :class="['form-select', {'is-invalid': errorMessage}]"
                    required="">
                <option :selected="!modelValue" disabled="" value="">{{selectLabel}}</option>
                <option v-for="opt in selectOptions" :disabled="opt.disabled" :selected="getTrackId(opt) === modelValue" :value="getTrackId(opt)" :key="getTrackId(opt)">
                  {{  opt[inputData?.displayKey] || opt?.name || opt?.id || opt }}
                </option>
            </select>
        </div>

        <label v-else-if="inputData.type === 'boolean'"
               :for="inputData.key"
               class="d-block cursor-pointer mt-3 max-w-max">
            <input :value="modelValue"
                   :disabled="disabled"
                   :validation="{numeric}"
                   :checked="modelValue"
                   @change="handleChange"
                   :class="['checkbox_animated', {'is-invalid': errorMessage}]"
                   :id="inputData.key"
                   type="checkbox">
            {{inputLabel}}
        </label>

        <div v-else-if="inputData.type === 'string' || inputData.type === 'number'" class="form-group">
            <label class="col-form-label">{{inputLabel}}</label>
            <input :value="modelValue"
                   :disabled="disabled"
                   @change="handleChange"
                   :class="['form-control', {'is-invalid': errorMessage}]"
                   :type="inputData.type === 'string' ? 'text' : 'number'"
                   required=""
                   :placeholder="inputLabel"
            />
            <!--                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
            <!--                <div class="invalid-feedback">{{ errors.email }}</div>-->
        </div>
        <div v-else-if="inputData.type === 'date'" class="form-group">
            <label class="col-form-label">{{inputLabel}}</label>
            <Datepicker
                :placeholder="inputLabel"
                :disabled="disabled"
                format="dd-MMM-yyyy "
                :enable-time-picker="false"
                :auto-apply="true"
                :model-value="modelValue"
                @update:model-value="handleChange"
            />
            <!--                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
            <!--                <div class="invalid-feedback">{{ errors.email }}</div>-->
        </div>

        <div v-else-if="inputData.type === 'textarea'" class="form-group">
            <label class="col-form-label">{{inputLabel}}</label>
            <textarea
                :disabled="disabled"
                    :name="inputData.api"
                   :value="modelValue"
                   @change="handleChange"
                   :class="['form-control', {'is-invalid': errorMessage}]"
                   v-bind="inputData.textareaOpts"
                   :placeholder="inputLabel"
            />
            <!--                `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`-->
            <!--                <div class="invalid-feedback">{{ errors.email }}</div>-->
        </div>
<!--        <div v-else-if="inputData.type === 'phoneNumber'" class="form-group maz">-->
<!--            <label class="col-form-label">{{inputLabel}}</label>-->
<!--            <MazPhoneNumberInput :default-country-code="'GB'"-->
<!--                                 :style="disabled?'opacity: 0.5;':''"-->
<!--                                 @update:model-value="handleChange"-->
<!--                                 :disabled="disabled"-->
<!--                                 :modelValue="modelValue"/>-->
<!--        </div>-->
        <div v-else-if="inputData.type === 'radio'" class="form-group">
            <label class="col-form-label">{{inputLabel}}</label>
                <div class="d-flex flex-wrap gap-2" style="min-height: 47px">
                    <div v-for="opt in selectOptions" class="align-items-center d-flex m-r-5">
                        <input type="radio" :id="inputLabel+opt.id" :value="opt.id" :name="inputLabel"
                               @change="handleChange"/>
                        <label :for="inputLabel+opt.id" class="m-b-0" style="margin-left: 2px">{{ opt.label }}</label>
                    </div>
                </div>
        </div>
        <div v-else-if="inputData.type === 'radioOther'" class="form-group">
            <label class="col-form-label">{{inputLabel}}</label>
            <div class="row">
                <div class="col-md-6 d-flex flex-wrap gap-2">
                    <div v-for="opt in selectOptions" class="align-items-center d-flex m-r-5">
                        <input :disabled="disabled" type="radio" :id="inputLabel+opt.id" :value="opt.id" :checked="modelValue === opt.id || (opt.id === 'Other' && modelValue !== null)" :name="inputLabel" @change="handleChange"/>
                        <label :for="inputLabel+opt.id" class="m-b-0" style="margin-left: 2px">{{ opt.label }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                <input :value="checkSelOptionsModelValue"
                       :disabled="checkSelOptions"
                       @change="handleChange"
                       :class="['form-control', {'is-invalid': errorMessage}]"
                       :type="'text'"
                       placeholder="Please Specify"
                />
                </div>
            </div>
        </div>
        <div v-else-if="inputData.type === 'null'">
            <label class="col-form-label">{{inputLabel}}</label>
            <input disabled
                   :value="modelValue"
                   :class="['form-control disabled cursor-default']"
                   type="text"
                   required=""
                   :placeholder="inputLabel"
            />
        </div>
        <div class="invalid-feedback d-block" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>

<style scoped>
select {
    color: #212529 !important;
}
</style>
<style>
.maz .m-phone-number-input__country-flag{
    width: 0px !important;
}
</style>
