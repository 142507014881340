<script>
import Error404 from "@/pages/error/error404.vue";
import TableSettings from "@/components/settings/TableSettings.vue";
import {settings} from "@/constants/tableSettings";
export default {
    name: "setting_table",
    components: {TableSettings, Error404},
    data(){
        return {
            currentSetting: null,
            allSettings: settings,
            invalidKey: false
        }
    },
    methods:{
        verifyRoute(){
            const {setting} = this.$route.params;
            if(setting){
                const foundS = this.allSettings.find(s => s.api.toLowerCase() === setting.toLowerCase());
                if(foundS) {
                    this.currentSetting = foundS;
                } else {
                    this.invalidKey = true;
                }
            }
        },
    },
    created() {
        this.verifyRoute();
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<template>
    <div>
        <error404 v-if="invalidKey"/>
        <div v-else-if="currentSetting">
            <Breadcrumbs :diff-main="currentSetting.title" main="Settings" :title="`${currentSetting.title}`" mainPath="/settings"/>
            <div class="container">
                <table-settings :setting-type="currentSetting"/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
