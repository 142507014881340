<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import NotificationSetting from "@/components/user/NotificationSetting.vue";
import draggable from 'vuedraggable'
import CreateEditSettings from "@/components/settings/CreateEditSettings.vue";
import EditNotification from "@/components/user/EditNotification.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import api from "@/utils/api";
import {useToast} from "vue-toastification";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
    name: "user_notifications",
    components: {ConfirmModal, PolarLoader, EditNotification, CreateEditSettings, NotificationSetting, draggable},
    data(){
        return {
            drag: false,
            currentNotification: null,
            loading: false,
            deleteId: null
        }
    },
    methods: {
        ...mapMutations({
            mutNotificationState: 'notifications/mutNotificationState'
        }),
        ...mapActions({
            actGetDashNotifications: 'notifications/actGetDashNotifications',
            actDeleteNotification: 'notifications/actDeleteNotification',
        }),
        async getNotifications(){
            this.loading = true;
            await this.actGetDashNotifications();
            this.loading = false;
        },
        async handleDragEnd(target){
            const id = JSON.parse(target.item.id);
            try {
                await api.put(`/homePageInfo/updateOrder/${id}`, null, {
                    params: {
                        newOrder: this.dashNotifications.findIndex(n => n.id === id)
                    }
                })
                useToast().success('New order set successfully');
            } catch (e) {
                useToast().error(e.response.data.errors[0].description);
                this.sort();
            }
        },
        sort() {
            this.mutNotificationState({
                key: 'dashNotifications',
                value: this.dashNotifications.sort((a, b) => a.order - b.order)
            })
        },
        handleDelete(){
            if(this.deleteId)
                this.actDeleteNotification(this.deleteId);
        },
        prepareForDelete(id){
            this.deleteId = id
        }
    },
    computed:{
        ...mapState({
            dashNotifications: state => state.notifications.dashNotifications
        }),
        ...mapGetters({
            permission: "auth/loggedPermission"
        }),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        dragModel:{
            get(){
                return this.dashNotifications
            },
            set(value){
                this.mutNotificationState({key: 'dashNotifications', value})
            }
        },
    },
    mounted() {
        this.getNotifications();
    },
}
</script>

<template>
    <div>
        <EditNotification @notificationUpdated="getNotifications"/>
        <div class="modal fade" id="deleteNotificationModal" role="dialog"
             aria-labelledby="deleteNotificationModal" aria-hidden="true">
            <ConfirmModal @confirmed="handleDelete">
                <template #message>
                    Are you sure you want to delete this notification?
                </template>
            </ConfirmModal>
        </div>
        <Breadcrumbs main="Account" main-path="/account" title="Manage Notification" />
        <div class="container">
            <div class="row">
                <div class="card position-relative overflow-hidden">
                   <div class="card-header d-flex align-items-center gap-2">
                        <h4 class="card-title mb-0">Notification List</h4>
                        <button :disabled="permission < 2"
                                data-bs-toggle="modal"
                                data-bs-target="#createSettingModal"
                                class="btn btn-primary ml-auto">
                            <i class="fa fa-plus"/>
                            New Notification
                        </button>
                    </div>
                    <div class="card-body">
                        <transition-group >
                            <draggable
                                v-model="dragModel"
                                key="draggable"
                                v-bind="dragOptions"
                                class="list-group"
                                item-key="id"
                                @start="drag = true"
                                @end="handleDragEnd"
                            >
                                <template #item="{ element }">
                                    <notification-setting :notification="element"
                                                          @delete="prepareForDelete"
                                                          class="drag-item"
                                                          :key="element.id"/>
                                </template>
                            </draggable>
                        </transition-group>
                    </div>
                    <polar-loader v-if="loading">
                        Loading Notifications
                    </polar-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.drag-item {
    cursor: move;
}

</style>
