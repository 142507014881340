<script>
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "supervisorModal",
  data(){
    return{

    }
  },
  components: {PolarLoader},
  computed:{
    ...mapState({
      loggedUser: state => state.auth.loggedUser
    })
  },
  methods:{
    ...mapActions({

    }),
    test(){

    },


    closeModal(){
      document.getElementById('dismissChangePassword').click();
    }
  }
}
</script>

<template>
  <div class="modal fade" id="rqSupModal" role="dialog"
       aria-labelledby="rqSupModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Request Supervisors Access
          </h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>Request Supervisors Access</div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="test">Submit</button>
          <button id="dismissChangePassword" class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
    <!--        <polar-loader></polar-loader>-->
  </div>
</template>

<style scoped>

</style>
