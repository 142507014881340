<script>
import emailValidation from "@/mixins/validations/emailValidation";
import {mapMutations, mapState} from "vuex";
import PolarLoader from "@/components/PolarLoader.vue";
import api from "@/utils/api";
export default {
    name: "ForgetPassword",
    components: {PolarLoader},
    props: ['propEmail'],
    mixins: [emailValidation],
    data(){
        return {
            email: '',
            loading: false,
            successfulRegister: 0
        }
    },
    computed:{
        ...mapState({
            authResponse: state => state.auth.authResponse
        })
    },
    methods: {
        ...mapMutations({
            setAuthState: 'auth/mutSetState'
        }),
        async handleForgetPassword(){
            this.validateEmailForm({email: this.email});
            if(this.emailIsValid){
                this.loading = true
                try {
                    await api.post('/user/forgetPassword', null, {
                        params: {
                            email: this.email
                        }
                    })
                    this.setAuthState({
                        key: 'authResponse',
                        value: {
                            title: 'Email sent successfully',
                            description: `You will find a reset link in the email we sent in your address: ${this.email}`
                        }
                    })
                    this.successfulRegister = 1;
                } catch (e) {
                    console.log('error', e)
                    const {errors, title} = e.response?.data || {title: 'Error', errors: [{description: 'Something wrong happened!'}]}
                    this.setAuthState({
                        key: 'authResponse',
                        value: {
                            title,
                            description: errors[0]?.description
                        }
                    })
                    this.successfulRegister = -1;
                } finally {
                    this.loading = false
                }
            }
        },
        backToLogin(){
            console.log('emitting')
            this.$emit('back');
        }
    },
    mounted() {
        if(this.propEmail){
            this.email = this.propEmail
        }
    }
}
</script>

<template>
    <div>
        <polar-loader v-if="loading">
            Sending email
        </polar-loader>
        <form class="theme-form" novalidate="" @submit.prevent="handleForgetPassword">
            <h4>Forget password</h4>
            <p>Enter your email</p>
            <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input v-model="email"
                       :class="[
                            'form-control',
                            `${formSubmitted ? emailError ? 'is-invalid' : 'is-valid' : ''}`
                       ]"
                       type="email" required="" placeholder="user@email.com" />
                <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="form-group mb-0">
                <button class="btn btn-primary btn-block w-100"
                        type="submit">
                    Submit
                </button>
            </div>
            <div class="mt-4 mb-0 text-end">
                <a @click.prevent="backToLogin" class="cursor-pointer">
                    Back to login
                </a>
            </div>
        </form>
        <div v-if="successfulRegister !== 0" class="absoluteCenter w-100 h-100 bg-white d-flex flex-column" style="padding: 40px;">
            <h4 :class="[{'text-success': successfulRegister > 0},{'text-danger': successfulRegister < 0},]">{{authResponse.title}}</h4>
            <p v-html="authResponse.description"></p>
            <button v-if="successfulRegister < 0" class="btn btn-primary mt-auto mx-auto" @click="() => successfulRegister = 0">Go back</button>
          <div v-else class="mt-auto mb-0 text-end">
            <a @click.prevent="backToLogin" class="cursor-pointer">
              Back to login
            </a>
          </div>
        </div>
    </div>
</template>

<style scoped>

</style>
