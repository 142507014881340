<script>
import {mapState} from "vuex";
import {format} from "date-fns";

export default {
    name: "ExtraDetails",
    computed:{
        ...mapState({
            singleProduct: state => state.product.singleProduct
        }),
        info(){
            if(!this.singleProduct) return [];
            return [
                {title: 'Created', key: 'createdOnUtc', value: format(this.singleProduct.createdOnUtc, 'PPpp')},
                {title: 'Created By', key: 'createdBy'},
                {title: 'Modified', key: 'lastModifiedOnUtc', value: format(this.singleProduct.lastModifiedOnUtc, 'PPpp')},
                {title: 'Modified By', key: 'lastModifiedBy'},
            ]
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="filter-block">
                <h4>Information</h4>
                <ul>
<!--                    <li v-for="i in info" :key="i.key">-->
<!--                        <div class="d-flex gap-1 flex-wrap align-items-center">-->
<!--                            <span>-->
<!--                                {{i.title}}:-->
<!--                            </span>-->
<!--                            <span class="fw-bold">-->
<!--                                {{i.value || singleProduct[i.key]}}-->
<!--                            </span>-->
<!--                        </div>-->
<!--                    </li>-->
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
