<script>
import Pagination from "@/components/uiKits/Pagination.vue";
import PolarLoader from "@/components/PolarLoader.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import EditUser from "@/components/user/EditUser.vue";
import PolarSearch from "@/components/uiKits/PolarSearch.vue";
import {format} from 'date-fns'

export default {
  components: {PolarSearch, EditUser, ConfirmModal, Pagination, PolarLoader},
  data() {
    return {
      userUI: [
        {title: 'ID', key: 'id'},
        {title: 'Name', key: 'firstName'},
        {title: 'Lastname', key: 'lastName'},
        {title: 'Email', key: 'email'},
        {title: 'Last login', key: 'lastLoggedInUtc'},
        // {title: 'Role', key: 'role'},
        {title: 'Healthcare Type', key: 'healthCareType'},
        {title: 'Active', key: 'isActive'},
        // {title: 'Last Used', key: 'lastModifiedOnUtc'},
      ],
      settingStatus: [],
      search: '',
      loadingUsers: false,
      deleteId: null,
      currentSort: 'lastLoggedInUtc',
      isDescending: true
    }
  },
  computed: {
    ...mapState({
      users: state => state.user.users,
      paginationInfo: state => state.user.usersPagination,
      loggedUser: (state) => state.auth.loggedUser,

    }),
    ...mapGetters({
      canModifyUser: "auth/canModifyUser",
      permission: "auth/loggedPermission"
    }),
    usersDisplay() {
      if (!this.users) return [];
      return this.users.map((u) => {
        return {
          ...u,
          lastLoggedInUtc: u.lastLoggedInUtc ? format(u.lastLoggedInUtc, 'dd MMMM yyyy') : 'No Activity',
          canModify: this.canModifyUser(u)
        }
      })
    },

  },
  methods: {
    ...mapActions({
      getUsers: 'user/actGetAllUsers',
      getSuperviseUsers: 'user/actGetSuperviseUsers',
      setStatus: 'user/actSetUserStatus',
      actApproveHospitalUser: 'user/actApproveHospitalUser',
      deleteUser: 'user/actDeleteUser'
    }),
    ...mapMutations({
      mutSetState: 'user/mutSetState'
    }),
    async changeUserStatus({id, isActive, canModify, approved}) {
      console.log({canModify})
      if (canModify) {
        this.settingStatus.push(id);
        if (this.loggedUser.role === 'Polar Speed Admin') {
          await this.setStatus({id, isActive: !isActive});

        } else {
          let params = {id: id, isActive: !approved}
          await this.actApproveHospitalUser(params);
        }
        const idx = this.settingStatus.findIndex(i => i === id);
        let idx2 = this.users.findIndex(i => i.id === id);
        if (idx2 > -1) {
          console.log(idx2)
          this.users[idx2].userStatus = isActive ? 'NotActive' : 'Active'

        }
        console.log('user status::', idx)
        this.settingStatus.splice(idx, 1);
      }
    },
    checkIfUserStatusChanging(id) {
      return this.settingStatus.findIndex(i => i === id) >= 0;
    },
    async handleGetUsers(pageNumber) {
      this.loadingUsers = true;
      let params = {
        searchKey: this.search,
        pageNumber,
        pageSize: 8,
        columnName: this.currentSort,
        isDescending: this.isDescending
      }
      if (this.loggedUser.role === 'Polar Speed Admin') {
        await this.getUsers(params);
      } else {
        await this.getSuperviseUsers(params);
      }
      this.loadingUsers = false;
    },
    handlePaginationEvent(page) {
      this.handleGetUsers(page);
    },
    handleUserDelete() {
      if (this.deleteId) {
        this.deleteUser(this.deleteId);
        this.deleteId = null;
      }
    },
    handleUserSelect(val) {
      // this.mutSetState({
      //     key: 'selectedUserID',
      //     value: val
      // })
      this.$router.push(`/user/edit#${val}`)
    },
    handleSort(key) {
      console.log('key:::', key);
      const finalKey = key === 'fullName' ? 'firstName' : key
      if (finalKey === this.currentSort) {
        this.isDescending = !this.isDescending;
      } else {
        this.currentSort = finalKey;
      }
      this.handleGetUsers(1);
    }
  },
  beforeMount() {

  },
  mounted() {
    // if (this.permission < 3)
    //     this.$router.push('/')
    // else
    this.handleGetUsers(1);
  },
}

</script>

<template>
  <div>
    <Breadcrumbs main="User Settings" title="Manage Users"/>
    <div class="container">
      <div class="row">
        <div class="card position-relative overflow-hidden">
          <div class="card-header d-flex align-items-center gap-2">
            <!--                            <h4 class="card-title mb-0">Users List</h4>-->
            <PolarSearch v-model="search" @search="handleGetUsers(1)" style="max-width: 400px;"/>
            <button :disabled="permission < 2" class="btn btn-primary ml-auto" v-if="permission === 3"
                    @click="() => this.handleUserSelect(null)">
              <i class="fa fa-plus"/>
              New User
            </button>
          </div>
          <div class="card-body" style="min-height: 565px;">
            <div class="table-responsive add-project stickyLastCol">
              <table class="table table-hover card-table table-vcenter text-nowrap">
                <thead>
                <tr>
                  <th v-for="(ui, idx) in userUI" :key="ui.key" :class="['fw-bold', {'text-center': idx > 2}]">
                    <div @click="handleSort(ui.key)" :class="['p-1 cursor-pointer max-w-min', {'mx-auto': idx > 2}]">
                      {{ ui.title }}
                      <i class="fa fa-sort"/>
                    </div>
                  </th>
                  <th class="text-center fw-bold" v-if="permission === 3">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in usersDisplay" :key="user.id">
                  <td v-for="ui in userUI" :key="`${user.id}|${ui.key}`">
                    <div v-if="ui.key !== 'isActive'">
                      {{ user[ui.key] }}
                    </div>
                    <div v-else
                         :class="[
                                                        'progress w-100 mx-auto text-center position-relative h-auto',
                                                     ]"
                         @click="changeUserStatus(user)"
                         style="max-width: 120px;">
                      <div v-if="user.hasOwnProperty('approved')" :class="[ 'btn btn-sm progress-bar w-100',
                                {'btn-success': user.approved},
                                {'btn-danger': !user.approved},
                               {'btn-pending': user.userStatus === 'Submitted' || user.userStatus === 'Pending'},
                                {'disabled cursor-default': !user.canModify},
                                {'progress-bar-animated progress-bar-striped disabled': checkIfUserStatusChanging(user.id)},
                            ]"
                           role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        {{ user.approved ? 'Active' : 'Not Active' }}

                      </div>
                      <div v-else :class="[ 'btn btn-sm progress-bar w-100',
                                {'btn-success': user.userStatus === 'Active'},
                                {'btn-danger': user.userStatus === 'NotActive'},
                               {'btn-pending': user.userStatus === 'Submitted' || user.userStatus === 'Pending'},
                                {'disabled cursor-default': !user.canModify},
                                {'progress-bar-animated progress-bar-striped disabled': checkIfUserStatusChanging(user.id)},
                            ]"
                           role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        {{ user.userStatus === 'NotActive' ? 'Not Active' : user.userStatus }}

                      </div>
                    </div>
                  </td>
                  <td v-if="permission === 3">
                    <div class="d-flex gap-2 h-100 justify-content-center">

                      <button @click="handleUserSelect(user.id)" :disabled="!user.canModify"
                              class="btn btn-primary btn-sm">
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button class="btn btn-danger btn-sm"
                              :disabled="!user.canModify"
                              @click="() => deleteId = user.id"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteUserModal">
                        <i class="fa fa-trash"></i>

                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pagination-padding">
            <pagination :pagination-info="paginationInfo" @pageChanged="handlePaginationEvent"/>
          </div>
          <polar-loader v-if="loadingUsers">
            Loading Users
          </polar-loader>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteUserModal" role="dialog"
         aria-labelledby="deleteUserModal" aria-hidden="true">
      <ConfirmModal @confirmed="handleUserDelete">
        <template #message>
          Are you sure you want to delete this user?
        </template>
      </ConfirmModal>
    </div>
  </div>
</template>
<style>
.btn-pending {
  background-color: #ffa800 !important;
  border-color: #ffa800 !important;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
}

.btn-pending.disabled, .btn-pending:disabled {
  background-color: #ffa800 !important;
  border-color: #ffa800 !important;
}

.btn-pending:focus {
  box-shadow: 0 0 0 0.2rem #ffc554;
}
</style>
