<template>
    <div class="col-sm-12">
        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="top-home-tab" data-bs-toggle="tab" href="#top-home" role="tab" aria-controls="top-home" aria-selected="false">Details</a>
                <div class="material-border"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-top-tab" data-bs-toggle="tab" href="#top-profile" role="tab" aria-controls="top-profile" aria-selected="false">Features</a>
                <div class="material-border"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="contact-top-tab" data-bs-toggle="tab" href="#top-contact" role="tab" aria-controls="top-contact" aria-selected="true">FreeStyle Libre Sensor Pack Kit</a>
                <div class="material-border"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="brand-top-tab" data-bs-toggle="tab" href="#top-brand" role="tab" aria-controls="top-brand" aria-selected="true">Indication of Use</a>
                <div class="material-border"></div>
            </li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade active show" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                <p class="mb-0 m-t-20">
                    Full product information can be found at: Free Style
                    User manual: User manuals - United Kingdom
                </p>
                <p class="mb-0 m-t-20">
                    For use with the FreeStyle LibreLink app or FreeStyle Libre reader. The FreeStyle Libre sensor measures and stores interstitial fluid glucose levels. It represents technology that is different to traditional blood glucose monitoring and makes glucose testing hassle-free.
                </p>
                <p class="mb-0 m-t-20">
                    In a study conducted by Abbott Diabetes Care, 99% of patients surveyed (n = 123) agreed that the FreeStyle Libre system would reduce the hassles of glucose monitoring. Data on file, Abbott Diabetes Care
                </p>
            </div>
            <div class="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">
                <p class="mb-0 m-t-20">
                    Small size (35 mm diameter x 5 mm height) akin to £2 coin – Discreet; the sensor can scan even through clothing
                    <br>
                    Designed to stay on the back of upper arm for up to 14 days
                    <br>
                    Requires no finger prick calibration - Eliminates finger pricks needed for calibration (Bailey Timothy., etal. The Performance and Usability of a Factory - Calibrated Flash Glucose Monitoring System. Diabetes Technology & Therapeutics.2015;17(11):787-794)
                    <br>
                    Water-resistant - Can be worn while bathing, showering, swimming, and exercising
                    <br>
                    Applied by the patient - Easy-to-use applicator to apply the sensor
                    <br>
                    Automatically captures readings day and night - With an easy scan, patients can see their glucose variations, including night-time lows
                    <br>
                    The reader can capture the data from the sensor when it is within 1cm to 4cm from sensor.
                    <br>
                    For a complete glycaemic picture, scan once every 8 hours.
                    <br>
                    The FreeStyle LibreLink app and the FreeStyle Libre reader have similar but not identical features. Finger pricks are required if readings and alarms do not match symptoms or expectations. The FreeStyle Libre sensor communicates with the FreeStyle Libre reader that started it or the FreeStyle LibreLink app that started it. A sensor started by the FreeStyle Libre reader will also communicate with the FreeStyle LibreLink app. The FreeStyle LibreLink app is only compatible with certain mobile devices and operating systems. Please refer to the smartphone compatibility guide on the website before using the app. Use of FreeStyle LibreLink requires registration with LibreView
                </p>
            </div>
            <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                <p class="mb-0 m-t-20">
                    1 Sensor Applicator
                    <br>
                    1 Sensor Pack
                    <br>
                    Alcohol Wipe
                    <br>
                    Product Insert
                    <br>
                    The Sensor initially comes in two parts: one part is in the Sensor Pack and the other part is in the Sensor Applicator. By following the instructions, you prepare and apply the Sensor on the back of your upper arm. The Sensor has a small, flexible tip that is inserted just under the skin. The Sensor can be worn for up to 14 days.
                </p>
            </div>
            <div class="tab-pane fade" id="top-brand" role="tabpanel" aria-labelledby="brand-top-tab">
                <p class="mb-0 m-t-20">
                    The FreeStyle Libre Flash Glucose Monitoring System Reader (“Reader”), when used with a FreeStyle Libre Flash Glucose Monitoring System Sensor (“Sensor”), is indicated for measuring interstitial fluid glucose levels in people (aged 4 and older) with diabetes mellitus, including pregnant women. The Reader and Sensor are designed to replace blood glucose testing in the self-management of diabetes, including dosing of insulin. The indication for children (aged 4-12) is limited to those who are supervised by a caregiver who is at least 18 years of age. The caregiver is responsible for managing or assisting the child to manage the Reader and Sensor and also for interpreting or assisting the child to interpret Sensor glucose readings.
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
</script>
