import api from "@/utils/api";
import {errorToast} from "@/utils/helperFunctions";

const state = {
    singleProduct: null
}

const getters = {

}

const mutations = {
    mutSetSingleProduct: (state, {data, id}) => {
        console.log(data, 'dataaa', id)
        state.singleProduct = data.items.find(p => p.id === id);
    }
}

const actions = {
    actSetSingleProduct: async ({commit}, id) => {
        try {
            const {data} = await api.get('/productMasterData')
            commit('mutSetSingleProduct', {data, id});
        } catch (e) {
            errorToast(e)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
