<script>
import {mapState} from "vuex";
import NotificationSetting from "@/components/user/NotificationSetting.vue";
export default {
    name: "DashboardNotifications",
    components: {NotificationSetting},
    computed:{
        ...mapState({
            dashNotifications: state => state.notifications.dashNotifications
        }),
        filterNot(){
            return this.dashNotifications.filter(n => n.isDisplayed);
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12 p-1" v-for="dash in filterNot" :key="dash.id">
            <NotificationSetting :display="true" :notification="dash"/>
        </div>
    </div>
</template>

<style scoped>

</style>
