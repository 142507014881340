<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media align-items-center">
      <img style="width: 40px;" src="@/assets/images/user/user.png" alt="" />
      <div class="media-body">
        <span>{{ `${loggedUser?.firstName} ${loggedUser?.lastName}` }}</span>
        <p class="mb-0 font-roboto">
            {{ loggedUser?.role }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
          <router-link :to="'/account'"><vue-feather type="user"></vue-feather><span>Account </span></router-link>
      </li>
      <li v-if="loggedUser.role === 'Polar Speed Admin'">
          <router-link :to="'/settings'"><vue-feather type="settings"></vue-feather><span>Settings </span></router-link>
      </li>
<!--        <li>-->
<!--            <vue-feather type="file-text"></vue-feather><span>Support</span>-->
<!--        </li>-->
        <li @click="$store.dispatch('auth/actLockScreen')">
            <vue-feather type="lock"></vue-feather><span>Lock Screen</span>
        </li>
      <li @click="handleLogOut">
        <vue-feather type="log-in"></vue-feather><span class="">LOG OUT</span>
      </li>
    </ul>
  </li>
</template>

<script>
// import firebase from 'firebase';
// import UserAuth from '../../auth/js/index';

export default {
    name: 'Profile',
        methods: {
            handleLogOut: function () {
                this.$store.dispatch('auth/actLogOut');
                // localStorage.removeItem('access_token');
                // localStorage.removeItem('auth_info');
                // localStorage.removeItem('logged_user');
                // this.$store.commit('auth/mutSetUser', null);
                // this.$store.commit('auth/mutSetState', {
                //     key: 'loggedUser',
                //     value: null
                // });
                // this.$router.replace('/auth/login');
            }
    },
    computed:{
        loggedUser(){
            return this.$store.state.auth.loggedUser;
        }
    }
};
</script>
