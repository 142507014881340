<script>
import {vOnClickOutside} from '@vueuse/components';
import {numeric} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
    name: "Phone",
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            searchString: '+44',
            phoneNumber: '',
        }
    },
    props: [
        'options',
        'filterKeys',
        'modelValue',
        'displayOption',
        'displayKey',
        'disabled',
        'id',
        'error'
    ],
    computed: {
        numeric() {
            return numeric
        },
        filterOptions() {
            const searchWord = this.searchString.toLowerCase();
            return this.options.filter(opt => {
                for (let x = 0; x < this.filterKeys.length; x++) {
                    const word = opt[this.filterKeys[x]].toLowerCase()
                    if (word.includes(searchWord)) {
                        return true;
                    }
                }
                return false
            })
        },
        // displayValue() {
        //     if (this.displayKey) {
        //         if (typeof this.displayKey === 'function')
        //             return this.displayKey(this.modelValue);
        //         return this.modelValue[this.displayKey]
        //     }
        //
        //     return this.modelValue
        // },
        errorMessage(){
            return this.v$.phoneNumber.$errors[0]?.$message;
        }
    },
    methods: {
        handleChange() {
            let a = {
                phoneNumber: this.phoneNumber,
                phonePrefix: this.searchString
            }
            this.$emit('handleChange', a)
        },
        handleOptionSelect(opt) {
            this.searchString = opt.prefix
            if(this.phoneNumber)
                this.handleChange()
        },
        handleFocusOut() {

        },
        handleFocusIn() {
            this.searchString = '';
        }
    },
    watch: {
        modelValue() {
            this.handleFocusOut();
        }
    },
    directives: {
        onClickOutside: vOnClickOutside
    },
    validations(){
        return {
            phoneNumber: {numeric, $autoDirty: true}
        }
    }
    // mounted(){
    //     const myDropdown = document.getElementById('dropka')
    //     const vm = this;
    //     console.log(myDropdown,'myDropdownmyDropdown')
    //     myDropdown.addEventListener('show.bs.dropdown', function () {
    //         vm.handleFocusIn();
    //     })
    //     this.handleFocusOut();
    // },
    // beforeUnmount() {
    //     const myDropdown = document.getElementById(this.id || 'dropka')
    //     const vm = this;
    //     myDropdown.removeEventListener('show.bs.dropdown', function () {
    //         vm.handleFocusIn();
    //     })
    // }
}
</script>

<template>
    <div class="row w-100" >
        <div class="dropdown col-3 col-md-2 p-r-0" :id="'dropka'">
            <div class="d-flex align-items-center gap-3 form-input position-relative"
                 aria-expanded="false"
                 :data-bs-toggle="disabled ? '' : 'dropdown'">
                <input type="text"
                       :disabled="disabled"
                       :class="['form-control', {'is-invalid': !!error}]"
                       @focusin="handleFocusIn"
                       v-model="searchString"
                       placeholder="Search">
                <span class="position-absolute" style="right: 12px;"><i class="fa fa-caret-down"></i></span>
            </div>
            <ul class="dropdown-menu w-100 min-w-max"
                style="max-height: 300px;height: min-content; overflow-y: auto; z-index: 2">
                <li v-for="opt in filterOptions" class="fs-6 cursor-pointer" @click.prevent="handleOptionSelect(opt)">
                    <div class="dropdown-item">
                        <slot name="option" :data="opt">
                            {{ opt.prefix + ' ' + opt.name }}
                        </slot>
                    </div>
                </li>
                <!--            <li v-if="!filterOptions.length">-->
                <!--                <div class="dropdown-item">-->
                <!--                    There are no matches-->
                <!--                </div>-->
                <!--            </li>-->
            </ul>
        </div>
        <div class="col-9 col-md-10">
            <input v-model="phoneNumber"
                   :disabled="!this.searchString || disabled"
                   @change="handleChange"
                   :class="['form-control', {'is-invalid': errorMessage}]"
                   type="text"
                   required=""
            />
            {{errorMessage}}
        </div>
    </div>
</template>

<style scoped>
.dropdown-item:hover {
    background-color: #f6f6f6;
    font-weight: bold;
}
</style>
