<template>
  <li class="onhover-dropdown cursor-default">
    <div class="notification-box cursor-pointer">
      <svg :style="!badgeNo ? 'animation: none' : '' ">
        <use href="@/assets/svg/icon-sprite.svg#notification" @click="notification_open()"></use>
      </svg>
      <!-- <feather type="bell" @click="notification_open()"></feather
      > -->
      <span v-if="badgeNo > 0" class="badge rounded-pill badge-primary">{{ badgeNo}}</span>
    </div>
    <div
      class="onhover-show-div notification-dropdown"
      :class="{ active: notification }"
    >
        <div class="d-flex dropdown-title justify-content-between">
            <h6 class="f-18 mb-0">Notifications</h6>
            <span type="button" @click="handleMarkAll(false)">Mark as read</span>
        </div>
          <ul style="max-height: 290px; overflow-y: scroll">
            <li v-for="notf in notifications" :key="notf.id" class="b-l-success border-4 d-flex justify-content-between align-items-center">
                <div>
                    <p>New user registered</p>
                    <p class="font-success">{{ notf.registeredUserEmail }}</p>
                </div>
                <button @click="handleMarkAll(notf.id)" type="button" class="btn btn-outline-success btn-xs">
                    <i class="fa fa-check"></i>
                </button>
            </li>
              <li v-if="!notifications.length">No new Notifications</li>
          </ul>
        <polar-loader v-if="loading">{{''}}</polar-loader>
    </div>
  </li>
</template>

<script>

import {mapActions, mapState} from "vuex";
import PolarLoader from "@/components/PolarLoader.vue";

export default {
    name: 'Notifications',
    components: {PolarLoader},
    data() {
        return {
          notification: false,
            loading: false
        };
    },
    computed:{
        ...mapState({
            loggedUser: state => state.auth.loggedUser,
            notifications: state => state.notifications.wsNotifications
        }),
        badgeNo(){
            return this.notifications.length;
        }
    },
    methods: {
        ...mapActions({
            'actGetWSNotifications': 'notifications/actGetWSNotifications',
            'actMarkAllAsRead': 'notifications/actMarkAllAsRead',
        }),
        async getNotifications(){
            this.loading = true;
            await this.actGetWSNotifications(this.loggedUser.id)
            this.loading = false;
        },
        notification_open() {
            this.notification = !this.notification;
        },
        async handleMarkAll(id){
            if(!this.badgeNo) return;
            this.loading = true;
            await this.actMarkAllAsRead({
                userId: this.loggedUser.id,
                notificationId: id ? id : this.notifications.map(u => u.id)
            })
            await this.getNotifications();
            this.loading = false;
        }
    },
    mounted() {
        this.getNotifications();
    }
};
</script>
